import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import "./Navbar.scss";
import useNavbar from "../../hooks/useNavbar";
import {SOCIAL_URL_DISCORD, SOCIAL_URL_TWITTER} from "../../config";
import SearchBox from "../SearchBox/SearchBox";
import useAppTheme from "../../hooks/useAppTheme";
import useAuth from "../../hooks/useAuth";
import ImgDownBlack from '../../assets/images/icon/chevron-down.svg';
import ImgDown from '../../assets/images/icon/chevron-down-white.svg';

interface INavbarProps {
  toggle: any
  isOpen: boolean,
}

interface ILogoEle {
  extraCls?: string
  black?: boolean
}

export const LogoEle = ({
                          extraCls,
                          black,
                        }: ILogoEle) => {

  return (
    <Link to='/' className={`logo-wrap  ${extraCls || ''}`}>
      <img className={`${black ? '' : 'hidden'}`} src={require("../../assets/images/logo-black.png").default} width={88} height={27}/>
      <img className={`${black ? 'hidden' : ''}`} src={require("../../assets/images/logo.png").default} width={88} height={27}/>
    </Link>
  );
};

export const HamburgerEle = ({
                               extraCls,
                               black,
                             }: ILogoEle) => {
  return (
    <>
      <img className={`${extraCls || ''} ${black ? '' : 'hidden'}`} src={require("../../assets/images/icon/hamburger-dark.svg").default} width={32} height={32}/>
      <img className={`${extraCls || ''} ${black ? 'hidden' : ''}`} src={require("../../assets/images/icon/hamburger.svg").default} width={32} height={32}/>
    </>
  );
};


export const CloseEle = ({
                           extraCls,
                           black,
                         }: ILogoEle) => {
  return (
    <>
      <img className={`${extraCls || ''} ${black ? '' : 'hidden'}`} src={require("../../assets/images/icon/close-dark.svg").default} width={32} height={32}/>
      <img className={`${extraCls || ''} ${black ? 'hidden' : ''}`} src={require("../../assets/images/icon/close.svg").default} width={32} height={32}/>
    </>
  );
};


export const NAV_LINKS = [
  {
    link: '#',
    title: 'Products',
    children: [
      {
        link: 'https://rarityspace.art',
        external: true,
        title: 'rarityspace',
      },
    ]
  },
  {
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSendEc_2Lxb2h0nQ-QTJqIQh9trd-KWcNE1bXNV_kqF2mRncA/viewform',
    external: true,
    title: 'Launch with us'
  },
  {
    link: '/#home-cured',
    title: 'Top drops'
  },
  {
    link: '/collections',
    title: 'Explore'
  },
  {
    link: 'https://roadmap.dropspace.art/',
    title: 'Roadmap',
    external: true,
  },
  {
    link: '/about',
    title: 'About'
  },
];

interface INavLinks {
  isMobileMenu?: boolean;
  navLinks: Array<any>;
  itemClassName?: string;
}

type Props = {
  isLink?: number;
  href?: any;
  className?: string;
  target?: string;
  to?: string;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  children: JSX.Element | JSX.Element[];
};

const OptTag = ({ children, ...props }: Props) => {
  const isLink = props.isLink;
  delete props?.isLink;
  return isLink == 0 ? (
    <a {...props}>{children}</a>
  ) : isLink == 1 ? (
    <Link {...props} to={props.to || ''}>
      {children}
    </Link>
  ) : (
    <span {...props}>{children}</span>
  );
};

export const NavItem = ({ x, itemClassName, isMobileMenu }: any) => {
  const [theme] = useAppTheme();
  const onClick = (e: any) => {
    if (isMobileMenu) {
      const pEle = e.target.closest('span.nav');
      if (pEle) {
        pEle.classList.toggle('opened');
      }
    } else {
      const pEle = e.target.closest('a.nav');
      if (pEle && pEle.closest('span.nav')) pEle.closest('span.nav').classList.remove('opened');
    }
  };
  const onMouseEnter = (e: any) => {
    if (!isMobileMenu) {
      if (x.children) {
        const pEle = e.currentTarget.closest('span.nav');
        if (pEle) {
          pEle.classList.add('opened');
        }
      }
    }
  };

  const onMouseLeave = (e: any) => {
    if (!isMobileMenu) {
      if (x.children) {
        const pEle = e.currentTarget.closest('span.nav');
        if (pEle) {
          pEle.classList.remove('opened');
        }
      }
    }
  };

  const renderChildrenBody = () => (
    <>
      <span className={`${x.children ? 'parent relative' : ''}`}>
        <span>{x.title}</span> {x.children && <img className={` `} src={theme == 'white'? ImgDownBlack : ImgDown} width={9} height={8} />}
      </span>
      {x.children && (
        <span className="children flex flex-col">
          <NavLinks navLinks={x.children} itemClassName={itemClassName} />
        </span>
      )}
    </>
  );

  return x.external ? (
    <OptTag
      isLink={x.children ? 2 : 0}
      key={x.title}
      href={x.link}
      className={`nav relative hover:opacity-95 ${itemClassName || ''}`}
      target="_blank"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {renderChildrenBody()}
    </OptTag>
  ) : (
    <OptTag
      isLink={x.children ? 2 : 1}
      key={x.title}
      to={x.link}
      className={`nav relative hover:opacity-95 ${itemClassName || ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {renderChildrenBody()}
    </OptTag>
  );
};

export const NavLinks = (props: INavLinks) => {
  const { itemClassName, navLinks, isMobileMenu } = props;
  return (
    <>
      {navLinks.map((x, ind) => (
        <NavItem key={x.title} x={x} itemClassName={itemClassName} isMobileMenu={isMobileMenu} />
      ))}
    </>
  );
};

const Navbar = (navItem: INavbarProps) => {

  const location = useLocation();
  const [theme] = useAppTheme();
  const {
    renderConnectButton,
    renderAccountModals
  } = useNavbar(navItem.isOpen);

  const {isAdmin} = useAuth();

  return (
    <React.Fragment>
      <div className="header-nav">
        <nav
          className='container flex justify-between items-center text-white relative text-sm2 pt-14 md:pt-10 xl:pt-9 3xl:pt-9.25 px-4 lg:px-8 xl:px-16 3xl:px-0 max-w-348 mx-auto'
          role='navigation'
        >
          <div className='menu-drop'>
            <LogoEle extraCls={''} black={theme != 'dark'}/>
          </div>

          <div className='search-box-wrapper hidden md:block lg:hidden 3xl:block'>
            <SearchBox openNavMenu={navItem.toggle}/>
          </div>

          <div className="ml-auto block lg:hidden">
            {renderConnectButton()}
          </div>

          <div className='cursor-pointer lg:hidden w-8 h-8' onClick={navItem.toggle}>
            <HamburgerEle black={theme != 'dark'}/>
          </div>

          <div className="md:w-7/12 lg:w-9/12 -mr-4 hidden lg:flex items-center justify-end">
            <div className='social-links hidden xl:flex mr-4'>
              <a className="social-link discord mr-7" href={SOCIAL_URL_DISCORD} target="_blank">Discord</a>
              <a className="social-link twitter" href={SOCIAL_URL_TWITTER} target="_blank">Twitter</a>
            </div>

            {/*{NAV_LINKS.map((x) => x.external ? <a key={x.title} href={x.link} className={`nav px-4 hover:opacity-95 md:ml-0`} target="_blank">{x.title}</a> :*/}
            {/*  <Link key={x.title} to={x.link} className={`nav px-4 hover:opacity-95 md:ml-0`}>{x.title}</Link>)}*/}
            <NavLinks navLinks={NAV_LINKS} itemClassName={'px-4 md:ml-0'} />

            {isAdmin && (
              <>
                <Link to={'/create-collection'} className="nav px-4 hover:opacity-95">Create</Link>
              </>
            )}
            {renderConnectButton()}
          </div>
          {renderAccountModals()}
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Navbar;

