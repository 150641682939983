import {reactLocalStorage as ls} from "reactjs-localstorage";
import _ from 'lodash';

/**
 * Set object on Local storage
 * @param key
 * @param data
 */
export function setLocalData(key: string, data: any) {
  ls.setObject(key, data);
}

export function updateLocalData(key: string, data: any) {
  const oldData = ls.getObject(key);
  ls.setObject(key, {...oldData, ...data});
}

/**
 * Get object on local storage
 * @param key
 * @param key2
 * @returns {any|undefined}
 */
export function getLocalData(key: string, key2?: string | null) {
  const oldData = ls.getObject(key);

  if (key2) {
    return _.get(oldData, key2);
  } else return _.isObject(oldData) && _.isEmpty(oldData) ? null : oldData;
}

