import React, {useEffect, useRef} from "react";
import {useLocation, useHistory} from 'react-router-dom';
import ImgSearch from "../../assets/images/icon/search.png";
import ImgSearchDark from "../../assets/images/icon/search-dark.png";
import ImgClose from "../../assets/images/icon/close.svg";
import ImgCloseDark from "../../assets/images/icon/close-dark.svg";
import "./SearchBox.scss";
import useAppTheme from "../../hooks/useAppTheme";
import {useDispatch, useSelector} from "react-redux";
import {isEnterKeyPressed} from "../../dnweb3/helpers/utilities";
import {setSearchKeyword} from "../../redux/global/actions";
import qs from 'qs';

interface ISearchBox {
  openNavMenu?: any
  closeNavMenu?: any
  forceShow?: boolean | undefined
  extraCls?: string | undefined
  isInMobile?: boolean | undefined
}

const SearchBox = (props: ISearchBox) => {
  const {openNavMenu, closeNavMenu, forceShow, extraCls, isInMobile} = props;
  const [theme] = useAppTheme();
  const searchRef = useRef<any>();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchKeyword = new URLSearchParams(location.search).get("q") || '';

  useEffect(() => {
    searchRef.current.value = searchKeyword;
  }, [searchKeyword]);

  const onSearch = (value: any, isReset?: boolean) => {
    dispatch(setSearchKeyword(value));

    if (!isReset) {
      closeNavMenu && closeNavMenu();
    }
    history.push(value ? `/collections?` + qs.stringify({q: value}) : `/collections`);
  }

  const onKeydown = (e: any) => {
    if (isEnterKeyPressed(e)) {
      onSearch(e.target.value);
    }
  }

  return (
    <>
      <div className={`hidden md:block search-box relative ${extraCls || ''}`} style={{display: forceShow !== undefined && forceShow ? 'block' : ''}}>
        <input className={`py-5 pl-15 pr-8 rounded ${extraCls || ''}`}
               placeholder="Search all collections..."
               defaultValue={searchKeyword || ''}
               ref={searchRef}
               onKeyDown={e => onKeydown(e)}
        />
        <img className="absolute top-0 left-0 ml-7 mt-4.5" src={theme == 'dark' || isInMobile ? ImgSearch : ImgSearchDark} width={15} height={15}/>
        {searchKeyword && <img
          className="cursor-pointer absolute top-0 right-0 mr-7 mt-4.5"
          onClick={() => onSearch('', true)}
          src={theme == 'dark' || isInMobile ? ImgClose : ImgCloseDark}
          width={20}
          height={20}
          title={"reset search"}
        />}
      </div>
      <div className={`block md:hidden search-box relative cursor-pointer`}
           style={{display: forceShow !== undefined && forceShow ? 'none' : ''}}
           onClick={openNavMenu}
      >
        {<img className="" src={theme == 'dark' ? ImgSearch : ImgSearchDark} width={15} height={15}/>}
      </div>
    </>
  );
}

export default SearchBox;
