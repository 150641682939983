import Web3 from "web3";
import BigNumber from "bignumber.js";
import {bnZero} from "../helpers/utilities";

export class BaseContractController {
  protected web3: Web3 | undefined;

  public constructor(web3: Web3) {
    this.web3 = web3;
  }

  public async getUserBalance(address: string): Promise<BigNumber> {
    if (!this.web3) return bnZero();
    const res = await this.web3.eth.getBalance(address);
    return new BigNumber(res);
  };

}
