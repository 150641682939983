import {convertHexToUtf8} from "@walletconnect/utils";
import _, { isNumber } from 'lodash';

import {IChainData} from "./types";
import {KOVAN_CHAIN_ID, MAINNET_CHAIN_ID, SUPPORTED_CHAINS} from "../constants";
import BigNumber from "bignumber.js";
import {APP_NETWORK_ID, BNB_PRICE_API} from "../config";
import moment from 'moment';
import 'moment-timezone';
import {APP_MEDIA_BASE_URL, TZ_NEW_YORK, TZ_SERVER} from "../../config";
import {EnumFileCat} from "../types";

export const DT_MIN_START_DATE = '1970-01-01 00:00:00';
export const DT_MAX_END_DATE = '2200-12-31 23:59:00';
export const DT_MAX_TS = 999999999999;

export function capitalize(string: string): string {
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function ellipseText(text = "", maxLength = 9999): string {
  if (text.length <= maxLength) {
    return text;
  }
  const _maxLength = maxLength - 3;
  let ellipse = false;
  let currentLength = 0;
  const result =
    text
      .split(" ")
      .filter(word => {
        currentLength += word.length;
        if (ellipse || currentLength >= _maxLength) {
          ellipse = true;
          return false;
        } else {
          return true;
        }
      })
      .join(" ") + "...";
  return result;
}

export function ellipseAddress(address = "", width = 10, endWidth?: number): string {
  return address ? `${address.slice(0, width)}...${address.slice(-(endWidth || width))}` : '';
}

export function padLeft(n: string, width: number, z?: string): string {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function sanitizeHex(hex: string): string {
  hex = hex.substring(0, 2) === "0x" ? hex.substring(2) : hex;
  if (hex === "") {
    return "";
  }
  hex = hex.length % 2 !== 0 ? "0" + hex : hex;
  return "0x" + hex;
}

export function removeHexPrefix(hex: string): string {
  return hex.toLowerCase().replace("0x", "");
}

export function getDataString(func: string, arrVals: any[]): string {
  let val = "";
  for (let i = 0; i < arrVals.length; i++) {
    val += padLeft(arrVals[i], 64);
  }
  const data = func + val;
  return data;
}

export function isMobile(): boolean {
  let mobile = false;

  function hasTouchEvent(): boolean {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  function hasMobileUserAgent(): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substr(0, 4),
      )
    ) {
      return true;
    } else if (hasTouchEvent()) {
      return true;
    }
    return false;
  }

  mobile = hasMobileUserAgent();

  return mobile;
}

export function payloadId(): number {
  const datePart: number = new Date().getTime() * Math.pow(10, 3);
  const extraPart: number = Math.floor(Math.random() * Math.pow(10, 3));
  const id: number = datePart + extraPart;
  return id;
}

export function getChainData(chainId: number): IChainData {
  console.log("xx Chain ID = ", chainId);
  const chainData = SUPPORTED_CHAINS.filter((chain: any) => chain.chain_id === chainId)[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = process.env.INFURA_PROJECT_ID;

  if (!API_KEY) {
    throw new Error("Environment variable INFURA_PROJECT_ID is not set");
  }
  console.log("%% RPC URL -> ", API_KEY);

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);
    console.log("%% -> RPC URL = ", rpcUrl, ", Chain ID = ", chainId);
    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}

export function getViewportDimensions() {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];
  const x = w.innerWidth || e.clientWidth || g.clientWidth;
  const y = w.innerHeight || e.clientHeight || g.clientHeight;

  return {x, y};
}

export function convertHexToUtf8IfPossible(hex: string) {
  try {
    return convertHexToUtf8(hex);
  } catch (e) {
    return hex;
  }
}

export function prettyPrint(obj: any) {
  return JSON.stringify(obj, null, 2);
}

export function verifyPayload(payload: any) {
  const {params, id, method} = payload;

  if (!params || typeof params !== "object") {
    throw new Error(
      `WalletConnect Error - invalid payload params. Payload: ${prettyPrint(payload)}`,
    );
  }

  if (!id || typeof id !== "number") {
    throw new Error(`WalletConnect Error - invalid payload id. Payload: ${prettyPrint(payload)}`);
  }

  if (!method || typeof method !== "string") {
    throw new Error(
      `WalletConnect Error - invalid payload method. Payload: ${prettyPrint(payload)}`,
    );
  }

  return;
}

export function verifyFields(params: any[], keys: any[]) {
  if (keys.length <= 0 || keys.filter((k: any) => typeof k !== "string").length !== 0) {
    throw new Error(`[verifyFields] Must provide an array of fields to check`);
  }
  if (typeof params !== "object") {
    throw new Error(`[verifyFields] Must provide a params object`);
  }

  const naStr = keys.filter(k => !params[k]);
  if (naStr.length !== 0) {
    throw new Error(
      `[verifyFields] Params missing needed keys. Params: ${prettyPrint(
        params,
      )}, keys: ${prettyPrint(keys)}`,
    );
  }
  return;
}

export function getCachedSession(): any {
  const local = localStorage ? localStorage.getItem("walletconnect") : null;

  let session = null;
  if (local) {
    try {
      session = JSON.parse(local);
    } catch (error) {
      throw error;
    }
  }
  return session;
}


const bnToDec = (bn: any, decimals = 18) => {
  return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber();
};

const decToBn = (dec: any, decimals = 18) => {
  return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals));
};

const bnDivdedByDecimals = (bn: any, decimals = 18) => {
  if (BigNumber.isBigNumber(bn)) {
    return bn.dividedBy(new BigNumber(10).pow(decimals));
  }
  return new BigNumber(bn).dividedBy(new BigNumber(10).pow(decimals));
};

const bnMultipledByDecimals = (bn: any, decimals = 18) => {
  return bn.multipliedBy(new BigNumber(10).pow(decimals));
};

/*
const nf = (value, decimal = 18, numPoint = 6, precision = 3) => {
  const bnValue = new BigNumber(value);
  const data = bnValue.dividedBy(new BigNumber(10).pow(decimal));
  if (data.isGreaterThan(new BigNumber(1).dividedBy(new BigNumber(10).pow(precision)))) {
    return data.dp(precision, 1).toString(10);
  }
  return data.dp(numPoint, 1).toString(10);
};
*/
const nf = (value: any, decimals = 2, decimalForce = undefined) => {
  const bnValue = new BigNumber(value);
  if (bnValue.isNaN() || bnValue.isZero()) return '0';
  return decimalForce ? bnValue.toFormat(decimals) : bnValue.toFormat(decimals).replace(/\.0*$|(\.\d*[1-9])0+$/, '$1');
};

const nf_i = (value: any) => {
  return nf(value, 0);
};

const bnZero = () => new BigNumber(0);

function toSeoUrl(url: string) {
  return url.toString()               // Convert to string
    .normalize('NFD')               // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-')            // Change whitespace to dashes
    .toLowerCase()                  // Change to lowercase
    .replace(/&/g, '-and-')          // Replace ampersand
    .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
    .replace(/-+/g, '-')             // Remove duplicate dashes
    .replace(/^-*/, '')              // Remove starting dashes
    .replace(/-*$/, '');             // Remove trailing dashes
}

const getBinancePrice = async () => {
  let response = await fetch(BNB_PRICE_API);
  if (!response.ok) {
    return 0;
  }
  const responseBinance = await response.json();
  const usd = responseBinance.binancecoin.usd;
  return usd;
}

const getEthPrice = async () => {
  let response = await fetch(BNB_PRICE_API);
  if (!response.ok) {
    return 0;
  }
  const responseBinance = await response.json();
  const usd = responseBinance["ethereum"].usd;
  return usd;
}


const getCleanNameByTypeId = (typeId: number, list: Array<any>) => {
  const x = _.find(list, x => (x.typeId) == typeId) || {};
  // @ts-ignore
  return toSeoUrl(x.name);
};

/* eslint no-useless-escape:0 */
// const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const reg = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;

const isUrl = (path: string) => {
  // todo
  return true;
  return reg.test(path);
}

const getValidUrl = (url: string) => {
  if (!url) return '';
  if (!url.includes('http://') && !url.includes('https://'))
    url = `https://${url}`;
  return isUrl(url) ? url : '';
}

function validateEmail(email: string) {
  // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email);
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function isEnterKeyPressed(e: any) {
  return ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13));
}


const scrollToElement = (ele: HTMLElement, offset?: number) => {
  if (!ele) return;
  const rect = ele.getBoundingClientRect();
  window.scrollTo({
    top: rect.top + window.scrollY + (offset || 0),
    left: 0,
    behavior: 'smooth'
  });
}


/**
 * Get w3c standard timezone string
 *
 * @param value
 * @param targetTz
 * @param srcTz
 *
 * @test
 *

 console.log(item.start_drop_date, '  NY=', startDate, '  Default=', moment(item.start_drop_date).format());
 console.log(" --> ",    moment().local().format(),    "  current =", dtTzStr(undefined), "   ", new Date());

 */
const dtTzStr = (value: string | undefined, targetTz?: string, srcTz?: string) => {
  const m = dtInTz(value, targetTz, srcTz);
  return m && m.isValid() ? m.format() : '';
}

const dtInTz = (value: string | undefined, targetTz?: string, srcTz?: string) => {
  const m = moment.tz(value, srcTz || TZ_SERVER);
  let targetM = null;
  if (m.isValid()) {
    targetM = targetTz ? m.clone().tz(targetTz) : m;
    return targetM;
  } else {
    return null;
  }
}

const dtNiceStartDropDate = (value:string | undefined) => {
  if (!value) return '';
  const mObj = dtInTz(value, TZ_NEW_YORK, TZ_NEW_YORK);
  const title = mObj ? `${mObj.format('MMM Do h:mma')} ${' EST' || mObj.format('h:mma z')}` :
    value;
  return title;
}

const dtTodayInServerTz = () => {
  const today = moment().tz(TZ_SERVER);
  return today;
}

const dtTzStrToServerDTStr = (value: string | undefined, targetTz?: string, srcTz?: string) => {
  const m = dtInTz(value, targetTz, srcTz);
  return m && m.isValid() ? m.format('YYYY-MM-DD HH:mm:ss') : '';
}

const dtStrToDateObject = (value: string | undefined, targetTz?: string, srcTz?: string) => {
  const m = dtInTz(value, targetTz, srcTz);
  return m && m.isValid() ? m.toDate() : null;
}

const getFormData = (object: any) => Object.keys(object).reduce((formData, key) => {
  const valueObj = object[key];
  let newValue = valueObj;
  if (typeof valueObj == "boolean") {
    newValue = valueObj ? 1 : 0;
  } else if (typeof valueObj == 'object') {
  } else {
  }

  formData.append(key, newValue);
  return formData;
}, new FormData());


const safeNum = (val: any, defaultValue = 0) => {
  return parseFloat(val) || defaultValue;
};

const safeTimestamp = (val: any) => {
  if (!val) return 0;
  if (isNumber(val)) return val;

  if (val.length > 10) {
    return DT_MAX_TS;
  }
  return parseInt(val) || 0;
};

const safeJsonParse = (val: any) => {
  if (!val) return null;
  if (typeof val == 'string') {
    try {
      return JSON.parse(val);
    } catch (e) {

    }
  } else {
    return val;
  }
}

const safeBoolBy01 = (val: any) => {
  if (!val) return false;
  if (val == '0') return false;
  if (val == '1') return true;
  return false;
};

const safe01ByBool = (val: any) => {
  if (!val) return '0';
  else return '1';
};

const noNullText = (val: any) => (val === 'null') ? '' : val;

const getWhitelistArr = (val: string | undefined) => {
  if (!val) return [];
  let arr = val
    .replace(/[\r]+/g, '')
    .replace(/[\n]+/g, ',')
    .split(',')
    .map((s: string) => s.trim().toLowerCase());
  arr = arr.filter((el) => el != null && el != '')
  arr.sort();
  arr = _.sortedUniq(arr);
  return arr;
};

const getImgUrl = (file: any, isOrigin?: boolean) => {
  if (file) {
    if (isOrigin) {
      if (file.org_path) {
        return APP_MEDIA_BASE_URL + file.org_path;
      }
    } else {
      if (file.path) {
        return APP_MEDIA_BASE_URL + file.path;
      }
    }
  }
  return '';
};

const mergeArr = (a: any, b: any, key: any) => {
  const bMap = _.keyBy(b, key);
  const result: Array<any> = [];
  _.forEach(a, (x: any) => {
    const keyVal = _.get(x, key);
    let mergedVal;
    if (keyVal in bMap) {
      const found: any = _.get(bMap, `[${keyVal}]`);
      mergedVal = { ...x, ...found };
      _.unset(bMap, `[${keyVal}]`);
    } else {
      mergedVal = x;
    }
    result.push(mergedVal);
  });

  _.forEach(b, (x: any) => {
    const keyVal = _.get(x, key);
    if (keyVal in bMap) {
      result.push(x);
    }
  });

  return result;
};

/*
console.log("========= TEST ==========");
const arr1 = [
  {
    project_id: 1,
    name: 'Project 1',
  },
  {
    project_id: 2,
    name: 'Project 2',
  },
];

const arr2 = [
  {
    project_id: 1,
    name: 'Project 1 modified',
  },
  {
    project_id: 2,
    name: 'Project 2 modified',
  },
  {
    project_id: 3,
    name: 'Project 3',
  },
];
console.log(mergeArr(arr1, arr2, 'project_id'));*/

const isStrEqual = (a: string, b: string) => {
  return a.toUpperCase() === b.toUpperCase();
}

export {
  bnToDec,
  decToBn,
  nf,
  nf_i,
  bnMultipledByDecimals,
  bnDivdedByDecimals,
  bnZero,
  toSeoUrl,
  getBinancePrice,
  getEthPrice,
  getCleanNameByTypeId,
  isUrl,
  getValidUrl,
  validateEmail,
  isEnterKeyPressed,
  scrollToElement,

  dtTzStr,
  dtInTz,
  dtTodayInServerTz,
  dtTzStrToServerDTStr,
  dtStrToDateObject,
  dtNiceStartDropDate,

  getFormData,
  safeNum,
  safeTimestamp,
  safeJsonParse,
  safeBoolBy01,
  safe01ByBool,

  getImgUrl,
  mergeArr,
  isStrEqual,
  getWhitelistArr,
  noNullText,
};


