import {WalletController, getWalletController} from "./wallet";
import {StoreController, getStoreController} from "./store";
import {ContractController, getContract} from "./contract";
import Web3 from "web3";

interface IAppControllers {
  store?: StoreController;
  wallet?: WalletController;
  contract: ContractController;
}

let controllers: IAppControllers | undefined;

/**
 * If scAddress is given, we create DropSpace United Smart Contract
 *
 * @param web3
 * @param scAddress
 */
export function setupAppControllers(web3: Web3, scAddress?: string): IAppControllers {
  /*const wallet = getWalletController();
  const store = getStoreController();*/
  const contract = getContract(web3);
  controllers = {
    contract,
  }

  return controllers;
}

export function getAppControllers(web3: Web3): IAppControllers {
  let _controllers = controllers;
  if (!_controllers || web3) {
    _controllers = setupAppControllers(web3);
  }
  return _controllers;
}







