import Web3 from "web3";
import BigNumber from "bignumber.js";
import abiDropSpace from "../abi/Dropspace.json";
import abiDropSpaceV21 from "../abi/DropspaceV21.json";
import { BaseContractController } from "./BaseContract";
import {bnToDec, getWhitelistArr} from "../helpers/utilities";
import { isTicketSale, isWhitelistSale } from "../../components/CollectionSummary/CollectionSummary";
import { EnumSaleStep } from "../helpers/types";
import {getMerkleTree, getMerkleTreeString, getRootHash} from "../helpers/MTWhitlistUtil";
import keccak256 from "keccak256";

export enum EnumSaleActiveStatus {
  saleActive = 'saleActive',
  preSaleActive = 'preSaleActive',
  whitelistSaleActive = 'whitelistSaleActive',
  whitelistBuyOnce = 'whitelistBuyOnce',
}

export class DropSpaceSCController extends BaseContractController {

  protected scAddress: string;
  protected scVersion: number | undefined;
  protected abi: Array<any> = abiDropSpace;

  public constructor(web3: Web3, scAddress: string, scVersion?: number) {
    super(web3);
    this.scAddress = scAddress;
    this.scVersion = scVersion;
    if ((this.scVersion || 0) >= 2.1) {
      this.abi = abiDropSpaceV21;
    }
  }

  public getContract() {
    if (this.web3 && this.scAddress && this.abi) {
      return new this.web3.eth.Contract(this.abi, this.scAddress);
    } else {
      throw 'DropSpace contract is not initialized.'
    }
  }

  public async getOwner(): Promise<string> {
    const res = await this.getContract().methods.owner().call();
    return res;
  };

  public async getPrice(count: number): Promise<BigNumber> {
    const res = await this.getContract().methods.mintPrice().call();
    return new BigNumber(res).multipliedBy(count);
  };

  public async toggleSaleActiveStatus(address: string, type: EnumSaleActiveStatus): Promise<any> {
    switch (type) {
      case EnumSaleActiveStatus.preSaleActive:
        return this.getContract().methods.togglePresaleActive().send({
          from: address
        });
        break;
      case EnumSaleActiveStatus.whitelistSaleActive:
        return this.getContract().methods.toggleWhitelistSaleActive().send({
          from: address
        });
        break;
      case EnumSaleActiveStatus.whitelistBuyOnce:
        const oldStatus = await this.getContract().methods.whitelistBuyOnce().call();
        return this.getContract().methods.setWhitelistBuyOnce(!oldStatus).send({
          from: address
        });
        break;
      default:
        return this.getContract().methods.toggleSaleActive().send({
          from: address
        });
    }
  };

  /**
   * Added whitelist checking logic.
   * @param address
   * @param whitelistsStr
   * @param whitelistBuyOnce
   */
  public async isWhitelisted(address: string, whitelistsStr?: string, whitelistBuyOnce?: boolean): Promise<boolean> {
    if (!address) return false;

    if ((this.scVersion || 0) >= 2.1) {
      if (!whitelistsStr) return false;
      if (whitelistsStr.includes(address)) {
        if (whitelistBuyOnce) {
          const res = await this.getContract().methods.whitelistClaimed(address).call();

          return !res;
        }
        return true;
      }
      return false;
    } else {
      return await this.getContract().methods.whitelist(address).call();
    }
  };

  /**
   * @since 2.1
   * @param address
   * @param whiteList
   */
  public async setWhitelistRoot(address: string, whiteList: string[]): Promise<any> {
    const merkleTree = getMerkleTree(whiteList);
    const rootHashString = merkleTree.getHexRoot();
    console.log('root:', rootHashString);
    const res = await this.getContract().methods.setWhitelistRoot(rootHashString).send({ from: address });
    return res;
  }

  /**
   * @since 2.1
   * @param address
   */
  public async isWhitelistClaimed(address: string): Promise<boolean> {
    const res = await this.getContract().methods.whitelistClaimed(address).call();
    return res;
  }


  public async setReserve(address: string, reserve: number): Promise<any> {
    const res = await this.getContract().methods.reserve(reserve).send({
      from: address,
    });
    return res;
  }

  public async withdraw(address: string): Promise<any> {
    const res = await this.getContract().methods.withdraw().send({
      from: address,
    });
    return res;
  }

  public async getSaleActiveStatuses(): Promise<any> {
    const [saleActive, whitelistSaleActive, preSaleActive, whitelistBuyOnce] = await Promise.all([
      this.getContract().methods.saleActive().call(),
      this.getContract().methods.whitelistSaleActive().call(),
      this.getContract().methods.presaleActive().call(),
      this.scVersion && this.scVersion > 1 ? this.getContract().methods.whitelistBuyOnce().call() : false,
    ]);

    return {
      saleActive,
      whitelistSaleActive,
      preSaleActive,
      whitelistBuyOnce
    }
  };

  public async buyMultiple(address: string, qty: number, totalPrice: BigNumber, ticketId?: number, saleStep?: EnumSaleStep, whitelistsStr?: string): Promise<any> {
    const {saleActive, whitelistSaleActive, preSaleActive, whitelistBuyOnce} = await this.getSaleActiveStatuses();
    const tmp = {
      sc_sale_active: saleActive,
      sc_whitelist_active: whitelistSaleActive,
      sc_presale_active: preSaleActive,
      whitelistBuyOnce,
    }

    // console.log(saleActive, preSaleActive, whitelistSaleActive);
    if (whitelistSaleActive || saleActive || preSaleActive) {
      if (isTicketSale(tmp, saleStep)) {
        const res = await this.getContract().methods.presaleBuy(ticketId, qty).send({
          from: address,
          value: totalPrice.toString(),
        });
        return res;
      } else if (isWhitelistSale(tmp, saleStep)) {
        if (whitelistSaleActive) {
          const tree = getMerkleTree(getWhitelistArr(whitelistsStr));
          const addressEnc = keccak256(address);
          const proofHex = tree.getHexProof(addressEnc);
          const res = await this.getContract().methods.whitelistBuy(qty, proofHex).send({
            from: address,
            value: totalPrice.toString(),
          });
          return res
        }
      } else if (!saleStep || saleStep == EnumSaleStep.NormalSale) {
        const res = await this.getContract().methods.buy(qty).send({
          from: address,
          value: totalPrice.toString(),
        });
        return res;
      }
    }
    throw "Sale is not available now";
  }

  public async buyMultipleTestGas(address: string, qty: number, totalPrice: BigNumber, ticketId?: number): Promise<any> {
    if (!this.web3) throw "You are not connected";
    const gasPrice = await this.web3.eth.getGasPrice();
    console.log('Gas Price', gasPrice);

    const data: any = {
      from: address,
      value: totalPrice.toString(),
      // maxPriorityFeePerGas: null,
      // maxFeePerGas: null,
    };

    // const estimatedGas = await this.getContract().methods.buy(qty).estimateGas(data);
    const estimatedGas = await this.web3.eth.estimateGas(data);
    console.log('estimatedGas', estimatedGas, bnToDec(new BigNumber(gasPrice).multipliedBy(estimatedGas)));

    const newGasPrice = new BigNumber(gasPrice).plus(20);
    console.log('New Gas & Price', newGasPrice.toString(), estimatedGas, bnToDec(new BigNumber(newGasPrice).multipliedBy(estimatedGas)));

    data.gasPrice = newGasPrice.toString();
    return await this.getContract().methods.buy(qty).send(data);
  }
}


export function getContractDS(web3: Web3, scAddress: string, scVersion?: number) {
  return new DropSpaceSCController(web3, scAddress, scVersion);
}

