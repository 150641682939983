import {useDispatch, useSelector} from "react-redux";
import {IMapStateToProps} from "../types";
import {EnumUserRole} from "../dnweb3/types";

import {useCallback} from "react";
import web3Util from "../dnweb3/helpers/Web3Util";
import AppApi from "../dnweb3/appapi";
import {setGuestProfile, setProfile, updateProfile} from "../redux/auth/actions";
import {getAuthToken, setAuthToken} from "../dnweb3/helpers/AuthUtil";

export default function useAuth() {
  const dispatch = useDispatch();
  const {
    authUser: {
      web3,
      address,
      loggedIn,
      profile: {
        user_id,
        role,
        token,
        status,
        nonce,
      },
      profileLoaded
    }
  } = useSelector((state: IMapStateToProps) => state);

  const requestAuth = useCallback(async (useOldToken?: boolean) => {
    if (web3 && address && loggedIn) {
      const sign = await web3Util.signMessage(web3, address, nonce || address);
      if (sign) {
        const data = await AppApi.login(address, sign);
        const resAuth = data? data.Authorization : null;
        if (data) {
          dispatch(setProfile({...data.user, token: data.Authorization}))
        } else {
          dispatch(setGuestProfile(true));
        }
        setAuthToken(address, resAuth);
        return resAuth;
      }
      if (useOldToken)
        return token;
    }
    return null;
  }, [address, web3, loggedIn, nonce, dispatch]);

  return {
    isAdmin: token && profileLoaded && loggedIn && address && status == 1 && user_id > 0 && ((role || 0) == EnumUserRole.ADMIN),
    isAuthenticated: token,
    token,
    requestAuth
  };
}
