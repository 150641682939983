import React from 'react'
import {Link} from "react-router-dom";
import './index.scss'
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.any,
  size: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.any,
  external: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
  disable: PropTypes.bool,
};

const LinkButton = (props: any) => {
  const {title, size = "normal", className, linkTo, target, icon, external, children} = props;
  return external ?
    <a
      href={linkTo}
      target={target || "_self"}
      className={`
                btn
                ${size === "sm" ? 'btn-sm' : 'btn-normal'}
                text-base 
                font-bold 
                focus:outline-none
                ${className}
        `}
      style={props.style || {}}
    >
      {icon}
      {title ? title : children}
    </a> :
    <Link
      to={linkTo}
      target={target || "_self"}
      className={`
                btn
                ${size === "sm" ? 'btn-sm' : 'btn-normal'}
                text-base 
                font-bold 
                focus:outline-none
                ${className}
        `}
      style={props.style || {}}
    >
      {icon}
      {title ? title : children}
    </Link>
}

LinkButton.propTypes = propTypes;

export default LinkButton;
