import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import DatePickerWithTimezone from "../DatePickerWithTimezone";
import {TZ_NEW_YORK} from "../../config";

const CDateInputComponent = ({
                               field, // { name, value, onChange, onBlur }
                               form, // : {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                               ...props
                             }: any) => {
  const {touched, errors, setFieldValue, handleChange} = form;

  const [date, setDate] = useState();

  useEffect(() => {
    setDate(field.value);
  }, [field]);

  const onSelect = (date:any) => {
    setDate(date);
    setFieldValue(field.name, date);
  }

  const onChange = (date:any) => {
    setDate(date);
    setFieldValue(field.name, date);
  }

  return (
    <>
      {/*<input type="text" {...field} {...props} />*/}
      <DatePickerWithTimezone
        timezone={TZ_NEW_YORK}
        selected={date}
        onSelect={onSelect} //when day is clicked
        onChange={onChange} //only when value has changed
        {...props}
      />
      {touched[field.name] && errors[field.name] && <div className="help-block errors">{errors[field.name]}</div>}
    </>
  )
};
export default CDateInputComponent;
