import React, {useState, useEffect, useCallback, useMemo} from "react";
import {PrevButton, NextButton, DotButton} from "./Buttons";
import useEmblaCarousel from "embla-carousel-react";
import "./CarouselSingle.scss";
import PropTypes from "prop-types";
import {nf, nf_i} from "../../dnweb3/helpers/utilities";
import CollectionSummary from "../CollectionSummary/CollectionSummary";
import {EnumCollectionLayoutTypes} from "../Collection/Collection";
import useInterval from "../../dnweb3/hooks/useInterval";
import LazyLoad from "react-lazyload";

const propTypes = {
  slides: PropTypes.array.isRequired,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  startIndex: PropTypes.number,
  itemCls: PropTypes.string,
  renderItem: PropTypes.func,
  sliderLeftCls: PropTypes.string,
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
};

const CarouselSingle = (props) => {
  const {slides, showPrev, showNext, loop, slidesToScroll, startIndex, itemCls, renderItem, sliderLeftCls, autoplay, delayLength} = props;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

  const INIT_OPTION = {
    skipSnaps: false,
    startIndex: startIndex || 0,
    loop: true,
  };
  const [viewportRef, embla] = useEmblaCarousel(INIT_OPTION);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(startIndex || 0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const [delay, setDelay] = useState(delayLength);
  const [isRunning, setIsRunning] = useState(autoplay);

  useEffect(() => {
    if (embla && embla.slideNodes().length !== slides.length) {
      embla.reInit(INIT_OPTION);
    }
  }, [embla, slides])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

  useInterval(
    () => {
      if (selectedIndex === scrollSnaps.length - 1) {
        scrollTo(0);
      } else {
        scrollNext();
      }
    },
    isRunning ? delay : null, false
  );

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect, slides]);



  const selectedSlide = useMemo(() => {
    if (!slides) return;
    return slides[selectedIndex];
  }, [slides, selectedIndex]);

  return (
    <div className="collections flex flex-wrap md:flex-nowrap  flex-col-reverse md:flex-row w-full mt-14 md:mt-0 md:w-auto">
      <div className={`slider-left ${sliderLeftCls || ''}`}>
        {selectedSlide && <CollectionSummary item={selectedSlide} layoutStyle={EnumCollectionLayoutTypes.FEATURED}/>}
      </div>
      <div className="slider-wrap ml-0 md:ml-8">
        <div className="embla images">
          <div className="embla__viewport" ref={viewportRef}>
            <div className="embla__container">
              {slides.map((x, index) => (
                renderItem ? renderItem(x, index) :
                  <div className={`embla__slide relative ${itemCls || ''}`} key={index}>
                    <div className="embla__slide__inner">
                      <LazyLoad>
                        <img
                          className="embla__slide__img"
                          src={x.featured_url}
                          alt={x.name}
                        />
                      </LazyLoad>
                    </div>
                  </div>
              ))}
            </div>
            {showPrev && <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled}/>}
            {showNext && <NextButton onClick={scrollNext} enabled={nextBtnEnabled}/>}
          </div>
        </div>
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

CarouselSingle.propTypes = propTypes;

export default CarouselSingle;
