import { CT_METAMASK, CT_COINBASE } from '../constants';
// import { getWeb3ByHttp } from '../helpers/Web3Util';
// import { APP_NETWORK_ID } from '../config';
// import detectEthereumProvider from '@metamask/detect-provider';

declare let window: any;

const ConnectToInjected = async (providerName: string) => {
  let provider = null;
  if (typeof window.ethereum !== 'undefined') {
    if (typeof window.ethereum.providers !== 'undefined') {
      switch (providerName) {
        case CT_COINBASE:
          provider = window.ethereum.providers.find(({ isCoinbaseWallet }: any) => isCoinbaseWallet);
          break;
        case CT_METAMASK:
          provider = window.ethereum.providers.find(({ isMetaMask }: any) => isMetaMask);
          break;
      }
    } else {
      provider = window.ethereum;
    }
    if (provider) {
      if (typeof window.ethereum.providers !== 'undefined') {
        window.ethereum.setSelectedProvider(provider);
      }
      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      await provider.enable();
    }
  } else {
    /*console.log('--> mobile mode?');
    const web3Obj = getWeb3ByHttp(APP_NETWORK_ID);
    await web3Obj.eth.requestAccounts();
    provider = web3Obj.currentProvider;*/
    /*if (provider) {
      await provider.request({ method: 'eth_requestAccounts' });
    }*/
    // legacy browser
    if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      // provider = await detectEthereumProvider();
      const domain = new URL(window.location.href);
      window.open(`https://metamask.app.link/dapp/${domain.hostname.replace('www.', '')}`);
      /*if (!provider) {
        throw new Error('Please install MetaMask or Coinbase!');
      }*/
    }
  }
  return provider;
};

export default ConnectToInjected;
