export const APP_API = process.env.REACT_APP_API;
export const APP_MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
export const APP_TILE_IMAGE = process.env.REACT_APP_MEDIA_BASE_URL + '/tiles';

export const priceEthPerBlock = 1;

export const SOCIAL_URL_TELEGRAM = '';
export const SOCIAL_URL_TWITTER = 'https://twitter.com/DropSpaceNFT';
export const SOCIAL_URL_INSTAGRAM = '';
export const SOCIAL_URL_OPENSEA = 'https://opensea.io/collection/dropspace-mint-tickets';
export const SOCIAL_URL_DISCORD = 'https://discord.gg/dropspacenft';
export const SOCIAL_URL_REDDIT = '';

export const OPENSEA_BUYING_URL = '#';
export const TOS_URL = '#';
export const PRIVACY_POLICY_URL = '#';

export const TZ_LOS_ANGELES = 'America/Los_Angeles';
export const TZ_NEW_YORK = 'America/New_York';
export const TZ_SERVER = TZ_NEW_YORK;

