import React, {useState, useEffect, useCallback} from "react";
import {PrevButton, NextButton} from "./Buttons";
import useEmblaCarousel from "embla-carousel-react";
import "./style.scss";
import PropTypes from "prop-types";

const propTypes = {
  slides: PropTypes.array.isRequired,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  startIndex: PropTypes.number,
  itemCls: PropTypes.string,
  renderItem: PropTypes.func,
};

const Carousel = (props) => {
  const {slides, showPrev, showNext, loop, slidesToScroll, startIndex, itemCls, renderItem} = props;
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  // const slidesToScroll = vw >= 1024 ? 6 : 2;
  const INIT_OPTION = {
    loop: loop,
    skipSnaps: false,
    containScroll: "trimSnaps",
    dragFree: false,
    slidesToScroll: slidesToScroll || 2,
    startIndex: startIndex || 0,
  };

  const [viewportRef, embla] = useEmblaCarousel(INIT_OPTION);

  useEffect(() => {
    if (embla && embla.slideNodes().length !== slides.length) {
      embla.reInit(INIT_OPTION);
    }
  }, [embla, slides]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {slides.map((x, index) => (
            renderItem ? renderItem(x, index) :
              <div className={`embla__slide relative ${itemCls || ''}`} key={index}>
                <div className="embla__slide__inner">
                  <img
                    className="embla__slide__img"
                    src={x.img}
                    alt={x.title}
                  />
                </div>
                <p>{x.title}</p>
              </div>
          ))}
        </div>
      </div>
      {showPrev && <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled}/>}
      {showNext && <NextButton onClick={scrollNext} enabled={nextBtnEnabled}/>}
    </div>
  );
};

Carousel.propTypes = propTypes;

export default Carousel;
