import React from 'react';

const AboutProject = () => {
  return (
    <div className="page-body text-black2 my-5 md:my-15 my-20" style={{minHeight: 'unset'}}>
      <div className="container">
        <div className="mx-auto max-w-2xl">
          <h1 className="base text-left pt-10 md:pt-20">
            <div className="inline-block align-middle border-b-4 border-black pl-12 md:pl-25 mr-8 md:mr-12"></div>
            A decentralized <br/>NFT launchpad.
          </h1>
          <div className="flex flex-wrap mt-10 md:mt-20">
            <p className="my-4 md:my-4">dropspace is a premium minting platform. </p>
            <p className="my-4 md:my-4 flex items-baseline ml-4"><span className="inline-block pb-1 border-t-2 border-black pl-3 md:pl-5 mr-4"></span><span>Power the launch of your project with our industry-leading tech by seamlessly connecting your art to our bullet-proof smart contracts. </span></p>
            <p className="my-4 md:my-4 flex items-baseline ml-4"><span className="inline-block pb-1 border-t-2 border-black pl-3 md:pl-5 mr-4"></span><span>Connect with our community of minters looking for top projects to mint.</span></p>
            <p className="my-4 md:my-4 flex items-baseline ml-4"><span className="inline-block pb-1 border-t-2 border-black pl-3 md:pl-5 mr-4"></span><span>Enjoy a smooth launch of your NFT collection.</span></p>
            {/*<div className="w-full md:w-1/2 mt-10 md:mt-15 lg:mt-25 md:px-10">
						<h3 className="font-rox text-10 leading-12 mb-8 md:mb-14">Curated Platform</h3>
						<p>
							Collections that have been hand-selected by the dropspace team as having outstanding art, teams, utility, and more.
						</p>
					</div>
					<div className="w-full md:w-1/2 mt-10 md:mt-15 lg:mt-25 md:px-10">
						<h3 className="font-rox text-10 leading-12 mb-8 md:mb-14">Public Platform</h3>
						<p>
							A decentralized launchpad where any project can mint their collection using our industry-leading tech.
						</p>
					</div>*/}
          </div>
        </div>
        <div className="mt-10 md:mt-15 lg:mt-25">
          <p className="text-center">Interested in launching with us? Email <a href="mailto:info@dropspace.art" className="underline">info@dropspace.art</a></p>
        </div>
      </div>
    </div>
  );
}
export default AboutProject;
