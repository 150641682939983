import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import useBodyScrollbar from '../../hooks/useBodyScrollbar';
import { CloseEle, LogoEle, NAV_LINKS, NavLinks } from "./Navbar";
import useNavbar from "../../hooks/useNavbar";
import {PRIVACY_POLICY_URL, SOCIAL_URL_DISCORD, SOCIAL_URL_TWITTER, TOS_URL} from '../../config';
import SearchBox from '../SearchBox/SearchBox';
import useAuth from '../../hooks/useAuth';

interface IDropdownProps {
  isOpen: any,
  toggle: any,
}

const NavbarMobile = ({isOpen, toggle}: IDropdownProps) => {
  const location = useLocation();
  const {
    renderConnectButton,
    renderAccountModals
  } = useNavbar(isOpen);

  useBodyScrollbar(isOpen);

  const {isAdmin, token} = useAuth();

  return (
    <div
      className={
        isOpen
          ? 'header-nav-modal flex flex-col text-center items-center text-sm2 text-white bg-black fixed w-full h-full top-0 left-0 z-50'
          : 'hidden'
      }
      style={{padding: '140px 0 40px', overflowY: 'auto'}}
    >
      <div className="flex items-center justify-between absolute top-0 left-0 w-full pt-14 px-4">
        <LogoEle black={false}/>

        <div className='cursor-pointer' onClick={toggle}>
          <CloseEle />
        </div>
      </div>

      <div className="w-full px-4 mb-8">
        <SearchBox forceShow extraCls='w-full' closeNavMenu={toggle} isInMobile />
      </div>

      {renderConnectButton()}

      <NavLinks navLinks={NAV_LINKS} itemClassName={'mt-4 lg:hidden'} isMobileMenu={true} />

      {isAdmin && (
        <>
          <Link to={'/create-collection'} className="nav mt-4 hover:opacity-95 lg:hidden">Create</Link>
        </>
      )}

      {renderAccountModals()}

      <a className="social-link nav twitter pb-4 mt-4 md:pb-0 px-5 md:px-6" href={SOCIAL_URL_TWITTER} target="_blank">Twitter</a>
      <a className="social-link nav discord pb-4 md:pb-0 px-5 md:px-6" href={SOCIAL_URL_DISCORD} target="_blank">Discord</a>

      {/*<a className="social-link nav pb-4" href={TOS_URL} target="_blank">Terms of service</a>
      <a className="social-link nav pb-4 " href={PRIVACY_POLICY_URL} target="_blank">Privacy policy</a>*/}
    </div>
  );
};

export default NavbarMobile;
