import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Button from "../Button";
import {IMapStateToProps} from "../../types";
import {setLoading, setLoadingType} from "../../redux/auth/actions";
import {LOADING_TYPE_BUYING_TICKET, MSG_NO_CURRENCY} from "../../dnweb3/constants";
import BigNumber from "bignumber.js";
import {getAppControllers} from "../../dnweb3/controllers";
import {EnumSCType} from "../../dnweb3/controllers/contract";
import useNavbar from "../../hooks/useNavbar";
import ImgSuccess from '../../assets/images/icon/checkout-success.svg';
import ImgCloseDark from "../../assets/images/icon/close-dark.svg";
import useBodyScrollbar from "../../hooks/useBodyScrollbar";
import useGraphTicketDataUpdater from "../../hooks/useGraphTicketDataUpdater";
import {InitialTicketData} from "../../dnweb3/types/ticketDataTypes";
import {bnToDec, nf, nf_i} from "../../dnweb3/helpers/utilities";
import useInterval, {TimerDelays} from "../../dnweb3/hooks/useInterval";
import {Link, useLocation} from "react-router-dom";
import LinkButton from "../LinkButton";
import {SOCIAL_URL_DISCORD, SOCIAL_URL_OPENSEA} from "../../config";
import {getLocalData, setLocalData} from "../../dnweb3/helpers/LsUtil";
import './MintTicket.scss';
import Countdown from "react-countdown";
import {ICountDownValue} from "../../hooks/useBuyNFT";
import AppMsg from "../../dnweb3/helpers/AppMsg";

const STEP_CHECKOUT = 1;
const STEP_SUCCESS = 2;

export default function MintTicket() {
  const dispatch = useDispatch();
  const location = useLocation();
  const saleActiveParam = new URLSearchParams(location.search).get("saleActive") || '';

  const [qty, setQty] = useState(1);
  const [step, setStep] = useState<number>(-1);

  const [alertOpen, setAlertOpen] = useState<any>(getLocalData(`DS-alert`));
  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3,
    },
    global: {
      ticketDataList
    }
  } = useSelector((state: IMapStateToProps) => state);

  useBodyScrollbar(step > 0 || loading && loadingType > 0);

  const {
    renderAccountModals,
    setVisibleConnectModal
  } = useNavbar(false);

  const {updater: updateTickets} = useGraphTicketDataUpdater();

  useInterval(async () => {
    await updateTickets();
  }, TimerDelays.SLOW_INTERVAL_90);

  const {
    saleActive: saleActiveSC,
    mintLimit,
    nftTotal,
    nftSold,
    price,
  } = ticketDataList[0] || InitialTicketData;

  // const saleActive = saleActiveParam ? (saleActiveParam == '1' ? saleActiveSC : false) : saleActiveSC;
  const saleActive = saleActiveSC;   // production version.
  // todo: dev code...
  // const saleActive = true;
  // const nftTotal = 81;

  const getPackagePrice = useCallback(async (packageQty: number) => {
    return await getAppControllers(web3).contract.getPrice(packageQty, EnumSCType.Ticket);
  }, [web3]);

  const onClickMint = async (packageQty?: number) => {

    const buyingQty = packageQty ? packageQty : qty;
    if (!loggedIn || !address) {
      setVisibleConnectModal(true);
      return;
    }
    if (buyingQty < 1) {
      AppMsg.info("Please fill quantity correctly!");
      return;
    }

    dispatch(setLoadingType(LOADING_TYPE_BUYING_TICKET));
    const balance: BigNumber = await getAppControllers(web3).contract.getUserBalance(address);
    const priceTotal: BigNumber = await getPackagePrice(buyingQty);

    if (priceTotal.lte(balance)) {
      getAppControllers(web3).contract.buyMultiple(address, buyingQty, priceTotal, EnumSCType.Ticket).then(async res => {
        await updateTickets();
        setStep(STEP_SUCCESS);
      }).catch(reason => {
        console.error(reason);
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      AppMsg.info(MSG_NO_CURRENCY);
      dispatch(setLoading(false));
    }
  };

  const changeQty = (dir: number) => {
    if (!saleActive) return;
    setQty((prev) => {
      let newQty = prev + dir;
      if (newQty < 1) newQty = 1;
      if (newQty > (mintLimit || 0)) newQty = (mintLimit || 0);
      return newQty;
    });
  }

  const onChangeQty = (e: any) => {
    let newQty = parseInt(e.target.value);
    if (isNaN(newQty) || newQty < 1) newQty = -1;
    if (newQty > (mintLimit || 0)) newQty = (mintLimit || 0);
    setQty(newQty);
  }

  const onCloseHandler = () => {
    if (step == STEP_SUCCESS) {
      setStep(-1);
    }
  }

  const onCloseAlertHandler = () => {
    setLocalData(`DS-alert`, 1);
    setAlertOpen(1);
    return true;
  }

  const renderByStep = () => {
    switch (step) {
      case STEP_SUCCESS:
        return (
          <>
            <img className="absolute right-0 top-0 mr-6 mt-10 cursor-pointer" src={ImgCloseDark} onClick={onCloseHandler}/>
            <div className="text-center"><img className="mb-10 mx-auto" src={ImgSuccess} width={64} height={64}/></div>
            <h4 className="text-3xl tracking-10 font-bold font-stinger_fit text-center mt-8">
              Mint successful!
            </h4>
          </>
        );
        break;
    }
  }

  const renderCountDown = (pValue: ICountDownValue) => {
    const value = pValue;
    let wrapCls = '';
    if (value && value.formatted.days == '0') {
      wrapCls += ' text-live';
    }
    return value && (
      <div className={`flex max-w-64 w-full justify-between font-bold ${wrapCls}`}>
        {value.formatted.days !== '0' ? (
          <>
            <div className="hour flex flex-col items-center">
              <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.days}</p>
              <p className="font-inter text-base leading-7">Days</p>
            </div>
            <span className="counter-time font-suisse font-bold text-3xl">:</span>
          </>
        ) : ''}
        <div className="hour flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.hours}</p>
          <p className="font-inter text-base leading-7">Hours</p>
        </div>
        <span className="counter-time font-suisse font-bold text-3xl">:</span>
        <div className="minute flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.minutes || 0}</p>
          <p className="font-inter text-base leading-7">Mins</p>
        </div>
        <span className="counter-time font-suisse font-bold text-3xl">:</span>
        <div className="second flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.seconds || 0}</p>
          <p className="font-inter text-base leading-7">Secs</p>
        </div>
      </div>
    );
  }


  const soldOut = parseInt(`${nftSold || 0}`) >= (nftTotal || 500);

  return (
    <div id='ticket' className={`mint-ticket py-12 px-8 border border-black2 max-w-2xl text-center mx-auto ${saleActive && !soldOut}`}>
      <h2 className="base mb-6 md:mb-8">dropspace <br />Mint Tickets</h2>
      <div className="font-suisse font-normal text-4 leading-7_5 mt-5">
        <p className="font-normal text-4_5 leading-7_5">DMT holders get a pre-sale mint window on every drop that launches on dropspace. Right now 350 DMTs are in circulation, and only 500 will ever exist.</p>
        <p className="font-normal text-4_5 leading-7_5 mt-10">Tickets will be sold incrementally with drops being announced on our <a className="underline" target={'_blank'} href={SOCIAL_URL_DISCORD}>Discord</a>.
          You can also find one in the secondary market on <a className="underline" target={'_blank'} href={SOCIAL_URL_OPENSEA}>Opensea</a>. </p>
        <p className="font-normal text-4_5 leading-7_5 mt-10">As we build the expanding dropspace ecosystem, DMT holders will be at the center of everything we do. And it’s a lifetime membership, meaning the tickets are never burned and always reusable.</p>
      </div>

      {soldOut && <div>
        <div className="text-2xl text-live font-bold text-center my-6">Sold out</div>
      </div>}

      <div className="flex items-center justify-between mt-8">
        {!saleActive && <div className="hidden md:block border-b border-black border-opacity-50 w-2/12"></div>}
        {soldOut ? <>
            <LinkButton
              disable={saleActive}
              className="btn-white black cursor-pointer mx-auto"
              target={'_blank'}
              linkTo={"https://opensea.io/collection/dropspace-mint-tickets"}
              external
            >Buy a ticket on Opensea</LinkButton>
          </>
          :
          <div className="flex items-center justify-around mx-auto">
            {saleActive && <>
              <div className="text-5 font-bold">{saleActive ? nf(bnToDec(price) || 0.01, 3) : 0.01} ETH</div>
            </>}
            {
              (saleActive) ? (
                <div className="flex flex-wrap ml-6 lg:ml-6 xl:ml-8">
                  <div className="input-wrap flex rounded-default space-x-1 mr-6 lg:mr-6 xl:mr-8">
                    <div className={`control ${saleActive ? '' : 'disabled'}`} onClick={() => changeQty(-1)}>-</div>
                    <input type="text" disabled={!saleActive} className="bg-white" value={qty > 0 ? qty : ''} onChange={onChangeQty}/>
                    <div className={`control ${saleActive ? '' : 'disabled'}`} onClick={() => changeQty(1)}>+</div>
                  </div>
                  <Button
                    className="btn-default ml-4 flex items-center btn-white black outline-none mt-4 sm:mt-0 sm:mt-0"
                    onClick={e => saleActive && onClickMint()}
                  ><span className="font-suisse text-4 font-normal leading-4 font-normal">{saleActive ? 'Mint Now' : 'Coming soon'}</span>
                  </Button>
                </div>
              ) : (
                <div className="font-suisse mt-6">
                  <div className="text-gray2 font-medium"><span>150 Mint tickets launching on </span><span className="font-semibold text-black1">22nd Oct, 2021 12pm EST</span></div>
                  <div className="mint-wrap my-6">
                    <div className="flex justify-center">
                      <Countdown
                        date='2021-10-22T12:00:00-04:00'
                        zeroPadDays={2}
                        zeroPadTime={2}
                        renderer={renderCountDown}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>}
        {!saleActive && <div className="hidden md:block border-b border-black border-opacity-50 w-2/12"></div>}
      </div>
      {saleActive && (<>
        <div className="font-suisse my-4 text-center text-gray-700">{nf_i(nftSold) || '0'} / {nf_i(nftTotal)} tickets sold</div>
        <div className="text-xs font-suisse italic pt-4">You can only mint {mintLimit} NFTs in one transaction.</div>
      </>)}

      {!saleActive && <Link className={"mt-4 italic underline text-xl text-suisse text-black1 font-normal  lg:mt-8 max-w-max flex flex-0 mx-auto items-center outline-none"} to={'/#newsletter'}>Subscribe to our newsletter!</Link>}
      {<a className={"mt-4 italic underline text-xl text-suisse text-black1 font-normal  lg:mt-8 max-w-max flex flex-0 mx-auto items-center outline-none"} href={'https://discord.gg/dropspacenft'} target="_blank">Join our discord!</a>}

      {!loggedIn && renderAccountModals()}
      <div
        className={`modal-checkout modal ${(step > 0) ? '' : 'hidden'} flex flex-col fixed w-screen min-h-full top-0 left-0 flex items-center shadow-lg justify-center z-50`}
      >
        <div className="modal-overlay absolute w-screen h-full bg-black opacity-50 pointer-events-none" onClick={onCloseHandler}></div>
        <div className="modal-container max-h-screen90 bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto relative">
          <div className="modal-content pt-14 pb-10 sm:pt-14 sm:pb-14 text-left px-8 sm:px-14 items-center justify-center text-black">
            {renderByStep()}
          </div>
        </div>
      </div>

      {alertOpen != 1 && <div className="ticket-alert fixed  max-w-100 bottom-0 left-0 mx-4 z-10 bg-white md:mx-10 mb-2 md:mb-8 border border-black2 text-left backdrop-filter backdrop-blur-md px-4 py-4 md:px-8 md:py-8">
        <img className="absolute right-0 top-0 mr-6 mt-6 cursor-pointer" src={ImgCloseDark} onClick={onCloseAlertHandler}/>
        <div className="text-live text-3_5 font-suisse">NEW</div>
        <h3 className="style3 mt-2">Mint Tickets —</h3>
        <p className="text-4 leading-7_5 font-suisse mt-4">dropspace will be selling <span className="font-bold">500 mint tickets</span> to our OG community. Holding a ticket will grant you early access to future top-tier drops.</p>
        <LinkButton linkTo={'/#ticket'} className="btn-white black flex max-w-max mt-4 ml-auto">Get my Mint Ticket</LinkButton>
      </div>}
    </div>
  )
}
