import React, {useEffect, useState} from 'react';
import useCollections from "../../hooks/useCollections";
import {IPaginationParam} from "../../dnweb3/types";
import Carousel from "../Carousel/Carousel";
import {IMapStateToProps} from "../../types";
import {ICollection} from "../../dnweb3/types";
import CollectionCard, {EnumCollectionLayoutTypes} from "../Collection/CollectionCard";
import useWindowWidth from "../../hooks/useWindowWidth";
import {useSelector} from "react-redux";
import SLoadingWrap from "../SLoadingWrap";

const LiveCollectionsListing = () => {

  const {vw} = useWindowWidth();
  const {
    authUser: {
      loading,
    },
  } = useSelector((state: IMapStateToProps) => state);

  const [fetchParams, setFetchParams] = useState<IPaginationParam>({
    sortBy: "start_drop_date",
    sortDir: 'desc',
    page: 1,
    perPage: 12,
    filters: {is_live: 1, is_sold_out: 0, is_listing: 1}
  });

  const {items, hasMore, loading: liveLoading, pagination} = useCollections(fetchParams);
  const fetchNext = () => {
    setFetchParams(prevState => ({...prevState, page: pagination.currentPage + 1}));
  };

  return <div className="container">
    {items.length > 0 && <>
      <h2 className="style-icon mt-12 md:mt-20 lg:mt-25">Live collections</h2>
      {/*<SLoadingWrap className="items flex flex-wrap -mx-3 xl:-mx-7 min-h-60" loading={liveLoading} maskCls={'parent'}>
        {items && items.length < 1 ? <p className="px-3 xl:px-7">There are no upcoming collections.</p> : <>
          {items && items.map((item, ind) => <div key={item.project_id} className="md:w-6/12 lg:w-4/12 px-3 xl:px-7 mb-8 md:mb-6 xl:mb-14">
              <CollectionCard key={item.project_id} item={item} className="" layoutStyle={EnumCollectionLayoutTypes.NORMAL}/>
            </div>
          )}
        </>}
      </SLoadingWrap>
      {hasMore && <h2 className={`style-icon mx-auto my-10 md:my-18 text-center ${liveLoading ? 'no-icon' : ''}`}>
        {liveLoading ? <span>Loading...</span>
          : <span className="cursor-pointer" onClick={fetchNext}>Load <span className="italic">more</span></span>
        }
      </h2>}*/}
      <div className="items-live flex min-h-60">
        <SLoadingWrap className="carousel-multi flex flex-wrap -mx-3 md:mx-0 md:flex-nowrap" loading={liveLoading} maskCls={'p'}>
          {!items || items.length < 1 ? <p>There are no live collections.</p> : <>
            {vw >= 768 ? <Carousel
                slides={items}
                showPrev
                showNext
                renderItem={(item: ICollection, index: number) => <div className={`embla__slide relative`} key={index}>
                  <div className="embla__slide__inner md:pr-6 lg:pr-6 xl:pr-14">
                    <CollectionCard key={item.project_id} item={item} className="" layoutStyle={EnumCollectionLayoutTypes.NORMAL}/>
                  </div>
                </div>
                }
              /> :
              items && items.map((item, ind) => <div key={item.project_id} className="w-full md:w-6/12 lg:w-4/12 px-3 md:px-3 xl:px-7 mb-14">
                <CollectionCard key={item.project_id} item={item} className="" layoutStyle={EnumCollectionLayoutTypes.NORMAL}/>
              </div>
              )
            }
          </>}
        </SLoadingWrap>
      </div>
    </>
    }
  </div>
}

export default LiveCollectionsListing;
