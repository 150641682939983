import React, {useCallback, useMemo, useRef, useState, forwardRef, useImperativeHandle, ForwardedRef} from "react";
import Cropper from "react-easy-crop";
import ReactSlider from "react-slider";
import CropUtil from "./CropUtil";
import {Point} from "react-easy-crop/types";

export interface ICroppedAreaPixels {
  x: number,
  y: number,
  width: number,
  height: number,
}

export const DefaultCroppedAreaPixels: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

interface ICropImageProps {
  file?: any,
  imgSrc?: any,
  blockDimension?: { x: number, y: number }
  croppedAreaPixels: ICroppedAreaPixels,
  setCroppedAreaPixels: any,
  initialCroppedAreaPixels?: any,

  getAlert?: any,
  children?: any,
}

interface ICropImageRefProps {
  getAlert?: any,
  getCropImage: any,
}


const CropImage = forwardRef<ICropImageRefProps, ICropImageProps>((props, ref) => {
  const {
    file,
    imgSrc,
    blockDimension,
    croppedAreaPixels,
    setCroppedAreaPixels,
    initialCroppedAreaPixels
  }: ICropImageProps = props;

  const cropImgWrapRef = useRef<any>(null);

  // crop
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);

  const [croppedImage, setCroppedImage] = useState<any>(null);

  const onCropChange = useCallback((location:Point) => {
    setCrop(location);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      /*const croppedImage = await getCroppedImg(
        file,
        croppedAreaPixels,
      )
      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)*/
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  /** Calculate the max zoom value */
  const maxZoom = useMemo(() => {
    let value = 10;
    if (file && file.width && cropImgWrapRef) {
      value = file.width / cropImgWrapRef.current.offsetWidth;
      // console.log("Calculated Max Zoom = ", value, cropImgWrapRef.current.offsetWidth, file.width);
    }
    return value;
  }, [file, cropImgWrapRef]);

  const aspectRatio = useMemo(() => {
    let value = 1;
    // @ts-ignore
    if (blockDimension.x > 0 && blockDimension.y > 0) {
      // @ts-ignore
      value = blockDimension.x / blockDimension.y;
    }
    return value;
  }, [file, cropImgWrapRef]);


  useImperativeHandle(ref, () => ({
    async getCropImage() {
      try {
        const croppedImage = await CropUtil.getCroppedImg(
          imgSrc,
          // @ts-ignore
          croppedAreaPixels,
          0
        )
        // console.log('done', croppedImage);
        return croppedImage;
      } catch (e) {
        console.error(e)
      }
    }
  }), [croppedAreaPixels]);

  return (
    <>
      <div className="img" ref={cropImgWrapRef}>
        {imgSrc && (
          <>
            <Cropper
              image={imgSrc}
              crop={crop}
              zoom={zoom}
              zoomSpeed={maxZoom / 40}
              aspect={aspectRatio}
              showGrid={false}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              initialCroppedAreaPixels={initialCroppedAreaPixels}
              /*onMediaLoaded*/
            />
          </>
        )}
      </div>
      {imgSrc && (
        <div className="my-2">
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="crop-thumb"
            trackClassName="crop-track"
            min={1}
            max={maxZoom}
            step={maxZoom / 40}
            value={zoom}
            onChange={value => setZoom(value)}
            renderThumb={(props, state) => <div {...props}></div>}
          />
        </div>
      )}

      {imgSrc && croppedImage && (
        <div><img src={croppedImage || ''}/></div>
      )}
    </>
  );
});
export default CropImage;
