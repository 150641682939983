import React, {useState, useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";

import "./index.scss";
import {PRIVACY_POLICY_URL, SOCIAL_URL_DISCORD, SOCIAL_URL_INSTAGRAM, SOCIAL_URL_OPENSEA, SOCIAL_URL_TELEGRAM, SOCIAL_URL_TWITTER, TOS_URL} from "../../config";
import {LogoEle} from "../Navbar/Navbar";

const Footer = ({history}: { history: any }) => {

  return (
    <div className="footer bg-white text-black relative font-suisse pb-9 lg:pb-14">
      <div className="container mx-auto">
        <div className="mb-6"><LogoEle black/></div>
        <div className="footer-content relative flex flex-wrap items-start mx-auto">
          <p className="lg:relative lg:block max-w-xs lg:max-w-full font-suisse text-3_5 leading-3_5 pb-4 lg:mt-0">© Copyright {new Date().getFullYear()} dropspace.<span className="block lg:hidden mt-2 lg:mt-0"/> A DeFi Network Product.</p>

          <div className='social-links flex ml-auto lg:mx-0'>
            <a className="social-link twitter pb-4 ml-0 lg:ml-6" href={SOCIAL_URL_TWITTER} target="_blank">Twitter</a>
            <a className="social-link discord pb-4 ml-6 sm:ml-6" href={SOCIAL_URL_DISCORD} target="_blank">Discord</a>
          </div>

          {/*<div className="hidden md:flex ml-0 sm:ml-auto">
            <a className="social-link twitter pb-4 ml-0 sm:ml-6" href={TOS_URL} target="_blank">Terms of service</a>
            <a className="social-link twitter pb-4 ml-6 sm:ml-6" href={PRIVACY_POLICY_URL} target="_blank">Privacy policy</a>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);

