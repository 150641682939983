import React, {Ref, useEffect, useMemo, useRef, useState} from 'react';

import "./Newsletter.scss";
import Button from "../../components/Button";
import AppApi from "../../dnweb3/appapi";
import {validateEmail, isEnterKeyPressed} from "../../dnweb3/helpers/utilities";
import AppMsg from "../../dnweb3/helpers/AppMsg";


const Newsletter = () => {
  const refEmail = useRef<any>();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const onClickSubmit = () => {
    if (!validateEmail(email)) {
      AppMsg.error('Please input the valid email address');
      refEmail.current.focus();
      return;
    }
    AppApi.post('/subscribers', {email}).then(res => {
      if (res.data.status == 'success') {
        setSubmitted(true);
      }
    }).catch(reason => {
      const msg = AppApi.getResponseErrorMsg(reason);
      AppMsg.error(msg ? msg : 'Failed to subscribe! Please try again!');
    });
  };

  return (
    <div className="section newsletter" id="newsletter">
      <div className="container flex flex-col items-center">
        {/*<h1 className="base text-center lg:text-left mb-44">Join our newsletter</h1>*/}
        <h2 className="base mt-32 md:mt-18 lg:mt-24 xl:mt-44 mb-6 md:mb-8 text-center">Subscribe and never miss a drop.</h2>
        <div className="flex mt-8 w-full justify-center lg:w-auto">
          {!submitted && <>
            <input className="input input-newsletter px-5 font-suisse text-3_75 leading-3_75" placeholder="Enter email address..."
                   ref={refEmail}
                   value={email}
                   onChange={e => setEmail(e.target.value)}
                   onKeyDown={e => isEnterKeyPressed(e) && onClickSubmit()}
            />
            <Button
              className="btn-default ml-4 flex items-center"
              onClick={onClickSubmit}
            ><span className="md:mr-4 font-suisse text-4 font-normal leading-4 font-normal">Subscribe</span>
              <svg className="hidden md:block" width="37" height="9" viewBox="0 0 37 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 4.5L36 4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M32 0.5L36 4.5L32 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Button>
          </>}
          {submitted && <div className="font-inter text-lg leading-loose normal max-w-sm text-center" style={{color: '#01CBAF'}}>Thanks for joining us! We will keep you posted on the latest drops.</div>}
        </div>
      </div>

    </div>
  );
}
export default Newsletter;
