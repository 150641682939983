import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from "react-router-dom";

import './index.scss';

import Newsletter from "./Newsletter";
import ImgContentSep from "../../assets/images/bg-section-sep.png";
import ImgLogoTheDefi from "../../assets/images/logo-thedefi.png";
import FeaturedCollections from "../../components/FeaturedCollections/FeaturedCollections";
import LiveCollections from "../../components/LiveCollections/LiveCollections";
import MintTicket from "../../components/MintTicket/MintTicket";
import useInterval, {TimerDelays} from "../../dnweb3/hooks/useInterval";
import {useDispatch, useSelector} from "react-redux";
import {incrementRefreshTick} from "../../redux/global/actions";
import {ICollection} from "../../dnweb3/types";
import {dtInTz} from "../../dnweb3/helpers/utilities";
import {TZ_LOS_ANGELES, TZ_NEW_YORK} from "../../config";
import {IMapStateToProps} from "../../types";

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [newsItem, setNewsItem] = useState<ICollection>();

  const {
    authUser: {
      address,
      loggedIn,
    }
  } = useSelector((state: IMapStateToProps) => state);

  useEffect(() => {
    const body: any = document.querySelector('body');
    body.classList.add('home');
    return () => {
      body.classList.remove('home');
    }
  }, []);

  /**
   * Refresh home page collections.
   * */
  useInterval(() => {
    dispatch(incrementRefreshTick());
  }, TimerDelays.SLOW_INTERVAL_90, false);

  const newsTitle = useMemo(() => {
    if (!newsItem || !newsItem.start_drop_date) return null;

    const mObj = dtInTz(newsItem.start_drop_date, TZ_NEW_YORK, TZ_NEW_YORK);
    const mObj2 = dtInTz(newsItem.start_drop_date, TZ_LOS_ANGELES, TZ_NEW_YORK);
    const title = mObj2 ? `${mObj2.format('MMM Do h:mma')} ${' PST' || mObj2.format('h:mma z')}` :
      newsItem.start_drop_date;
    const title2 = mObj ? ` (${mObj.format('h:mma')} ${' EST' || mObj.format('h:mma z')} )`  :
      '';

    // Oct 8th. 10:30am PST ( 1:30pm EST )
    let name = newsItem.name;
    const lastChar = name.substr(name.length-1);
    if (lastChar != "!" && lastChar != "."){
      name = name + ".";
    }

    return (
      <div className="text-6 flashing">{name} <span className="pl-4"></span> {title} {title2}</div>
    );
  }, [newsItem?.project_id]);

  return (
    <div className="page-body home">
      <div className="home-feature container relative">
        {/*<Link to={'/#mint-ticket'}>
          <div className="mt-15 lg:mt-25 border border-white bg-black2 py-6 px-14">
            <div className="text-live text-3_5 font-suisse">NEW</div>
            <div className="text-6 flashing">Mint Tickets. <span className="pl-4"></span> Oct 22nd 9:00am PST (12:00pm EST)</div>
          </div>
        </Link>*/}

        {/*{newsItem && newsTitle &&  <>
          <Link to={`/collection/${newsItem.name_uid}`}>
            <div className="mt-15 lg:mt-25 border border-white bg-black2 py-6 px-4 md:px-14">
              <div className="text-live text-3_5 font-suisse">NEW</div>
              {newsTitle}
            </div>
          </Link>
        </>}*/}

        <Link to={`/collection/90s-babes`}>
          <div className="mt-15 lg:mt-25 border border-white bg-black2 py-6 px-4 md:px-14">
            <div className="text-live text-3_5 font-suisse">NEW</div>
            <div className="text-6 flashing">90s Babes. <span className="pl-4"></span> Mar 1st 9:00am PST (12:00pm EST )</div>
          </div>
        </Link>

        <div className="flex flex-wrap flex-row text-white relative w-full md:max-w-2xl xl:max-w-2xl mt-16 md:mt-36 xl:mt-48 3xl:mt-48 ">
          <h1 className="base text-left max-w-2xl">
            {/*<div className="hidden md:hidden align-middle border-b-4 border-white pl-12 md:pl-25 mr-8 md:mr-12"></div>
            Top-tier mints <br className="inline"/>
            <div className="xs:inline-block align-middle border-b-4 border-white pl-12 md:pl-25 mr-8 md:mr-12"></div>
            drop <span className="italic">here.</span>*/}
            <div className="xs:inline-block align-middle border-b-4 border-white pl-12 md:pl-25 mr-8 md:mr-12"></div>A decentralized <br className="inline"/>NFT launchpad.
          </h1>
          <p className="mt-8 md:max-w-lg lg:max-w-sm xl:max-w-xl">
            dropspace is a premium minting platform, designed to provide industry-leading tech for the launch of your NFT collection.
          </p>
        </div>
        <div className="xl:absolute bottom-0 right-0 md:-mr-12 lg:-mr-14 xl:mr-0 lg:-mt-16 xl:mt-0">
          <FeaturedCollections/>
        </div>
      </div>

      <div className="home-live container relative"  id="home-cured">
        <div className="flex flex-wrap flex-row text-white relative w-full lg:max-w-2xl xl:w-7/12 3xl:w-6/12 mt-25 md:mt-20 lg:mt-20 xl:mt-28 3xl:mt-0">
          <h2 className="base text-left"><div className="xs:inline-block align-middle border-b-4 border-white pl-12 md:pl-25 mr-8 md:mr-12"></div>Top-tier drops{/*<br/>
            Explore our <span className="text-live italic">curated collections</span> below.*/}</h2>
          <p className="mt-10 md:max-w-xl lg:max-w-2xl lg:pr-12 xl:pr-0 xl:max-w-3xl">
            Explore some of our favorite collections launching on dropspace.
          </p>
        </div>
        <LiveCollections setNewsItem={setNewsItem} />
      </div>

      <div className="container md:pt-18 lg:pt-22.5 xl:pt-25 3xl:pt-36">
        <div className="flex items-center justify-center space-x-6">
          <svg className="hidden md:block" width="37" height="9" viewBox="0 0 37 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.5L36 4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 0.5L36 4.5L32 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <Link to="/collections" className="text-8_75 leading-15 border-b border-white border-opacity-25">Browse all projects</Link>
          <svg className="transform rotate-180 hidden md:block" width="37" height="9" viewBox="0 0 37 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.5L36 4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 0.5L36 4.5L32 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>

      <Newsletter/>

      {/*<div className="border-b border-white mt-8 mb-6 md:mt-11 md:mb-9 mx-auto" style={{width: 40}}></div>
      <div className="container py-1">
        <div className="thedefi-logo text-center flex justify-center inline-block mx-auto">
          <a className="flex items-center font-suisse text-5" href="https://thedefi.network" title="Open thedefi.network.">
            <img className="inline-block mr-4" src={ImgLogoTheDefi} width={25} height={28}/>
            <span>A DeFi Network Product</span>
          </a>
        </div>
      </div>*/}

      <div className="bg-white relative w-full h-8 lg:h-12 xl:h-24">
        <img className="absolute 3xl:relative max-w-full w-full h-full" src={ImgContentSep}/>
      </div>

      <div className="bg-white text-black">
        <div className="container py-20">
          <div className="flex flex-wrap -ml-9">
            <div className="lg:w-6/12 xl:w-5/12 px-9">
              <h1 className="base">What is drop<span className="italic">space?</span></h1>
            </div>
            <div className="lg:w-6/12 xl:w-5/12 px-9 mt-10 lg:mt-0">
              <p>A decentralized platform for discovering and minting top NFT collections.</p>
            </div>
          </div>

          <div className="flex flex-wrap md:flex-nowrap pt-20 -mx-4 md:-mx-4 lg:-mx-6 xl:-mx-9">
            <div className="px-4 md:px-4 lg:px-6 xl:px-9 mt-10 md:mt-0 min-w-1/3">
              <div className="border-b border-gray-400 my-6" style={{width: 60}}></div>
              <h3 className="style3 font-rox">A Community</h3>
              <p className="py-6">
                Join thousands of collectors looking for great projects together.
              </p>
            </div>
            <div className="px-4 md:px-4 lg:px-6 xl:px-9 mt-10 md:mt-10 min-w-1/3">
              <div className="border-b border-gray-400 my-6" style={{width: 60}}></div>
              <h3 className="style3 font-rox">A Radar</h3>
              <p className="py-6">
                Be the first to discover the top drops launching in the NFT space.
              </p>
            </div>
            <div className="px-4 md:px-4 lg:px-6 xl:px-9 mt-10 md:mt-20 min-w-1/3">
              <div className="border-b border-gray-400 my-6" style={{width: 60}}></div>
              <h3 className="style3 font-rox">A Launchpad</h3>
              <p className="py-6">
                Launch your NFT collection using our industry-leading tech.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white text-black pb-20" id='mint-ticket'>
        <MintTicket/>
      </div>
    </div>
  );
}
export default Home;
