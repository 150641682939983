import {useCallback, useEffect, useRef, useState} from 'react'
import {ICollection, InitialPagination, InitialSCData, IPaginationResult, IResultWithPagination, ISCData} from "../dnweb3/types";
import AppApi from "../dnweb3/appapi";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../types";
import {bnToDec, getImgUrl, mergeArr, noNullText, safeBoolBy01, safeJsonParse, safeNum} from "../dnweb3/helpers/utilities";
import {APP_MEDIA_BASE_URL} from "../config";
import useAuth from "./useAuth";
import _ from 'lodash';
import usePrevious from "./usePrevious";
import {useLocation} from 'react-router-dom';

export const bindCollectionData = (data: any): ICollection => {
  const row: ICollection = {
    project_id: data.project_id,
    name: data.name,
    name_uid: data.name_uid,
    tagline: data.tagline,
    description: noNullText(data.description),

    mint_price: safeNum(data.mint_price),
    mint_limit: safeNum(data.mint_limit),
    presale_minted_count: safeNum(data.presale_minted_count),
    sale_minted_count: safeNum(data.sale_minted_count),
    minted_count: safeNum(data.minted_count),
    total_count: safeNum(data.total_count),
    sc_ticket_subgraph_url: data.sc_ticket_subgraph_url,
    sc_subgraph_url: data.sc_subgraph_url,
    sc_address: data.sc_address,
    sc_version: safeNum(data.sc_version),

    logo_url: data.logo_url && `${APP_MEDIA_BASE_URL}${data.logo_url}`,
    banner_url: data.banner_url && `${APP_MEDIA_BASE_URL}${data.banner_url}`,
    card_url: data.card_url && `${APP_MEDIA_BASE_URL}${data.card_url}`,
    featured_url: data.featured_url ? `${APP_MEDIA_BASE_URL}${data.featured_url}` : `${APP_MEDIA_BASE_URL}${data.card_url}`,

    start_drop_date: data.start_drop_date,
    presale_date: data.presale_date,
    whitelistsale_date: data.whitelistsale_date,

    website: data.website,
    social_links: data.social_links ? JSON.parse(data.social_links) : {},

    sc_sale_active: safeBoolBy01(data.sc_sale_active),
    sc_presale_active: safeBoolBy01(data.sc_presale_active),
    sc_whitelist_active: safeBoolBy01(data.sc_whitelist_active),
    status: data.status,
    presale_whitelist: noNullText(data.presale_whitelist),
    sc: safeJsonParse(data.sc),

    is_live: safeBoolBy01(data.is_live),
    featured: safeBoolBy01(data.featured),
    is_mintable: safeBoolBy01(data.is_mintable),
    is_upcoming: safeBoolBy01(data.is_upcoming),
    is_homepage: safeBoolBy01(data.is_homepage),
    is_tbd: safeBoolBy01(data.is_tbd),
    is_curated:  (safeBoolBy01(data.featured) || safeBoolBy01(data.is_homepage)),
  };
  return row;
}

/**
 * We safely update subgraph data in the existing collection data.
 * @param srcData
 * @param targetData
 */
export const getMergedCollectionDataBySubGraphData = (srcData: ICollection | undefined, targetData: ISCData): ICollection | undefined => {
  if (srcData && (srcData.localTimestamp || 0) < (targetData.localTimestamp || 0)) {
    return {...srcData, ...targetData};
  }
  return srcData;
}

/**
 * We safely update collection data while keeping the real-time subgraph data.
 *
 * @param srcData
 * @param targetData
 */
export const getSafeUpdatedCollectionData = (srcData: ICollection | undefined, newData: ICollection | undefined, skipSubGraphData?: boolean): ICollection | undefined => {
  if (!srcData) return newData;
  if (!newData) return newData;
  const data = {...srcData, ...newData};
  if (skipSubGraphData) {
    const scDataKeys = Object.keys(InitialSCData);

    scDataKeys.forEach(key => {
      _.set(data, `[${key}]`, _.get(srcData, `[${key}]`));
      // data[`${key}`] = srcData[`${key}`];
    });
  }
  return data;
}


export enum EnumPostProcess {
  OVERRIDE = 1,
  REPLACE = 2,
}

const useCollections = (props: any, mode?: EnumPostProcess) => {
  const {sortBy, sortDir, page, search, perPage, filters} = props;

  const {
    global: {
      refreshTick
    }
  } = useSelector((state: IMapStateToProps) => state);
  const {isAdmin} = useAuth();

  // Search keywords
  const location: any = useLocation();
  const searchKeyword = new URLSearchParams(location.search).get("q");
  const prevSearchKeyword = usePrevious(searchKeyword);
  const isSearchKeywordChanged = prevSearchKeyword !== undefined && prevSearchKeyword != searchKeyword;

  // Items & Pagination
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Array<ICollection>>([]);
  const [pagination, setPagination] = useState<IPaginationResult>(InitialPagination);

  /**
   * Fetching items from backend API server.
   */
  const fetchItems = useCallback(async (refreshRequired?: boolean) => {
    try {
      setLoading(true);

      const apiParams = {
        ...{
          sortDir: sortDir || 'created_on',
          sortBy: sortBy || 'desc',
          search: search || '',
          page: page || InitialPagination.currentPage,
          perPage: perPage || InitialPagination.perPage,
        },
        ...filters,
        search: searchKeyword,
      };

      // If search keyword changed, re-set pagination.
      if (isSearchKeywordChanged) {
        apiParams.page = InitialPagination.currentPage;
      } else {
        if (refreshRequired) {
          apiParams.page = 1;
          apiParams.perPage = Math.max(items.length, apiParams.page * apiParams.perPage);
        }
      }

      const response: IResultWithPagination = await AppApi.getProjectsList(apiParams);
      const data = response?.data;
      const pagination = response?.pagination;

      // Preparing new result array.
      const newItems: Array<ICollection> = [];
      for (let ind in data) {
        newItems.push(bindCollectionData(data[ind]));
      }

      // Merge and set items
      if (mode == EnumPostProcess.OVERRIDE || isSearchKeywordChanged || refreshRequired) {
        setItems(newItems);
      } else {
        setItems((prevState) => {
          let mergedItems;
          mergedItems = mergeArr(prevState, newItems, 'project_id') as Array<ICollection>;
          /*mergedItems = [...mergedItems].filter(x => {
            if (filters && filters.featured !== undefined) {
              if ((filters.featured == 1) != x.featured) return false;
            }
            if (filters && filters.is_live !== undefined) {
              if ((filters.is_live == 1) != x.is_live) return false;
            }
            if (filters && filters.is_upcoming !== undefined) {
              console.log('upcoming filtering...',(filters.is_upcoming == 1), x.is_upcoming,  (filters.is_upcoming == 1) != x.is_upcoming);
              if ((filters.is_upcoming == 1) != x.is_upcoming) return false;
            }
            return true;
          });
          console.log(prevState, mergedItems);*/
          return mergedItems;
        });
      }
      if (!refreshRequired) {
        setPagination(pagination);
      }
    } catch (error) {
      console.error('Unable to fetch collections.', error)
    } finally {
      setLoading(false);
    }
  }, [sortBy, sortDir, page, search, perPage, filters, isAdmin, searchKeyword, isSearchKeywordChanged, items]);

  useEffect(() => {
    fetchItems();
  }, [sortBy, sortDir, page, search, perPage, filters, isAdmin, searchKeyword]);

  useEffect(() => {
    if (refreshTick && refreshTick > 0)
      fetchItems(true);
  }, [refreshTick]);

  return {
    items,
    pagination,
    hasMore: pagination.hasMore,
    loading,
    fetchItems
  }
}

export default useCollections;
