import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import styled from 'styled-components';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from "../Button";
import {IMapStateToProps} from "../../types";
import SLoadingWrap from "../SLoadingWrap";
import useBodyScrollbar from "../../hooks/useBodyScrollbar";
import ImgSuccess from "../../assets/images/icon/checkout-success.svg";

const SModalDiv = styled.div`  
`;

const SAccountModal = ({
                         isOpen,
                         onOpen,
                         onClose
                       }: any) => {

  const [copied, setCopied] = useState(false);

  const address = useSelector((state: IMapStateToProps) => state.authUser.address);
  const curNetworkId = useSelector((state: IMapStateToProps) => state.authUser.networkId);
  const connectType = useSelector((state: IMapStateToProps) => state.authUser.connectType);
  const killSession = useSelector((state: IMapStateToProps) => state.authUser.killSession);
  const web3 = useSelector((state: IMapStateToProps) => state.authUser.web3);

  const modalRef: any = useRef();

  useBodyScrollbar(isOpen);

  useEffect(() => {
    const body: any = document.querySelector('body');
    if (isOpen) {
      body.classList.add('modal-active');
      modalRef.current.classList.remove('pointer-events-none');
    } else {
      body.classList.remove('modal-active');
      modalRef.current.classList.add('pointer-events-none');
    }
  }, [isOpen]);


  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleLogout = async () => {
    // @ts-ignore
    killSession(web3, curNetworkId, address, connectType).then(res => {
      onClose();
    });
  }
  return (
    <>
      <SModalDiv
        className={`modal ${isOpen ? '' : 'opacity-0'} flex flex-col pointer-events-none normal-case fixed w-full h-full top-0 left-0 flex items-center shadow-lg justify-center z-50 text-black`}
        ref={modalRef}
      >
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50" onClick={onClose}></div>
        <div className="modal-container normal-case bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto">
          <SLoadingWrap
            className="modal-content py-12 text-left px-8 items-center justify-center"
            loading={false}
          >
            <h3 className="text-3xl font-bold font-exter-black text-center">Your wallet</h3>
            <div className="modal-content pt-4 text-left px-8 items-center justify-center font-exter-regular">
              <div className="my-4">
                <div className="my-4 w-full text-center break-words">{copied ? <div className=""><img className="inline-block" src={ImgSuccess} width={40} height={40} /> Copied</div> : address}</div>
                <CopyToClipboard onCopy={() => setCopied(true)} text={address}>
                  <Button
                    className="btn btn-dark py-4 px-5 border focus:outline-none mt-6 min-w-full text-center"
                    onClick={() => {
                    }}
                    disable={false}
                  >Copy address</Button>
                </CopyToClipboard>
                <button className="btn btn-dark py-4 px-5 border focus:outline-none mt-6 min-w-full text-center" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </SLoadingWrap>
        </div>
      </SModalDiv>

    </>
  );
}

export default SAccountModal;
