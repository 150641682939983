import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import global from './global/reducer';

const reducers = combineReducers({
  authUser,
  global
});

export default reducers;
