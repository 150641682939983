import axios from "axios";
import qs from 'qs';
import {sha256} from 'js-sha256';
import _ from "lodash";
import {setAuthToken} from "../helpers/AuthUtil";
import {APP_API} from "../../config";
import {ICollection, IResultWithPagination} from "../types";
import {deviceDetect} from 'react-device-detect';

interface IAxiosResponse {
  code: number;
  status: string;
  data?: any;
}

interface IApiResponse {
  code: number;
  status: string;
  message?: IResultWithPagination | any;
}

const defaultOptions = {
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json;charset=utf-8',
  }
};

export const getApiToken = (msg: string) => {
  return sha256(msg || 'DROPSPACE-APP');
}

const request = async (type: string, url: string, params: any): Promise<IAxiosResponse> => {
  const newOptions: any = {...defaultOptions};

  if (type == 'get') {
    return axios.get(`${url}${params ? '?' + qs.stringify(params) : ''}`);
  } else if (type == 'post') {
    return axios.post(url, params);
  } else if (type == 'put') {
    return axios.put(url, params);
  } else if (type == 'delete') {
    return axios.delete(url, params);
  } else{
    throw "Invalid axios request type!";
  }
}

const get = async (url: string, params?: any) => {
  return request('get', url, params);
}

const post = async (url: string, params?: any) => {
  return request('post', url, params);
}

/**
 * ------------------------------------------------------------------------------------------
 * User
 * ------------------------------------------------------------------------------------------
 */
const login = async (address: string, signature: string): Promise<any | null> => {
  return post(`/login`, {address, signature, client_detail: deviceDetect(undefined)}).then(response => {
    if (response.data.code == 200) {
      return response.data.message;
    }
    return null;
  }).catch(reason => {
    setAuthToken(address, null);
    return null;
  });
}

const getUserProfile = async (address: string): Promise<any> => {
  const response: IAxiosResponse = await get(`/users/profile/${address}`);
  return response.data.code == 200 ? response.data.message : null;
}

/**
 * ------------------------------------------------------------------------------------------
 * Address
 * ------------------------------------------------------------------------------------------
 */

const getResponseErrorMsg = (reason: any) => {
  let msg = '';
  if (reason.message) {
    msg = reason.message;
  } else {
    const resObj = _.get(reason, 'request.response');
    msg = _.get(resObj ? JSON.parse(resObj) : null, 'message', '');
  }
  return msg;
}

/**
 * ------------------------------------------------------------------------------------------
 * Project
 * ------------------------------------------------------------------------------------------
 */
const getProject = async (projectId: any, params?: any): Promise<any> => {
  let paramsStr = '';
  if (params) {
    paramsStr = '?' + qs.stringify(params);
  }
  const response: IAxiosResponse = await get(`/projects/${projectId}${paramsStr}`);
  return response.data.code == 200 ? response.data.message : {};
}

const getProjectByUid = async (uid: string, params?: any): Promise<ICollection> => {
  let paramsStr = '';
  if (params) {
    paramsStr = '?' + qs.stringify(params);
  }

  const response: IAxiosResponse = await get(`/projects/uid/${uid}${paramsStr}`);
  return response.data.code == 200 ? response.data.message : {};
}


const getProjectsList = async (params: any): Promise<IResultWithPagination> => {
  const response: IAxiosResponse = await get(`/projects`, params);
  return response.data.code == 200 ? response.data.message : {};
}

const updateProjectByField = async (project_id:number, params: any): Promise<IAxiosResponse> => {
  return request('put', `/projects/${project_id}`, params);
}




/**
 * ------------------------------------------------------------------------------------------
 * File
 * ------------------------------------------------------------------------------------------
 */

const getFileUrl = (file: any, isOrigin?: boolean) => {
  if (file) {
    return `${APP_API}/files/original-image/${file.file_id}${isOrigin ? '?thumb=1' : ''}`;
  }
}

const AppApi = {
  defaultOptions,
  request,
  get,
  post,
  getApiToken,
  getResponseErrorMsg,

  login,
  getUserProfile,

  getProject,
  getProjectByUid,
  getProjectsList,
  getFileUrl,
  updateProjectByField,
};

export default AppApi;
