import React, {useCallback, useContext, useEffect, useState} from "react";

interface INotFound {
  title?: any
}

export default function NotFound(props: INotFound) {
  const {title} = props;
  return (
    <div className="page white text-black min-h-screen">
      <div className="container">
        <h3 className="style1 my-25">{title ? title : 'Page not found.'}</h3>
      </div>
    </div>
  )
}
