export const MSG_SELECT_MAINNET = 'Please select Mainnet to proceed!';
export const MSG_SELECT_BINANCE = 'Please select Binance Smart Chain to proceed!';
export const MSG_SELECT_KOVAN_NET = 'Please select Kovan net to proceed!';
export const MSG_SELECT_RINKEBY_NET = 'Please select Rinkeby testnet to proceed!';
export const MSG_CONNECT_REQUIRED_MOBILE =
  '! In order to connect to MetaMask on a Smartphone, you must have the app installed and connect through the MetaMask app browser.';
export const MSG_CONNECT_REQUIRED_DESKTOP =
  '! In order to connect to MetaMask on a Desktop browser, you must have the plugin installed using a supported browser.';
export const MSG_CONNECT_WALLET = 'In order to farm our pools, in your wallet, make sure you are connected to the Ethereum Mainnet.';

export const MSG_AMOUNT_REQUIRED = '! Please enter an amount';
export const MSG_NO_CURRENCY = 'The currency required is not enough in your wallet';
export const MSG_AMOUNT_EXCEEDED = '! This amount exceeds your wallet';
