import React, {HTMLProps, Props} from 'react';
import {Link} from "react-router-dom";

export interface IButton extends HTMLProps<any> {
  size?: any
  disable?: any
  btnType?: string
  btnLinkUrl?: string
  arrowType?: string
  external?:boolean
  type?:'button' | 'submit'
}

const Button = (props: IButton) => {
  const {children, size = "normal", className, onClick, disable, btnType, btnLinkUrl, external, type} = props;

  const classNameStr = `
                btn
                ${size === "sm" ? 'btn-sm' : 'btn-normal'}
                ${disable ? 'btn-disabled' : ''}
                text-base 
                font-semibold 
                focus:outline-none
                ${className}
        `;

  return (
    btnType == 'link' ?
      (external ? <a
        href={btnLinkUrl || '#'}
        target={'_blank'}
        className={classNameStr}
        style={props.style || {}}
      >
        {children}
      </a> : <Link
        to={btnLinkUrl || '#'}
        onClick={onClick}
        className={classNameStr}
        style={props.style || {}}
      >
        {children}
      </Link>)
      :
      <button
        onClick={onClick}
        disabled={disable}
        className={classNameStr}
        style={props.style || {}}
        type={type || 'button'}
      >
        {children}
      </button>
  )
}

export default Button;
