import React from "react";

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev transform rotate-180"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="white"/>
      <path d="M12 27.5L11.5 27.5L11.5 28.5L12 28.5L12 27.5ZM12 28.5L44 28.5L44 27.5L12 27.5L12 28.5Z" fill="#060606"/>
      <path d="M31 15L44 28L31 41" stroke="#060606"/>
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#060606"/>
    </svg>
  </button>
);

export const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="white"/>
      <path d="M12 27.5L11.5 27.5L11.5 28.5L12 28.5L12 27.5ZM12 28.5L44 28.5L44 27.5L12 27.5L12 28.5Z" fill="#060606"/>
      <path d="M31 15L44 28L31 41" stroke="#060606"/>
      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#060606"/>
    </svg>
  </button>
);

export const Thumb = ({ selected, onClick, item }) => (
  <div
    className={`embla__slide embla__slide--thumb ${
      selected ? "is-selected" : ""
    }`}
  >
      <button
        onClick={onClick}
        className="embla__slide__inner embla__slide__inner--thumb"
        type="button"
      >
          <div className="border-b border-white" title={item.name}></div>
      </button>
  </div>
);

export const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
);

