import _ from "lodash";
import BigNumber from "bignumber.js";
import abiMaster from "../abi/Master.json";
import abiMintTicket from "../abi/MintTicket.json";

import {NetAddressMap} from "../constants";
import {APP_NETWORK_ID} from "../config";
import {Contract} from "web3-eth-contract";

import {bnDivdedByDecimals, bnToDec, bnZero, decToBn} from "../helpers/utilities";
import Web3 from "web3";
import {BaseContractController} from "./BaseContract";

export enum EnumSCType {
  Master = 'MASTER',
  Ticket = 'MintTicket',
}

export class ContractController extends BaseContractController{
  public masterSM;
  public networkId: number;
  public address: string | undefined;

  public constructor(web3: Web3) {
    super(web3);

    this.networkId = APP_NETWORK_ID;
    this.masterSM = this.master();
  }

  public master(): Contract {
    const tokenAddress = _.get(NetAddressMap, `[${this.networkId}][MASTER]`);
    if (tokenAddress) {
      // @ts-ignore
      return this.web3 ? new this.web3.eth.Contract(abiMaster, tokenAddress) : null;
    } else {
      throw new Error(`Unknown token for network ${this.networkId}`);
    }
  }

  public ticket(): Contract {
    const tokenAddress = _.get(NetAddressMap, `[${this.networkId}][MintTicket]`);
    if (tokenAddress) {
      // @ts-ignore
      return this.web3 ? new this.web3.eth.Contract(abiMintTicket, tokenAddress) : null;
    } else {
      throw new Error(`Unknown token for network ${this.networkId}`);
    }
  }

  public getContract(name?: EnumSCType): Contract {
    return name == EnumSCType.Ticket ? this.ticket() : this.masterSM;
  }

  /**
   * ========================================================================================
   * Biz logic methods
   * ========================================================================================
   */
  public async getUserNftIdList(address: string, typeId: number, totalCount: number): Promise<object> {
    let map = {};
    return map;
    if (typeId >= 0) {
      const ids = await this.masterSM.methods.getIDFromMap(address, typeId).call();
      // @ts-ignore
      map[typeId] = ids;
    } else {
      for (let i = 0; i < totalCount; i++) {
        // const ids = await this.masterSM.methods.getIDFromMap(address, NFT_LIST[i].typeId).call();
        // @ts-ignore
        // map[i] = ids;
        map[i] = [-1];
      }
    }
    return map;
  };

  public async getBaseTokeUri(): Promise<string> {
    if (!this.web3) return "";
    const res = await this.masterSM.methods.baseURI().call();
    return res;
  };

  /**
   *
   * @param count
   */
  public async getPrice(count: number, scType?: EnumSCType): Promise<BigNumber> {
    if (!this.web3) return bnZero();
    const res = await this.getContract(scType).methods.mintPrice().call();
    return new BigNumber(res).multipliedBy(count);
  };

  public async buyMultiple(address: string, qty: number, totalPrice: BigNumber, scType?: EnumSCType): Promise<any> {
    if (!this.web3) return null;

    const res = await this.getContract(scType).methods.buy(qty).send({
      from: address,
      value: totalPrice.toString(),
    });
    return res;
  };

  public async nameNFT(address: string, totalPrice: BigNumber, tokenId: number, name: string): Promise<any> {
    if (!this.web3) return null;

    const res = await this.masterSM.methods.nameNFT(tokenId, name).send({
      from: address,
      value: totalPrice.toString(),
    });
    return res;
  };
}

export function getContract(web3: Web3) {
  return new ContractController(web3);
}
