import {KOVAN_CHAIN_ID, MAINNET_CHAIN_ID, RINKEBY_CHAIN_ID} from "./index";

export const NetAddressMap = {
  [RINKEBY_CHAIN_ID]: {
    MASTER: '0x33364aff29c5f7c2b1d3e843d75665f683633d3b',
    MintTicket: '0x8f14dd232b01de4e5d6e2e0dfdabe19f1afebd68', // Rinkeby
  },
  [MAINNET_CHAIN_ID]: {
    MASTER: '0x7ba0A79eC30259E2792A43989EdD97c6E40Bb336',
    MintTicket: '0x7ba0A79eC30259E2792A43989EdD97c6E40Bb336',
  },
  [KOVAN_CHAIN_ID]: {
    MASTER: '0xd20201cB08f8dD3BA34a746d23354b99166CD2a7',     // 0219
    MintTicket: '0xaD1a65C62DC0CFA526f451193B85526810DCD8ec', // 0219
  },
};

