import "./ProjectOwnerPanel.scss";
import {EnumUserRole, ICollection} from "../../dnweb3/types";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Switch from "react-switch";
import {IMapStateToProps} from "../../types";
import {EnumSaleActiveStatus, getContractDS} from "../../dnweb3/controllers/dropspace";
import SLoadingWrap from "../SLoadingWrap";
import {APP_NETWORK_ID} from "../../dnweb3/config";
import {MAINNET_CHAIN_ID} from "../../dnweb3/constants";
import {bnToDec, ellipseAddress, getWhitelistArr, nf, nf_i, noNullText, safeNum} from "../../dnweb3/helpers/utilities";
import AppMsg from "../../dnweb3/helpers/AppMsg";
import Button from "../Button";
import BigNumber from "bignumber.js";
import AppApi from "../../dnweb3/appapi";
import {setLoading as setGLoading} from "../../redux/auth/actions";
import useSCOwner from "../../hooks/useSCOwner";
import useAuth from "../../hooks/useAuth";
import Web3Util from "../../dnweb3/helpers/Web3Util";

interface IProjectOwnerPanel {
  fetchProject?: () => void
  fetchGraphCustomData?: () => void
  updateProjectDataBySubGraph?: () => void
  project: ICollection
  setProject?: any
}

const InitialForm = {
  [EnumSaleActiveStatus.saleActive]: false,
  [EnumSaleActiveStatus.preSaleActive]: false,
  [EnumSaleActiveStatus.whitelistSaleActive]: false,
  [EnumSaleActiveStatus.whitelistBuyOnce]: false,
  ticketAddress: '',
};

const ProjectOwnerPanel = ({
                             project,
                             setProject,
                             fetchProject,
                             updateProjectDataBySubGraph
                           }: IProjectOwnerPanel) => {
  const dispatch = useDispatch();

  const {
    authUser: {
      web3,
      address,
      loggedIn,
      networkId,
      profileLoaded,
      profile: {role}
    }
  } = useSelector((state: IMapStateToProps) => state);

  const {isAuthenticated, requestAuth, isAdmin} = useAuth();
  const {isOwner} = useSCOwner(project?.sc_address);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(InitialForm);
  const [reserve, setReserve] = useState<number>(0);
  const [curBalance, setCurBalance] = useState<BigNumber>(new BigNumber(0));
  const whitelistsRef:any = useRef();
  const [whitelistsCount, setWhitelistsCount] = useState<number>(0);

  useEffect(() => {
    if (project.presale_whitelist) {
      const tmp = getWhitelistArr(project.presale_whitelist || '');
      setWhitelistsCount(tmp.length );
    } else setWhitelistsCount(0);;
  }, [project.presale_whitelist]);

  useEffect(() => {
    if ((role != EnumUserRole.ADMIN) && profileLoaded && loggedIn && isOwner && (project.sc_version || 0) >= 2.1 && !isAuthenticated) {
      setLoading(true);
      const tmpFunc = async () => {
        setLoading(false);
        const token = await requestAuth();
        if (!token) {
          AppMsg.error("You need to sign with one-time nonce to perform this action!");
          return;
        }
      }
      tmpFunc();
    }
  }, [project.sc_version, isOwner, loggedIn, isAuthenticated, profileLoaded, isAdmin]);

  const updateStatuses = useCallback(async () => {
    if (web3 && loggedIn && address && project.sc_address) {
      setLoading(true);
      const controller = getContractDS(web3, project.sc_address, project.sc_version);
      controller
        .getSaleActiveStatuses()
        .then(async (res) => {
          setForm({...res, ticketAddress: project.ticketAddress});
          updateProjectDataBySubGraph && (await updateProjectDataBySubGraph());
        })
        .catch((reason) => {
          const msg = AppApi.getResponseErrorMsg(reason);
          AppMsg.error(msg ? msg : 'Failed to get sale statuses!');
        })
        .finally(() => {
          setLoading(false);
        });

      controller.getUserBalance(project.sc_address)
        .then((res) => {
          setCurBalance(res);
          setLoading(false);
        })
        .catch((reason) => {
          const msg = AppApi.getResponseErrorMsg(reason);
          AppMsg.error(msg ? msg : 'Failed to get user balance!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loggedIn, address, web3, project.sc_address]);

  useEffect(() => {
    updateStatuses().then();
  }, [updateStatuses]);

  const onChange = async (value: any, name: EnumSaleActiveStatus) => {
    if (web3 && loggedIn && address && project.sc_address) {
      setLoading(true);
      const controller = getContractDS(web3, project.sc_address, project.sc_version);
      controller
        .toggleSaleActiveStatus(address, name)
        .then(async (res: any) => {
          if (res.status == true) {
            await updateStatuses();
            updateProjectDataBySubGraph && await updateProjectDataBySubGraph();
          } else {
            AppMsg.error("Failed to update status. Please try again.");
          }
        }).catch((reason) => {
        // AppMsg.error(reason.toString());
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const submitUpdatedValueOnBackend = async (newData: any, cb?:any) => {
    setLoading(true);
    AppApi.updateProjectByField(project.project_id, newData)
      .then(function (response: any) {
        if ((response.status = 200)) {
          AppMsg.info('Updated project information.');
          if (cb) {
            cb();
          }
        }
      })
      .catch((reason) => {
        const msg = AppApi.getResponseErrorMsg(reason);
        AppMsg.error(msg ? msg : 'Error occurred! Please reload a page and try again!');
      })
      .finally(() => {
        setLoading(false);
        dispatch(setGLoading(false));
      });
  };

  const onUpdateField = async (field: string) => {
    if (web3 && loggedIn && address && project.sc_address && field) {
      const controller = getContractDS(web3, project.sc_address, project.sc_version);
      if (field == 'withdraw') {
        setLoading(true);
        controller
          .withdraw(address)
          .then((res) => {
            updateStatuses();
            AppMsg.success('Withdrawn successfully!');
          })
          .catch((reason) => {
            const msg = AppApi.getResponseErrorMsg(reason);
            AppMsg.error(msg ? msg : 'Failed to withdraw!');
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (field == 'reserve' && reserve) {
        setLoading(true);
        controller
          .setReserve(address, reserve)
          .then(async (res) => {
            AppMsg.success('Reserved successfully!');
            setTimeout(async () => {
              updateProjectDataBySubGraph && (await updateProjectDataBySubGraph());
            }, 1000);
          })
          .catch((reason) => {
            const msg = AppApi.getResponseErrorMsg(reason);
            AppMsg.error(msg ? msg : 'Failed to reserve!');
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (field == 'presale_whitelist') {
        if ((project.sc_version || 0) < 2.1) {
          AppMsg.error('This is not supported in the current smart contract.');
          return;
        }

        if (!isAuthenticated) {
          setLoading(true);
          const token = await requestAuth();
          setLoading(false);
          if (!token) {
            AppMsg.error("You need to sign with one-time nonce to perform this action!");
            return;
          }
        }

        const whitelists = whitelistsRef.current.value;
        if (!whitelists || whitelists === '') return;
        setLoading(true);

        const presaleWhitelistArr = getWhitelistArr(whitelists);
        const whitelistsJoined = presaleWhitelistArr.join(',');
        setWhitelistsCount(presaleWhitelistArr.length);

        controller
          .setWhitelistRoot(address, presaleWhitelistArr)
          .then(async (res: any) => {
            if (res.status === true) {
              submitUpdatedValueOnBackend({presale_whitelist: whitelistsJoined}, () => {
                // (location as any).reload();
                setProject((prev: any) => ({...prev, presale_whitelist: whitelistsJoined}));
              });
              // BUG: Freeze on remote servers. But it works on local
              // setProject((prev: any) => ({...prev, presale_whitelist: whitelistsJoined}));
              // setWhitelists(whitelistsJoined);
            }
          })
          .catch((reason: any) => {
            const msg = AppApi.getResponseErrorMsg(reason);
            AppMsg.error(msg ? msg : 'Failed to set Whitelist Sale Time! Please check the value and try again.');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <div className="owner-panel flex flex-wrap xl:flex-nowrap items-start w-full mb-8 font-suisse">
      <SLoadingWrap loading={loading} maskCls={'p'}
                    className={`w-full max-w-md shadow-buy border border-black2 p-8 md:p-10 ${loading ? 'pointer-events-none' : ''}`}>
        <h3 className="style1 mb-6">Smart contract</h3>
        <div className={`form`}>
          <div className={`block mb-8`}>
            <div className="form-row flex items-center justify-between space-x-4 text-black1">
              <label>Smart contract: </label>
              {project ? <a
                  href={Web3Util.getEtherscanUrl(APP_NETWORK_ID, project.sc_address || '')}
                  target={'_blank'}
                >{ellipseAddress(project.sc_address, 6, 6)}
                </a>
                : "No smart contract address!"}
            </div>
            <div className="form-row flex items-center justify-between text-black1">
              {project ? <>
                  <label>Ticket Addr: </label>
                  <a
                    href={Web3Util.getEtherscanUrl(APP_NETWORK_ID, project.ticketAddress || '')}
                    target={'_blank'}
                  >{ellipseAddress(project.ticketAddress, 6, 6)}
                  </a>
                </>
                : "No Ticket address!"}
            </div>
          </div>
          <div className={`block mb-8`}>
            <div className="form-row flex items-center mt-4 justify-between">
              <label className="form-label mr-6  min-w-max" htmlFor={EnumSaleActiveStatus.preSaleActive}>Pre-sale Active</label>
              <Switch
                checked={form.preSaleActive}
                onChange={(e) => onChange(e, EnumSaleActiveStatus.preSaleActive)}
                onColor="#c6ccef"
                onHandleColor="#4D5FD5"
                offColor="#ccc"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px #4d5fd5)"
                activeBoxShadow="0px 0px 1px 10px #c3bebe22"
                height={22}
                width={40}
                className="react-switch"
                id={EnumSaleActiveStatus.preSaleActive}
              />
            </div>
            <div className="form-row flex items-center justify-between">
              <label className="form-label mr-6 min-w-max" htmlFor={EnumSaleActiveStatus.whitelistSaleActive}>Whitelist Sale Active</label>
              <Switch
                checked={form.whitelistSaleActive}
                onChange={(e) => onChange(e, EnumSaleActiveStatus.whitelistSaleActive)}
                onColor="#c6ccef"
                onHandleColor="#4D5FD5"
                offColor="#ccc"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px #4d5fd5)"
                activeBoxShadow="0px 0px 1px 10px #c3bebe22"
                height={22}
                width={40}
                className="react-switch"
                id={EnumSaleActiveStatus.whitelistSaleActive}
              />
            </div>
            <div className="form-row flex items-center justify-between">
              <label className="form-label mr-6  min-w-max" htmlFor={EnumSaleActiveStatus.saleActive}>Sale Active</label>
              <Switch
                checked={form.saleActive}
                onChange={(e) => onChange(e, EnumSaleActiveStatus.saleActive)}
                onColor="#c6ccef"
                onHandleColor="#4D5FD5"
                offColor="#ccc"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px #4d5fd5)"
                activeBoxShadow="0px 0px 1px 10px #c3bebe22"
                height={22}
                width={40}
                className="react-switch"
                id={EnumSaleActiveStatus.saleActive}
              />
            </div>
            {(safeNum(project.sc_version)) > 1 && <div className="form-row flex items-center justify-between mt-8">
              <label className="form-label mr-6  min-w-max" htmlFor={EnumSaleActiveStatus.whitelistBuyOnce}>Whitelist once</label>
              <Switch
                checked={form.whitelistBuyOnce}
                onChange={(e) => onChange(e, EnumSaleActiveStatus.whitelistBuyOnce)}
                onColor="#c6ccef"
                onHandleColor="#4D5FD5"
                offColor="#ccc"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px #4d5fd5)"
                activeBoxShadow="0px 0px 1px 10px #c3bebe22"
                height={22}
                width={40}
                className="react-switch"
                id={EnumSaleActiveStatus.whitelistBuyOnce}
              />
            </div>}
            <div className="form-row form-row-reserve flex items-center justify-between">
              <label className="form-label mr-2">Reserve (Current: {nf_i(project.reserved)})</label>
              <input
                className={`mr-1`}
                value={reserve < 1 ? '' : reserve}
                onChange={(e: any) => {
                  const value = Math.floor(safeNum(e.target.value));
                  setReserve(value);
                }}
              />
              <Button
                className="btn-white black"
                onClick={(e: any) => {
                  onUpdateField('reserve');
                }}
              >
                Add
              </Button>
            </div>
            {(project.sc_version || 0) >= 2.1 && <div className="form-row flex flex-wrap items-center justify-between">
              <label className="form-label mr-2">Whitelists</label>
              <textarea className="my-4" defaultValue={noNullText(project.presale_whitelist || '')} rows={5} ref={whitelistsRef}/>
              <div className="help-block">{whitelistsCount > 0 ? `${whitelistsCount} whitelisted` : `no whitelists exist.`}</div>
              <Button
                className="btn-white black"
                onClick={(e: any) => {
                  onUpdateField('presale_whitelist');
                }}
              >
                Update
              </Button>
            </div>}
            <div className="form-row flex items-center justify-between">
              <Button
                className="btn-white black btn-withdraw mr-2"
                disabled={!!project.withdrawalWallet}
                onClick={(e: any) => {
                  onUpdateField('withdraw');
                }}
              >
                {`Withdraw - ${nf(bnToDec(curBalance) || 0.00, 3)}ETH`}
              </Button>
              {project && project.withdrawalWallet ? <a
                  href={`https://${APP_NETWORK_ID == MAINNET_CHAIN_ID ? '' : 'rinkeby.'}etherscan.io/address/${project.withdrawalWallet}`}
                  target={'_blank'}
                >{ellipseAddress(project.withdrawalWallet, 6, 6)}
                </a>
                : "No withdrawal address!"}
            </div>
          </div>
        </div>
      </SLoadingWrap>
    </div>
  );
}

export default ProjectOwnerPanel;

