import {INCREMENT_FAST_COUNTER, INCREMENT_REFRESH_TICK, INCREMENT_SLOW_COUNTER, SET_SEARCH_KEYWORD, SET_TICKET_DATA} from "./actions";
import {InitialTicketData, ITicketData} from "../../dnweb3/types/ticketDataTypes";

export interface IGlobalState {
  slowCounter?: number
  fastCounter?: number
  searchKeyword?: string
  refreshTick?: number    // Used to refresh collections

  ticketDataList: Array<ITicketData>,
}

export const INIT_GLOBAL_STATE: IGlobalState = {
  searchKeyword: '',
  slowCounter: 0,
  fastCounter: 0,
  refreshTick: 0,
  ticketDataList: [InitialTicketData],
}

const globalReducer = (state: IGlobalState = INIT_GLOBAL_STATE, action: any) => {
  switch (action.type) {
    case SET_TICKET_DATA:
      return {...state, ticketDataList: action.payload};
    case SET_SEARCH_KEYWORD:
      return {...state, searchKeyword: action.payload};
      break;
    case INCREMENT_SLOW_COUNTER:
      return {...state, slowCounter: (state.slowCounter || 0) + 1};
      break;
    case INCREMENT_FAST_COUNTER:
      return {...state, fastCounter: (state.fastCounter || 0) + 1};
      break;
    case INCREMENT_REFRESH_TICK:
      return {...state, refreshTick: (state.refreshTick || 0) + 1};
      break;
    default:
      return {...state};
  }
}

export default globalReducer;
