import {toast, ToastOptions} from 'react-toastify';

class AppMsg {
  static options: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress:undefined,
    theme: "colored",
    className: 'app-toast',
  };

  static success(msg: any, options?: any) {
    toast.success(msg, {...this.options, ...{style: {background: '#000000'}},  ...options});
  }

  static info(msg: any, options?: any) {
    toast.info(msg, {...this.options, ...{style: {background: '#000000'}}, ...options});
  }

  static error(msg: any, options?: any) {
    toast.error(msg, {...this.options, ...{},  ...options});
  }
}

export default AppMsg;
