import {gql} from 'graphql-request';

const LIMIT = 1000;
const LIMIT_HOLDERS = 1000;

/**
 * Versions:
 * 1 or undefined: original SC Subgraph
 * 2: Blacklist Added in SC.
 *
 * @param version
 * @constructor
 */

const GetTicketDataList = (version?: number) => {
  let extra = '';
  /*if (version && version > 1) {
    extra = 'whitelistBuyOnce';
  }*/
  return gql`
    {
      dropspaceSales(first: ${LIMIT}, skip: $skip) {
        id
        mintLimit
        mintPrice
        
        preSaleActive
        saleActive
        whitelistSaleActive
        
        supplyLimit        
        totalSupply
        preSale
        
        baseURI
        reserved    
        ${extra}
      } 
    }
  `;
}

const GetCustomDataList = (version?: number) => {
  let extra = '';
  if (version && version > 1) {
    extra = 'whitelistBuyOnce';
  }
  return gql`
    {
      dropspaceSales(first: ${LIMIT}, skip: $skip) {
        id
        mintLimit
        mintPrice
        
        preSaleActive
        saleActive
        whitelistSaleActive
        
        supplyLimit        
        totalSupply
        preSale
        
        baseURI
        reserved        
        ticketAddress  
        ${extra}
      }      
    }
  `;
}

const GetCustomDataListWithTickets = (version?: number) => {
  let extra = '';
  if (version && version > 1) {
    extra = 'whitelistBuyOnce';
  }
  return gql`
  query GetCustomDataListWithTickets($first: Int, $skip: Int, $owner: String) {    
      dropspaceSales(first: 1, skip: 0) {
        id
        mintLimit
        mintPrice
        
        preSaleActive
        saleActive
        whitelistSaleActive
        
        supplyLimit
        
        totalSupply
        preSale
        
        baseURI
        reserved  
        ticketAddress
        withdrawalWallet
        ${extra}
      }
      
      tickets(first: ${LIMIT}, skip: $skip, where: {owner: $owner}) {
        id
        used
        owner
      }   
  }
  `;
}

const GetUsedTickets = gql`
    {
      tickets(first: ${LIMIT}, skip: $skip, where: $where) {
        id
        used
        owner
      } 
    }
  `;

const GetTotalDropSpacesOwned = (address: string) => {
  return gql`
    {
      totalDropspacesOwned(id:"${address}") {
      count
    }
  `;
}


const AppGql = {
  LIMIT,
  LIMIT_HOLDERS,

  GetTicketDataList,
  GetCustomDataList,
  GetCustomDataListWithTickets,
  GetUsedTickets,
  GetTotalDropSpacesOwned
};

export default AppGql;
