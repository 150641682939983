import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import globalSagas from './global/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    globalSagas(),
  ]);
}
