import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAppControllers} from "../controllers";
import {setError, setInitData, setStateData} from "../../redux/auth/actions";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import {IMapStateToProps} from "../../types";

const useUserBalanceUpdater = (address: string) => {
  const dispatch = useDispatch();
  const web3:Web3 = useSelector((state:IMapStateToProps) => state.authUser.web3);
  const [loading, setLoading] = useState(false);

  const updater = useCallback(async () => {
    setLoading(true);
    try {
      if (address) {
        const balance:BigNumber = await getAppControllers(web3).contract.getUserBalance(address);
        dispatch(setStateData({balance: balance}));
      }
    } catch (e) {
      setError(e);
      dispatch(setInitData());
    }
    setLoading(false);
  }, [dispatch, address, web3]);

  return {loading, updater}
}

export default useUserBalanceUpdater;
