import {useEffect, useRef, useState} from 'react'

export const getWidth = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export default function useWindowWidth(ref?: any, resizeCallback?: any) {
  const [vw, setVw] = useState(getWidth());
  const [refW, setRefW] = useState(0);

  const updateLayout = () => {
    setVw(getWidth());
    if (ref)
      setRefW((ref.current as HTMLElement).clientWidth);
    if (resizeCallback) resizeCallback(vw);
  };

  useEffect(() => {
    window.addEventListener('resize', updateLayout);
    return () => {
      return window.removeEventListener('resize', updateLayout);
    };

  }, [updateLayout, ref, resizeCallback]);

  return {
    vw,
    refW
  };
}
