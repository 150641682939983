import { CT_COINBASE, CT_METAMASK, CT_WALLET_CONNECT } from './connectType';

export const AppWallets = [
  {
    id: CT_METAMASK,
    name: 'Metamask',
  },
  {
    id: CT_COINBASE,
    name: 'Coinbase',
  },
  /*{
    id: CT_WALLET_CONNECT,
    name: 'WalletConnect',
  },*/
];
