import React, {useState} from 'react';
import useCollections, {EnumPostProcess} from "../../hooks/useCollections";
import {IPaginationParam} from "../../dnweb3/types";
import CarouselSingle from "../Carousel/CarouselSingle";
import {useDispatch} from "react-redux";
import SLoadingWrap from "../SLoadingWrap";

const FeaturedProjectLabel = () => <div className="md:absolute lg:relative xl:absolute hidden md:flex items-center h-full md:transform md:-rotate-90 lg:transform-none md:left-0 md:top-0 md:ml-15 md:-mt-60 lg:ml-0 lg:mt-0 xl:mt-20 3xl:mt-0 xl:-ml-72 3xl:-ml-96">
  Featured projects <span className="tracking-tighter border-b border-white leading-4 ml-2 px-4"> &nbsp;</span>
</div>;

const FeaturedCollections = () => {
  const dispatch = useDispatch();

  const [fetchParams, setFetchParams] = useState<IPaginationParam>({
    sortBy: 'start_drop_date',
    sortDir: 'asc',
    page: 1,
    perPage: 5,
    filters: {featured: 1, is_listing: 1},
  });
  const {loading, items} = useCollections(fetchParams, EnumPostProcess.OVERRIDE);

  return (
    <SLoadingWrap loading={loading} maskCls={'p'} className="features-wrap carousel-single flex relative lg:items-center xl:items-start lg:justify-between xl:justify-items-auto md:mt-20 lg:mt-0">
      {items.length > 0 && <>
        <FeaturedProjectLabel/>
        <CarouselSingle slides={items} sliderLeftCls={'md:mt-60 lg:mt-50 xl:mt-0'} autoplay={true} delayLength={5000}/>
      </>}
    </SLoadingWrap>
  );
}

export default FeaturedCollections;
