import React, {useState, useEffect, useCallback, useLayoutEffect} from 'react';
import {BrowserRouter, Switch, Route, useLocation, Redirect} from 'react-router-dom';
import {connect, useDispatch, useSelector} from "react-redux";
import Web3 from "web3";
import _ from "lodash";
import {ToastContainer} from 'react-toastify';

import Web3Util from "./dnweb3/helpers/Web3Util";
import {IStateType} from "./redux/auth/reducer";
import ConnectToInjected from "./dnweb3/connectors/injected";
import ConnectToWalletConnect from "./dnweb3/connectors/walletconnect";
import {setLocalData} from "./dnweb3/helpers/LsUtil";

import {
  setAddress,
  setConnectType,
  setError,
  setInitData, setLoading,
  setLoggedIn,
  setNetworkId,
  setStateData,
} from "./redux/auth/actions";


import {doLogin, doLogout, getLastLogin, isLoggedIn, setAuthToken} from "./dnweb3/helpers/AuthUtil";
import {
  AppWallets,
  BSC_CHAIN_ID,
  CT_COINBASE,
  CT_METAMASK,
  CT_WALLET_CONNECT,
  MAINNET_CHAIN_ID,
  MSG_SELECT_BINANCE, MSG_SELECT_MAINNET, MSG_SELECT_RINKEBY_NET,
  RINKEBY_CHAIN_ID
} from "./dnweb3/constants";
import {APP_NETWORK_ID} from "./dnweb3/config";

import './App.scss';
import './assets/css/form.scss';
import 'react-toastify/dist/ReactToastify.css';


import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer/index';
import NavbarMobile from './components/Navbar/NavbarMobile';
import {IAppDispatchProps, IMapStateToProps} from "./types";
import LoadingData from "./components/LoadingData";


import ReactGA from 'react-ga';
import {scrollToElement} from "./dnweb3/helpers/utilities";
import Project from "./pages/Project/Project";
import useAppTheme from "./hooks/useAppTheme";
import Projects from "./pages/Projects/Projects";
import useWindowWidth from "./hooks/useWindowWidth";
import CreateOrUpdateProject from "./pages/CreateOrUpdateProject/CreateOrUpdateProject";
import NotFound from "./components/NotFound";
import {useAxios} from "./hooks/useAxios";
import useScrollToTop from "./hooks/useScrollToTop";
import AppMsg from "./dnweb3/helpers/AppMsg";
import AboutProject from "./pages/AboutProject";
import ConnectToCoinbase from "./dnweb3/connectors/coinbase";

ReactGA.initialize('UA-207535026-1'); // Set on 09-17
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const curNetworkId = useSelector((state: IMapStateToProps) => state.authUser.networkId);
  const connectType = useSelector((state: IMapStateToProps) => state.authUser.connectType);
  const address = useSelector((state: IMapStateToProps) => state.authUser.address);
  const [domLoaded, setDomLoaded] = useState(false);

  const [theme] = useAppTheme();
  const {vw} = useWindowWidth();
  useAxios();
  useScrollToTop();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const checkLogin = useCallback(async () => {
    // Login status checking
    if (!isLoggedIn(curNetworkId, address, connectType)) {
      dispatch(
        setStateData({
          loggedIn: false,
        }),
      );
    }
  }, [dispatch, curNetworkId, address, connectType]);

  const subscribeProvider = useCallback(
    async (web3: Web3, provider: any) => {
      if (!provider.on) {
        return;
      }
      const accountsChanged = async (web3Param: Web3, accounts: string[]) => {
        console.log('accounts changed', accounts[0]);
        await dispatch(setStateData({ address: accounts[0] }));
        await checkLogin();
      };

      const chainChanged = async (web3Param: Web3, chainId: any) => {
        const networkId = await web3Param.eth.net.getId();
        await dispatch(setStateData({ chainId: parseInt(chainId), networkId }));
        await checkLogin();
      };

      const networkChanged = async (web3Param: Web3, networkId: any) => {
        const chainId = await (web3Param.eth as any).chainId();
        await dispatch(setStateData({ chainId, networkId: parseInt(networkId) }));
        await checkLogin();
      };

      provider.on('disconnect', () => {});
      provider.on('accountsChanged', async (accounts: string[]) => {
        await accountsChanged(web3, accounts);
      });
      provider.on('chainChanged', async (chainId: number) => {
        await chainChanged(web3, chainId);
      });

      /*Deprecated: provider.on('networkChanged', async (networkId: number) => {
        await networkChanged(web3, networkId);
      });*/
    },
    [checkLogin, dispatch],
  );

  const resetApp = useCallback(
    async (web3: Web3) => {
      console.log(web3, curNetworkId, address, connectType);
      if (!curNetworkId) return;
      doLogout(curNetworkId, address, connectType);

      if (web3 && web3.currentProvider && (web3.currentProvider as any).close) {
        await (web3.currentProvider as any).close();
      }
      // clearCachedProvider;
      setLocalData(CT_WALLET_CONNECT, null);

      dispatch(setInitData());
      dispatch(
        setStateData({
          loggedIn: false,
          connected: false,
          address: '',
          chainId: null,
          networkId: null,
          web3: null,
          provider: null,
        }),
      );
    },
    [dispatch, curNetworkId, address, connectType],
  );

  const onConnect = useCallback(
    async (connectTypeParam = '', isCached: boolean, cb?: any) => {
      // console.log('\nOn Connect', connectTypeParam, isCached);

    let provider = null;
    let web3 = null;
    let connected = false;
    let loggedIn = false;
    let networkId = null;
    let chainId = null;
    let addressLocal = null;
    let accounts = null;
    dispatch(setLoading(true));

    try {
      if (connectTypeParam === CT_WALLET_CONNECT) {
        provider = await ConnectToWalletConnect({ chainId: APP_NETWORK_ID });
      } else if (connectTypeParam === CT_COINBASE) {
        // provider = await ConnectToInjected(connectTypeParam);
        provider = await ConnectToCoinbase(connectTypeParam);
      } else {
        provider = await ConnectToInjected(connectTypeParam);
      }

      // console.log(' ===> provider gotten...', connectTypeParam, provider);
      if (!provider) {
        throw new Error('Please login to your wallet!');
      }

      web3 = Web3Util.initWeb3(provider);
      await subscribeProvider(web3, provider);

      networkId = await web3.eth.net.getId();
      accounts = await web3.eth.getAccounts();
      addressLocal = accounts[0].toLowerCase();
      chainId = await web3.eth.getChainId();

    } catch (e: any) {
      console.error('Error => ', e, e.code);
      switch (connectTypeParam) {
        case CT_WALLET_CONNECT:
          break;
        case CT_COINBASE:
          break;
        default:
          if (e?.code == -32002 || e?.code == 4001) {
            AppMsg.error('Please login to your wallet.');
          } else {
            AppMsg.error(e.message);
          }
          break;
      }
    } finally {
      dispatch(setLoading(false));
    }

    if (networkId && networkId != APP_NETWORK_ID) {
      if (!isCached) {
        if (APP_NETWORK_ID == BSC_CHAIN_ID) AppMsg.info(MSG_SELECT_BINANCE);
        else if (APP_NETWORK_ID == RINKEBY_CHAIN_ID) AppMsg.info(MSG_SELECT_RINKEBY_NET);
        else if (APP_NETWORK_ID == MAINNET_CHAIN_ID) AppMsg.info(MSG_SELECT_MAINNET);
      }
    }
    // According to cache, we set the login status.
    if (networkId && addressLocal && connectTypeParam) {
      if (networkId == APP_NETWORK_ID) {
        if (isCached) {
          loggedIn = isLoggedIn(networkId, addressLocal, connectTypeParam);
        } else {
          loggedIn = true;
          doLogin(networkId, addressLocal, connectTypeParam);
        }
      }
    }

    // Set state data.
    dispatch(setStateData({
      loggedIn,
      connected,
      networkId,
      address: addressLocal,
      chainId,
      web3,
      provider,
      connectType: connectTypeParam,
      loading: false,
      triedConnect: true,
    }));

    if (cb && loggedIn) {
      cb();
    }
    },
    [dispatch, subscribeProvider],
  );

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useLayoutEffect(() => {
    if (location.hash) {
      const ele = (document.getElementById(location.hash.substr(1)) as HTMLElement);
      scrollToElement(ele);
    }
  }, [location]);

  useEffect(() => {
    // init
    // event binding...
    dispatch(
      setStateData({
        onConnect: onConnect,
        killSession: resetApp,
      }),
    );
  }, [dispatch, resetApp, onConnect]);

  useEffect(() => {
    if (onConnect && domLoaded) {
      const lastLogin = getLastLogin(APP_NETWORK_ID);
      if (lastLogin && _.isObject(lastLogin)) {
        for (const connectTypeLocal in lastLogin) {
          // console.log('last login type ', connectTypeLocal);
          if (_.find(AppWallets, { id: connectTypeLocal }) && _.get(lastLogin, connectTypeLocal)) {
            const addrs = _.get(lastLogin, connectTypeLocal);
            let exists = false;
            for (const k in addrs) {
              if (_.get(addrs, k)) exists = true;
            }
            if (exists) {
              (async () => {
                await onConnect(connectTypeLocal, true, null);
              })();
            }
            break;
          }
        }
      }
      setDomLoaded(false);
    }
  }, [onConnect, domLoaded]);

  useEffect(() => {
    if (vw >= 1024 && isOpen) {
      setIsOpen(false);
    }
  }, [vw, isOpen]);

  return (
    <>
      <div className={`page ${theme}`}>
        <LoadingData/>
        <NavbarMobile isOpen={isOpen} toggle={toggle}/>
        <Navbar isOpen={isOpen} toggle={toggle}/>
        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>
          <Route path='/collections'>
            <Projects/>
          </Route>
          <Route path='/collection/:name_uid'>
            <Project/>
          </Route>
          <Route path='/create-collection'>
            <CreateOrUpdateProject />
          </Route>
          <Route path='/edit-collection/:project_id'>
            <CreateOrUpdateProject />
          </Route>
          <Route path='/about'>
            <AboutProject />
          </Route>
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>

        <Footer/>
      </div>
      <ToastContainer/>
    </>
  );
}


const mapStateToProps = (state: IMapStateToProps): IStateType => {
  return {...state.authUser};
}

const mapDispatchToProps = (dispatch: any): IAppDispatchProps => {
  return {
    setAddressRequest: (address: string) => dispatch(setAddress(address)),
    setNetworkIdRequest: (networkId: number) => dispatch(setNetworkId(networkId)),
    setConnectTypeRequest: (connectType: string) => dispatch(setConnectType(connectType)),
    setErrorRequest: (error: any) => dispatch(setError(error)),
    setInitData: () => dispatch(setInitData()),
    setLoggedIn: (status: boolean) => dispatch(setLoggedIn(status)),
    setStateData: (data: any) => dispatch(setStateData(data)),
    setLoading: (data) => dispatch(setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);



