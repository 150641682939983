import {useCallback, useEffect, useState} from "react";

import {request} from 'graphql-request'
import {useDispatch} from "react-redux";
import {setError, setStateData} from "../redux/auth/actions";
import {setTicketData} from "../redux/global/actions";

import AppGql from "../dnweb3/appollo/AppGql";
import BigNumber from "bignumber.js";

const useGraphTicketDataUpdater = (version?: number) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const updater = useCallback(async () => {
    let error = false;
    let ticketDataList: Array<any> = [];
    try {
      setLoading(true);
      let i = 0;
      while (true && i < 10) {
        const endpoint = process.env.REACT_APP_API_SUBGRAPH_TICKET || '';
        const res = await request<any>(endpoint, AppGql.GetTicketDataList(version), {
          first: AppGql.LIMIT,
          skip: AppGql.LIMIT * i
        });

        if (res.error) {
          error = true;
          break;
        }

        if (res && res.dropspaceSales) {
          ticketDataList = [...ticketDataList, ...res.dropspaceSales];
          if (res.dropspaceSales.length < 1 || res.dropspaceSales.length < AppGql.LIMIT) {
            break;
          }
        } else {
          error = true;
          break;
        }
        i++;
      }

      if (error) {
        console.error("Fetching error to get minting data.");
        dispatch(setError("Fetching error to get minting data."));
      } else {
        if (ticketDataList.length > 0) {
          for (let idx in ticketDataList) {
            ticketDataList[idx].price = new BigNumber(ticketDataList[idx].mintPrice);
            ticketDataList[idx].nftSold = parseInt(ticketDataList[idx].totalSupply) || 0;
            ticketDataList[idx].nftTotal = parseInt(ticketDataList[idx].supplyLimit) || 0;
            ticketDataList[idx].mintLimit = parseInt(ticketDataList[idx].mintLimit) || 0;
          }
          dispatch(setStateData({nftPrices: {normal_price: new BigNumber(ticketDataList[0].mintPrice || 0)}}));
        }
        dispatch(setTicketData(ticketDataList));
      }
    } catch (e) {
      console.error(e);
      dispatch(setError(e));
      error = true;
    } finally {
      setLoading(false);
    }

    return (error) ? null : ticketDataList;
  }, [dispatch]);

  return {loading, updater}
}

export default useGraphTicketDataUpdater;
