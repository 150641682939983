import Web3 from "web3";
import BigNumber from "bignumber.js";
import {KOVAN_CHAIN_ID, MAINNET_CHAIN_ID} from '../constants';
import {bnZero} from "./utilities";
type TypeMap = {
  [key: number]: any;
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });
  return web3;
}

const signMessage = async (web3: any, address: string, nonce: any) => {
  if (!web3 || !address) return;
  const dataToSign = `I am signing my one-time nonce: ${nonce}`;
  try {
    const signed = await web3.eth.personal.sign(dataToSign, address, '');
    return signed;
  } catch (e) {
    return null;
  }
}

// add 10%
function calculateGasMargin(value: BigNumber): BigNumber {
  return value.multipliedBy(new BigNumber(10000)).plus(new BigNumber(1000)).div(new BigNumber(10000));
}

const web3List: TypeMap = {};

function getInfuraAPIEndpoint(chainId: number) {
  switch (chainId) {
    case MAINNET_CHAIN_ID:
      return `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`;
    case KOVAN_CHAIN_ID:
      return `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`;
    default:
      return `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`;
  }
}

function getEtherscanUrl(chainId: number, address: string) {
  switch (chainId) {
    case MAINNET_CHAIN_ID:
      return `https://etherscan.io/address/${address}`;
    case KOVAN_CHAIN_ID:
      return `https://kovan.etherscan.io/address/${address}`;
    default:
      return `https://rinkeby.etherscan.io/address/${address}`;
  }
}


function getWeb3ProviderByHttp(chainId: number) {
  const web3Provider = new Web3.providers.HttpProvider(getInfuraAPIEndpoint(chainId));
  return web3Provider;
}

function _getWeb3ByHttp(chainId: number) {
  const web3Provider = getWeb3ProviderByHttp(chainId);
  const web3Obj = new Web3(web3Provider);
  return web3Obj;
}

export function getWeb3ByHttp(chainId: number) {
  if (!(chainId in web3List)) {
    web3List[chainId] = _getWeb3ByHttp(chainId);
  }
  return web3List[chainId];
}
export default {
  initWeb3,
  calculateGasMargin,
  signMessage,
  getWeb3ByHttp,
  getWeb3ProviderByHttp,
  getInfuraAPIEndpoint,
  getEtherscanUrl,
}
