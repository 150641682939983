import React, {useEffect, useRef, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import {useHistory, useParams} from 'react-router-dom';

import './CreateOrUpdateProject.scss';
import SLoadingWrap from "../../components/SLoadingWrap";
import Button from "../../components/Button";
import CDateInputComponent from "../../components/Form/CDateInputComponent";
import {ICroppedAreaPixels} from "../../components/CropImage";
import {IMapStateToProps} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {setLoading as setGLoading} from '../../redux/auth/actions';

import {
  dtInTz,
  dtStrToDateObject,
  dtTzStrToServerDTStr,
  getFormData,
  getValidUrl,
  getWhitelistArr,
  nf,
  nf_i, noNullText,
  safeNum,
  validateEmail
} from "../../dnweb3/helpers/utilities";
import {ImageCropUploader} from "../../components/ImageCropUploader/ImageCropUploader";
import AppApi from "../../dnweb3/appapi";
import useAuth from "../../hooks/useAuth";
import {EnumFileCat, EnumProjectStatus} from "../../dnweb3/types";
import _ from 'lodash';
import CRadioComponent from "../../components/Form/CRaidoComponent";
import {SocialLinksAvailable} from "../Project/Project";
import LinkButton from "../../components/LinkButton";
import {TZ_NEW_YORK} from "../../config";
import AppMsg from "../../dnweb3/helpers/AppMsg";
import {getContractDS} from "../../dnweb3/controllers/dropspace";
import useSCOwner from "../../hooks/useSCOwner";

interface IMutation {
  changed?: boolean
  cropChanged?: number
}

interface IValues {
  project_id?: number | string;
  name: string;
  name_uid?: string;
  tagline: string;
  description: string;
  sc_base_uri: string;
  total_count: number;
  mint_price: number;
  sc_address: string;
  sc_version: number;
  sc_ticket_subgraph_url: string;
  sc_subgraph_url: string;
  presale_date: any;
  whitelistsale_date: any;
  presale_whitelist: string;
  start_drop_date: any;
  website: string;
  email?: string;
  featured?: boolean;
  status?: EnumProjectStatus;
  social_links?: any;
  is_tbd: boolean;
  is_homepage: boolean;
  is_listing: boolean;

  files?: any;
}

export const DefaultSocialLinks = {};
SocialLinksAvailable.forEach(key => {
  _.set(DefaultSocialLinks, `[${key}]`, '');
});

const INIT_FORM_VALUES: IValues = {
  project_id: '',
  name: '',
  description: "",
  mint_price: 0.05,
  presale_date: "",
  whitelistsale_date: "",
  presale_whitelist: "",
  sc_address: "",
  sc_version: 2.1,
  sc_base_uri: "",
  sc_ticket_subgraph_url: "",
  sc_subgraph_url: "",
  start_drop_date: "",
  tagline: "",
  total_count: 10000,
  website: "",
  email: "",
  featured: false,
  status: EnumProjectStatus.DISABLED,
  social_links: DefaultSocialLinks,
  is_tbd: true,
  is_homepage: true,
  is_listing: true,
  files: {}
};

const LOGO_MAX_SIZE = 5 * 1024 * 1024;
const BANNER_MAX_SIZE = 10 * 1024 * 1024;
const CARD_MAX_SIZE = 10 * 1024 * 1024;

const DefaultCroppedAreaPixelsLogo: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 256,
  height: 256,
}

const DefaultCroppedAreaPixelsBanner: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 1800,
  height: 500,
}

const DefaultCroppedAreaPixelsCard: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 400,
  height: 165,
}

const DefaultCroppedAreaPixelsFeatured: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 600,
  height: 785,
}

const statuses = [
  {value: EnumProjectStatus.ACTIVE, text: 'Active'},
  {value: EnumProjectStatus.DISABLED, text: 'Hidden'},
];

const CreateOrUpdateProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const project_id = params.project_id || '';
  const isCreateMode = project_id == '';
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmDlg, setShowConfirmDlg] = useState<boolean>(false);

  const [intiValues, setInitValues] = useState<IValues>(INIT_FORM_VALUES);
  const [mutations, setMutations] = useState<any>({});

  const {
    authUser: {
      address,
      loggedIn,
      profileLoaded,
      web3,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const {isAdmin, token, requestAuth} = useAuth();

  const {isOwner} = useSCOwner(intiValues.sc_address);

  const formikRef: React.Ref<any> = useRef();

  useEffect(() => {
    // console.log(profileLoaded, isAdmin);
    if (profileLoaded) {
      if (!isAdmin) {
        history.push('/');
      }
    }
  }, [isAdmin, profileLoaded]);

  useEffect(() => {
    if (isAdmin && profileLoaded) {
      if (project_id) {
        setLoading(true);
        AppApi.getProject(project_id, {with: 'files'}).then((res: any) => {
          setProjectData(res);
        }).catch((reason: any) => {
          if (reason?.response?.status == 404) {
            history.push(`/collections`);
          }
        })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setProjectData(INIT_FORM_VALUES);
      }
    }
  }, [project_id, profileLoaded, isAdmin]);


  const setProjectData = (res: any) => {
    const newValues = {...INIT_FORM_VALUES};
    newValues.project_id = res.project_id;
    newValues.name = res.name || '';
    newValues.name_uid = res.name_uid;    // read-only
    newValues.tagline = res.tagline || '';
    newValues.description = noNullText(res.description || '');
    newValues.mint_price = safeNum(res.mint_price);
    newValues.total_count = safeNum(res.total_count);
    newValues.sc_address = res.sc_address || '';
    newValues.sc_version = safeNum(res.sc_version);
    newValues.sc_ticket_subgraph_url = res.sc_ticket_subgraph_url || '';
    newValues.sc_subgraph_url = res.sc_subgraph_url || '';
    newValues.presale_date = dtStrToDateObject(res.presale_date);
    newValues.whitelistsale_date = dtStrToDateObject(res.whitelistsale_date);
    newValues.presale_whitelist = noNullText(res.presale_whitelist);
    newValues.start_drop_date = dtStrToDateObject(res.start_drop_date);
    newValues.email = res.email || '';
    newValues.featured = res.featured == 1;
    newValues.is_tbd = res.is_tbd == 1;
    newValues.is_listing = res.is_listing == 1;
    newValues.is_homepage = res.is_homepage == 1;
    newValues.status = res.status;
    newValues.website = res.website;
    newValues.social_links = typeof res.social_links == 'string' ? res.social_links ? JSON.parse(res.social_links) : INIT_FORM_VALUES.social_links : newValues.social_links;

    newValues.files = res.files;

    setInitValues(newValues);

    /*if (res && res.avatar_org_path) {
      let url = `${APP_API}/address/avatar/${res.address}?type=original`;
      console.log("Profile Data = ", res);
      const tmpFunc = async () => {
        const fileName = res.avatar_org_path.substring(res.avatar_org_path.lastIndexOf('/') + 1);
        const fileObj: File = await CropUtil.getFileFromUrl(url, fileName);
        if (fileObj.type.toLowerCase() === (res.avatar_type || '').toLowerCase()) {
          setFile(fileObj);
          setImgSrc(url);

          // crop info
          const initData = {
            x: parseInt(res.crop_x),
            y: parseInt(res.crop_y),
            height: parseInt(res.crop_height) || 100,
            width: parseInt(res.crop_width) || 100
          };
          setCroppedAreaPixels(initData);
        } else {
          setImgSrc(null);
          setFile(null);
          setCroppedAreaPixels({x:0,y:0,width: 0, height: 0});
        }
      }
      tmpFunc().then();
    } else {
      setFile(null);
      setImgSrc(null);
    }*/
  }

  const validate = (values: IValues) => {
    const errors: FormikErrors<IValues> = {};

    if (!values.name)
      errors.name = 'Name required!';

    if (!values.tagline)
      errors.tagline = 'Tagline required!';

    if (values.email && !validateEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  /*const submitUpdatedValueOnBackend = async (newData: any) => {
    setLoading(true);
    AppApi.updateProjectByField(project_id, newData)
      .then(function (response: any) {
        if ((response.status = 200)) {
          AppMsg.info('Updated project info.');
        }
      })
      .catch((reason) => {
        const msg = AppApi.getResponseErrorMsg(reason);
        AppMsg.error(msg ? msg : 'Error occurred! Please reload a page and try again!');
      })
      .finally(() => {
        setLoading(false);
        dispatch(setGLoading(false));
      });
  };*/

  /*const onClickUpdate = async (field: string) => {
    if (isOwner && formikRef.current.values.sc_address) {
      if (!token) {
        setLoading(true);
        const tokenL = await requestAuth();
        setLoading(false);
        if (!tokenL) {
          AppMsg.error('You need to sign with one-time nonce to perform this action!');
          return;
        }
      }

      const controller = getContractDS(web3, formikRef.current.values.sc_address, formikRef.current.values.sc_version);

      if (field === 'presale_whitelist') {
        let presaleWhitelist = formikRef.current.values.f;
        if (!presaleWhitelist || presaleWhitelist === '') return;
        const presaleWhitelistArr = getWhitelistArr(presaleWhitelist);
        console.log('Updated list', presaleWhitelistArr);
        presaleWhitelist = presaleWhitelistArr.join('\n');

        setLoading(true);
        controller
          .setWhitelistRoot(address, presaleWhitelistArr)
          .then(async (res: any) => {
            if (res.status === true) {
              submitUpdatedValueOnBackend({presale_whitelist: presaleWhitelist});
              formikRef.current.values.presale_whitelist = presaleWhitelist;
            }
          })
          .catch((reason: any) => {
            console.log(reason);
            const msg = AppApi.getResponseErrorMsg(reason);
            AppMsg.error(msg ? msg : 'Failed to set Whitelist Sale Time! Please check the value and try again.');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      AppMsg.info('You don\'t have a permission.');
    }
  };*/

  const onSubmit = async (values: IValues, actions: FormikHelpers<IValues>) => {
    const {setFieldError, setSubmitting} = actions;

    // auth Validation.
    if (!isAdmin) {
      AppMsg.error("You are not authorized!");
      return;
    }

    if (!token) {
      setLoading(true);
      const token = await requestAuth();
      setLoading(false);
      if (!token) {
        AppMsg.error("You need to sign with one-time nonce to perform this action!");
        return;
      }
    }

    setLoading(true);
    /*console.log(logoChildRef.current.getCroppedAreaPixels());
    const cropImage = await logoChildRef.current.getCropImage();
    console.log(cropImage);
    console.log(values);*/

    // Time conversion
    const formData = getFormData(values);

    formData.set('presale_date', values.presale_date ? dtTzStrToServerDTStr(values.presale_date, TZ_NEW_YORK) : '');
    formData.set('whitelistsale_date', values.whitelistsale_date ? dtTzStrToServerDTStr(values.whitelistsale_date, TZ_NEW_YORK) : '');
    formData.set('start_drop_date', values.start_drop_date ? dtTzStrToServerDTStr(values.start_drop_date, TZ_NEW_YORK) : '');

    formData.set('featured', values.featured ? '1' : '0');
    formData.set('is_tbd', values.is_tbd ? '1' : '0');
    formData.set('is_listing', values.is_listing ? '1' : '0');
    formData.set('is_homepage', values.is_homepage ? '1' : '0');

    // image files.
    if (mutations[EnumFileCat.LOGO]?.changed) {
      formData.append(`fileMutations[${EnumFileCat.LOGO}][changed]`, `1`);
      formData.append('fileLogo', logoChildRef.current.getFile());
    }
    if (mutations[EnumFileCat.BANNER]?.changed) {
      formData.append(`fileMutations[${EnumFileCat.BANNER}][changed]`, `1`);
      formData.append('fileBanner', bannerChildRef.current.getFile());
    }
    if (mutations[EnumFileCat.CARD]?.changed) {
      formData.append(`fileMutations[${EnumFileCat.CARD}][changed]`, `1`);
      formData.append('fileCard', cardChildRef.current.getFile());

      const cropInfo = cardChildRef.current.getCroppedAreaPixels();
      formData.append('fileCardCropInfo[x]', `${cropInfo.x}`);
      formData.append('fileCardCropInfo[y]', `${cropInfo.y}`);
      formData.append('fileCardCropInfo[width]', `${cropInfo.width}`);
      formData.append('fileCardCropInfo[height]', `${cropInfo.height}`);
    }
    if (mutations[EnumFileCat.FEATURED]?.changed) {
      formData.append(`fileMutations[${EnumFileCat.FEATURED}][changed]`, `1`);
      formData.append('fileFeatured', featuredChildRef.current.getFile());
    }

    formData.delete('socialLinks');
    formData.delete('files');

    formData.set('sc_base_uri', getValidUrl(values.sc_base_uri));
    formData.set('sc_ticket_subgraph_url', getValidUrl(values.sc_ticket_subgraph_url));
    formData.set('sc_subgraph_url', getValidUrl(values.sc_subgraph_url));
    formData.set('website', getValidUrl(values.website));
    const socialLinks = values.social_links;
    for (let key in socialLinks) {
      if (socialLinks[key]) {
        socialLinks[key] = getValidUrl(socialLinks[key]);
      }
    }
    formData.append(`social_links`, JSON.stringify(socialLinks));

    console.log("Final Form: ", Object.fromEntries(formData.entries()));

    AppApi.request('post', `/projects`, formData)
      .then(function (response: any) {
        const project_id = response.data.message?.project_id;
        if (response.status = 200 && project_id) {
          AppMsg.info(isCreateMode ? 'Created the new project successfully.' : 'Updated project info.');
          if (isCreateMode) {
            setTimeout(() => {
              history.push(`/edit-collection/${project_id}`);
            }, 400);
          }
        }
      })
      .catch(reason => {
        let msg = AppApi.getResponseErrorMsg(reason);
        AppMsg.info(msg ? msg : 'Error occurred! Please reload a page and try again!');
      })
      .finally(() => setLoading(false));
  };

  const onDelete = async () => {
    // auth Validation.
    if (!isAdmin) {
      AppMsg.error("You are not authorized!");
      return;
    }

    if (!token) {
      setLoading(true);
      await requestAuth();
      setLoading(false);
    }

    setLoading(true);

    AppApi.request('delete', `/projects/${project_id}`, {project_id})
      .then(function (response: any) {
        if (response.status = 204) {
          AppMsg.info('Deleted successfully.');
          setTimeout(() => {
            history.push(`/collections`);
          }, 200);
        } else {
          AppMsg.info('Failed to delete project.');
        }
      })
      .catch(reason => {
        let msg = AppApi.getResponseErrorMsg(reason);
        AppMsg.info(msg ? msg : 'Error occurred! Please reload a page and try again!');
      })
      .finally(() => setLoading(false));

  }

  /**
   * ----------------------------------------------------------------------
   * Image file
   * ----------------------------------------------------------------------
   */
  const logoChildRef = useRef<any>();
  const bannerChildRef = useRef<any>();
  const cardChildRef = useRef<any>();
  const featuredChildRef = useRef<any>();

  const onClickLogoUpdate = async (remove: boolean = false) => {
  }

  const onMutationChange = (type: EnumFileCat, key: string, value: any) => {
    setMutations((prevState: any) => {
      const newState = {...prevState};
      _.set(newState, `[${type}][${key}]`, value);
      return newState;
    });
  }

  return (
    <div className="create-project text-black2 my-20">
      <div className="container">
        {<div>
          <h1 className="base text-left max-w-xl mb-10">
            {project_id ? "Edit" : "Create"} project
            {isAdmin && project_id &&
              <LinkButton className="btn-white gray align-middle bg-white ml-8 mt-8" linkTo={`/collection/${intiValues.name_uid}`}>View</LinkButton>}
          </h1>
          <p className="max-w-xl my-4">Quis vitae porttitor lectus vel facilisis nisl, varius ullamcorper. Pellentesque vel donec diam tristique proin
            vel. Nunc vulputate sed mauris, et cras accumsan.</p>

          <Formik enableReinitialize={true} initialValues={intiValues} onSubmit={onSubmit} validate={validate} innerRef={formikRef}>
            {(props: FormikProps<IValues>) => (
            <SLoadingWrap
              className="min-h-screen"
              loading={loading}
              maskCls={"full z-10"}
            >
              {profileLoaded && <Form className="form">
                <h3 className="style1 font-bold pb-1 pr-4 my-8 border-b border-black border-opacity-50 flex flex-grow-0 max-w-max">Project
                  details</h3>
                <div className="form-row">
                  <label className="form-label required" htmlFor="name">Name</label>
                  <Field id="name" name="name" placeholder="project name"/>
                  <ErrorMessage component="div" name="name" className="help-block errors"/>
                </div>
                <div className="form-row">
                  <label className="form-label required" htmlFor="tagline">Tagline</label>
                  <Field id="tagline" name="tagline" placeholder="tagline"/>
                  <ErrorMessage component="div" name="tagline" className="help-block errors"/>
                </div>

                <div className="form-row form-row-inline">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="status">Status</label>
                    <Field id="status" name="status" as="select">
                      {statuses.map(x => <option key={x.value} value={x.value}>{x.text}</option>)}
                    </Field>
                  </div>
                  <ErrorMessage component="div" name="status" className="help-block errors"/>
                </div>

                <div className="form-row form-row">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="is_listing">Listing?</label>
                    <Field id="is_listing" name="is_listing" component={CRadioComponent}/>
                  </div>
                  <ErrorMessage component="div" name="is_listing" className="help-block errors"/>
                </div>

                <div className="form-row form-row-inline">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="featured">Featured?</label>
                    <Field id="featured" name="featured" component={CRadioComponent}/>
                  </div>
                  <ErrorMessage component="div" name="featured" className="help-block errors"/>
                </div>

                <div className="form-row form-row-inline">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="is_homepage">Show homepage?</label>
                    <Field id="is_homepage" name="is_homepage" component={CRadioComponent}/>
                  </div>
                  <ErrorMessage component="div" name="is_homepage" className="help-block errors"/>
                </div>

                <div className="form-row">
                  <label className="form-label" htmlFor="description">Description</label>
                  <Field id="description" name="description" placeholder="description" as="textarea" rows={6}/>
                </div>

                {/* Avatar */}
                <div className="form-row">
                  <label className="form-label">Avatar / logo</label>
                  <span className="help-block">PNG, JPG. {nf_i(DefaultCroppedAreaPixelsLogo.width)}px × {nf_i(DefaultCroppedAreaPixelsLogo.height)}px or higher recommended. Max {nf(LOGO_MAX_SIZE / 1024 / 1024, 0)}MB.</span>
                  <ImageCropUploader
                    ref={logoChildRef}
                    loading={loading}
                    setLoading={setLoading}
                    maxSize={LOGO_MAX_SIZE}
                    defaultCroppedAreaPixels={DefaultCroppedAreaPixelsLogo}
                    updateAndUpload={onClickLogoUpdate}
                    hidePreview
                    imageUrl={AppApi.getFileUrl(intiValues.files[EnumFileCat.LOGO])}
                    fileChanged={(file) => onMutationChange(EnumFileCat.LOGO, 'changed', true)}
                    fileRemoved={() => onMutationChange(EnumFileCat.LOGO, 'changed', true)}
                    cropAreaChanged={(pixels) => onMutationChange(EnumFileCat.LOGO, 'cropChanged', pixels)}
                  />
                </div>
                <div className="form-row">
                  <label className="form-label">Banner</label>
                  <span
                    className="help-block">PNG, JPG. {nf_i(DefaultCroppedAreaPixelsBanner.width)}px × {nf_i(DefaultCroppedAreaPixelsBanner.height)}px or higher recommended. Max {nf(BANNER_MAX_SIZE / 1024 / 1024, 0)}MB.</span>
                  <ImageCropUploader
                    ref={bannerChildRef}
                    loading={loading}
                    setLoading={setLoading}
                    maxSize={BANNER_MAX_SIZE}
                    defaultCroppedAreaPixels={DefaultCroppedAreaPixelsBanner}
                    updateAndUpload={onClickLogoUpdate}
                    hidePreview
                    imageUrl={AppApi.getFileUrl(intiValues.files[EnumFileCat.BANNER])}
                    fileChanged={(file) => onMutationChange(EnumFileCat.BANNER, 'changed', true)}
                    fileRemoved={() => onMutationChange(EnumFileCat.BANNER, 'changed', true)}
                    cropAreaChanged={(pixels) => onMutationChange(EnumFileCat.BANNER, 'cropChanged', pixels)}
                  />
                </div>

                <div className="form-row">
                  <label className="form-label">Card Image</label>
                  <span className="help-block">PNG, JPG. {nf_i(DefaultCroppedAreaPixelsCard.width)}px × {nf_i(DefaultCroppedAreaPixelsCard.height)}px or higher recommended. Max {nf(CARD_MAX_SIZE / 1024 / 1024, 0)}MB.</span>
                  <ImageCropUploader
                    ref={cardChildRef}
                    loading={loading}
                    setLoading={setLoading}
                    previewTitle={""}
                    maxSize={CARD_MAX_SIZE}
                    defaultCroppedAreaPixels={DefaultCroppedAreaPixelsCard}
                    updateAndUpload={onClickLogoUpdate}
                    hidePreview
                    imageUrl={AppApi.getFileUrl(intiValues.files[EnumFileCat.CARD])}
                    fileChanged={(file) => onMutationChange(EnumFileCat.CARD, 'changed', true)}
                    fileRemoved={() => onMutationChange(EnumFileCat.CARD, 'changed', true)}
                    cropAreaChanged={(pixels) => onMutationChange(EnumFileCat.CARD, 'cropChanged', pixels)}
                  />
                </div>

                <div className="form-row">
                  <label className="form-label">Featured Image</label>
                  <span className="help-block">Used in the featured / live listing on home page.</span>
                  <span
                    className="help-block">PNG, JPG. {nf_i(DefaultCroppedAreaPixelsFeatured.width)}px × {nf_i(DefaultCroppedAreaPixelsFeatured.height)}px or higher recommended. Max {nf(CARD_MAX_SIZE / 1024 / 1024, 0)}MB.</span>
                  <ImageCropUploader
                    ref={featuredChildRef}
                    loading={loading}
                    setLoading={setLoading}
                    previewTitle={""}
                    maxSize={CARD_MAX_SIZE}
                    defaultCroppedAreaPixels={DefaultCroppedAreaPixelsFeatured}
                    updateAndUpload={onClickLogoUpdate}
                    hidePreview
                    imageUrl={AppApi.getFileUrl(intiValues.files[EnumFileCat.FEATURED])}
                    fileChanged={(file) => onMutationChange(EnumFileCat.FEATURED, 'changed', true)}
                    fileRemoved={() => onMutationChange(EnumFileCat.FEATURED, 'changed', true)}
                    cropAreaChanged={(pixels) => onMutationChange(EnumFileCat.FEATURED, 'cropChanged', pixels)}
                  />
                </div>

                <div className="form-row">
                  <label className="form-label" htmlFor="sc_address">Smart Contract Address</label>
                  <Field id="sc_address" name="sc_address" placeholder="Smart contract address"/>
                </div>
                <div className="form-row">
                  <label className="form-label" htmlFor="sc_version">Smart Contract Version (Numeric)</label>
                  <span className="help-block">Internal smart contract version, used for Subgraph. Applied since 11/4/2021</span>
                  <Field id="sc_version" name="sc_version" placeholder="Smart contract version"/>
                </div>
                {/*<div className={`form-row ${formikRef.current.values.sc_version == 2.1 ? '' : 'hidden'}`}>
                  <label className="form-label" htmlFor="presale_whitelist">
                    Whitelists
                  </label>
                  <span className="help-block block">Comma or new line separated text</span>
                  <Field
                    id="presale_whitelist"
                    name="presale_whitelist"
                    placeholder="Pre-sale whitelist"
                    as="textarea"
                    rows={3}
                    className="leading-5"
                  />
                  {props.values.sc_version == 2.1 ? (
                    <>
                      <div className={`inline-block xs:ml-0 md:ml-4 align-middle -mt-14`}>
                        <Button className="btn-default btn-dark ml-4 px-4" onClick={() => onClickUpdate('presale_whitelist')}>
                          Update
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>*/}
                <div className="form-row">
                  <label className="form-label" htmlFor="sc_subgraph_url">Subgraph URI</label>
                  <Field id="sc_subgraph_url" name="sc_subgraph_url" placeholder="Subgraph URI"/>
                </div>
                <div className="form-row">
                  <label className="form-label" htmlFor="total_count">Total Count</label>
                  <Field id="total_count" name="total_count" placeholder="Total Count" type="number"/>
                </div>
                <div className="form-row">
                  <label className="form-label" htmlFor="mint_price">Mint Price</label>
                  <Field id="mint_price" name="mint_price" placeholder="price" type="number" step={0.01}/>
                </div>

                <div className="form-row form-row">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="is_tbd">TBD?</label>
                    <Field id="is_tbd" name="is_tbd" component={CRadioComponent}/>
                  </div>
                  <ErrorMessage component="div" name="is_tbd" className="help-block errors"/>
                </div>

                <div className="flex form-row">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="presale_date">MintTicket sale on</label>
                    <Field id="presale_date" name="presale_date" autoComplete='off' placeholder="MintTicket sale date" className="" showTimeSelect
                           dateFormat="Pp" component={CDateInputComponent}/>
                    <label className="ml-4">EST</label>
                  </div>
                </div>

                <div className="flex form-row">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="whitelistsale_date">Whilelist sale On</label>
                    <Field id="whitelistsale_date" name="whitelistsale_date" autoComplete='off' placeholder="Whitelist sale date" className=""
                           showTimeSelect dateFormat="Pp" component={CDateInputComponent}/>
                    <label className="ml-4">EST</label>
                  </div>
                </div>

                <div className="flex form-row">
                  <div className="flex items-center">
                    <label className="form-label" htmlFor="start_drop_date">Public sale On</label>
                    <Field id="start_drop_date" name="start_drop_date" autoComplete='off' placeholder="Public sale date" className="" showTimeSelect
                           dateFormat="Pp" component={CDateInputComponent}/>
                    <label className="ml-4">EST</label>
                  </div>
                </div>

                <div className="form-row">
                  <label className="form-label" htmlFor="website">Website</label>
                  <Field id="website" name="website" placeholder="Website"/>
                </div>

                <h3
                  className="style1 font-bold font-suisse pb-1 pr-4 my-20 mb-8 border-b border-black border-opacity-50 flex flex-grow-0 max-w-max">Social
                  Links</h3>
                {SocialLinksAvailable.map(socialKey => {
                  return <div key={socialKey} className="form-row relative">
                    <label className="absolute form-label mt-4 ml-3" style={{minWidth: 18}}>
                      <img src={require(`../../assets/images/social-icons/${socialKey}.png`).default} width={18} height={18}/>
                    </label>
                    <Field id={`social_links.${socialKey}`} name={`social_links.${socialKey}`} style={{paddingLeft: '3rem'}} placeholder="URL"/>
                  </div>;
                })}

                <h3 className="style1 font-bold pb-1 pr-4 my-20 mb-8 border-b border-black border-opacity-50 flex flex-grow-0 max-w-max">Your
                  details</h3>
                <div className="form-row">
                  <label className="form-label" htmlFor="email">Email</label>
                  <Field id="email" name="email" placeholder="Email"/>
                </div>
                <div className={"flex font-suisse"}>
                  <Button className="btn-default font-suisse btn-white black" type="submit">Submit</Button>
                  {isAdmin && project_id && <>
                    <Button className="btn-default btn-dark font-suisse ml-8" type="button" onClick={() => setShowConfirmDlg(true)}>Delete</Button>

                    <div
                      className={`modal-checkout modal ${showConfirmDlg ? '' : 'hidden'} flex flex-col fixed w-screen min-h-full top-0 left-0 flex items-center shadow-lg justify-center z-50`}
                    >
                      <div className="modal-overlay absolute w-screen h-full bg-black opacity-50 pointer-events-none"
                           onClick={() => setShowConfirmDlg(false)}></div>
                      <div className="modal-container max-h-screen90 bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto relative">
                        <div className="modal-content py-12 text-left px-6 items-center justify-center text-black">
                          <h4 className="text-lg tracking-10 font-bold font-stinger_fit text-center mt-8 mb-12">
                            Are you sure you want to delete?
                          </h4>
                          <div className="flex justify-end">
                            <Button className="btn-default btn-dark ml-auto font-suisse" type="button" onClick={onDelete}>Confirm & Delete</Button>
                            <Button className="btn-default btn-white black ml-6 font-suisse" type="button"
                                    onClick={() => setShowConfirmDlg(false)}>Close</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>}
                </div>
              </Form>}
            </SLoadingWrap>)}
          </Formik>
        </div>}
      </div>

    </div>
  );
}

export default CreateOrUpdateProject;
