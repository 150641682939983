import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {nf} from "../../dnweb3/helpers/utilities";
import ImgRemove from "../../assets/images/icon/remove.svg";
import CropImage, {ICroppedAreaPixels} from "../CropImage";
import Button from "../Button";
import CropUtil from "../CropImage/CropUtil";
import SLoadingWrap from "../SLoadingWrap";


const InitialCroppedAreaPixels: ICroppedAreaPixels = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

export interface IImageCropUploaderRef {
  getCroppedAreaPixels?: any
  getCropImage?: any
  getFile?: any
}

export interface IImageCropUploader {
  loading: boolean
  setLoading: any

  hideCrop?: boolean
  hidePreview?: boolean
  previewTitle?: string
  wrapCls?: string

  maxSize: number
  acceptFileFormats?: string
  defaultCroppedAreaPixels: ICroppedAreaPixels
  //croppedAreaPixels: ICroppedAreaPixels
  imageName?: string | undefined
  imageUrl?: string | undefined

  updateAndUpload: any

  // Called after user selects a file.
  fileChanged?: (file: File) => void
  fileRemoved?: () => void
  // Called whenever user crops
  cropAreaChanged?: (args: ICroppedAreaPixels) => void
}

const ImageCropUploader = forwardRef<IImageCropUploaderRef, IImageCropUploader>(({
                                                                                   loading: loadingProp,
                                                                                   setLoading: setLoadingProp,

                                                                                   hideCrop,
                                                                                   hidePreview,
                                                                                   previewTitle,
                                                                                   wrapCls,

                                                                                   maxSize,
                                                                                   acceptFileFormats,
                                                                                   defaultCroppedAreaPixels,
                                                                                   imageName,
                                                                                   imageUrl,

                                                                                   updateAndUpload,
                                                                                   fileChanged,
                                                                                   fileRemoved,
                                                                                   cropAreaChanged,

                                                                                 }: IImageCropUploader, ref) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICroppedAreaPixels>(defaultCroppedAreaPixels);

  const childRef = useRef<any>();
  const [file, setFile] = useState<any>();
  const [imgSrc, setImgSrc] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

  }, []);
  useEffect(() => {
    if (imageUrl) {
      const tmpFunc = async () => {
        const fileName = imageName || 'Image';
        const fileObj: File = await CropUtil.getFileFromUrl(imageUrl, fileName);
        if (fileObj.type.toLowerCase()) {
          setFile(fileObj);
          setImgSrc(imageUrl);
        } else {
          setImgSrc(null);
          setFile(null);
          setCroppedAreaPixels(InitialCroppedAreaPixels);
        }
      }
      setLoading(true);
      tmpFunc().then().finally(() => setLoading(false));
    } else {
      setFile(null);
      setImgSrc(null);
    }
  }, [imageUrl]);

  useEffect(() => {
    setCroppedAreaPixels(defaultCroppedAreaPixels);
  }, [defaultCroppedAreaPixels]);

  useEffect(() => {
    if (file && (!hideCrop && !hidePreview)) {
      cropAreaChanged && cropAreaChanged(croppedAreaPixels);
    }
  }, [croppedAreaPixels]);


  /**
   * -------------------------------------------------------------------------------------
   * Forward Ref functions
   */
  useImperativeHandle(ref, () => ({
    async getCropImage() {
      try {
        return childRef.current.getCropImage() || null;
      } catch (e) {
        console.error(e)
      }
    },
    getCroppedAreaPixels() {
      return croppedAreaPixels || null;
    },
    getFile() {
      return file || null;
    }
  }), [croppedAreaPixels, file, childRef]);


  /**
   * -------------------------------------------------------------------------------------
   * Loading images to be uploaded. Triggered after user selects an image.
   * @param acceptedFile
   */
  const loadImageFile = (acceptedFile: File) => {
    fileChanged && fileChanged(acceptedFile);
    if (!acceptedFile) return;
    // Do something with the files
    const preview = URL.createObjectURL(acceptedFile);
    setImgSrc(preview);
    setFile(acceptedFile);
  };

  const onDrop = useCallback(async acceptedFiles => {
    loadImageFile(acceptedFiles[0]);
  }, []);

  const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone({
    accept: acceptFileFormats || "image/*",
    multiple: false,
    minSize: 0,
    maxSize: maxSize,
    onDrop
  });

  const handleRemoveImage = () => {
    setFile(null);
    setImgSrc(null);
    setCroppedAreaPixels({x: 0, y: 0, width: 0, height: 0});
    fileRemoved && fileRemoved();
  }

  return (
    <div className={`icu flex ${wrapCls || ''}`}>
      <div className="icu-uploader">
        <SLoadingWrap className={`dropzone-wrap`} maskCls={'z-10'} loading={loading}>
          {!imgSrc && (
            <>
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag and drop your file, or browse to choose a file</p>
              </div>
            </>
          )}
          {imgSrc && (
            <div className="dz-preview">
              <img src={imgSrc} alt=""/>
              <div className="remove" onClick={handleRemoveImage}><img src={ImgRemove}/></div>
            </div>
          )}
        </SLoadingWrap>
        {fileRejections.length > 0 && <div className="help-block errors">
          {fileRejections.map(({file: any, errors}, ind) => (
            <ul key={file ? file.path : ind}>
              {errors.map(e => (
                <li key={e.code}>{e.code == 'file-too-large' ? `File is larger than ${nf(maxSize / 1024 / 1024, 0)}MB.` : e.message}</li>
              ))}
            </ul>
          ))}
        </div>}
      </div>

      {!hidePreview && <div className="icu-preview ml-20">
        {previewTitle && <label className="form-label">{previewTitle}</label>}
        <div className={`img-preview-wrap`}>
          {hideCrop ?
            <>
              <img src={imgSrc} width={croppedAreaPixels.width} height={croppedAreaPixels.height}/>
            </> :
            <CropImage
              ref={childRef}
              imgSrc={imgSrc}
              file={file}
              blockDimension={{x: 100, y: 100}}
              croppedAreaPixels={croppedAreaPixels}
              initialCroppedAreaPixels={croppedAreaPixels}
              setCroppedAreaPixels={setCroppedAreaPixels}
            />}
        </div>
        {/*<div className="flex my-4 space-x-4">
            {(file && imgSrc) && <Button className="btn btn-default btn-white black" disable={loading} onClick={() => cbUpdateAndUpload(false)}>Confirm</Button>}
            {(true /*&& form.avatar_org_path* /) && <Button className="btn btn-default  btn-white black" disable={loading} onClick={() => cbUpdateAndUpload(true)}>Remove</Button>}
          </div>*/}
      </div>}
    </div>
  );
});

export {
  ImageCropUploader
};
