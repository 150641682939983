import React from 'react';
import LazyLoad from 'react-lazyload';
import {ICollection} from "../../dnweb3/types";
import CollectionSummary from "../CollectionSummary/CollectionSummary";

export enum EnumCollectionLayoutTypes {
  FEATURED = 0,
  NORMAL = 1,
  SOLD_OUT = 2,
}

interface ICollectionProps {
  item: ICollection,
  layoutStyle?: EnumCollectionLayoutTypes.FEATURED,
  className?: string
}

const Collection = (props: ICollectionProps) => {

  const {item, layoutStyle, className} = props;

  return (
    <div className={`collection ${className || ''}`}>
      <div className="collection-img max-h-125 overflow-hidden">
        <LazyLoad>
          <img className={""} src={item.banner_url} width="100%" height="auto"/>
        </LazyLoad>
      </div>
      <CollectionSummary item={item}/>
    </div>
  );
}

export default Collection;
