import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doLogin, getAuthToken, isLoggedIn} from "../dnweb3/helpers/AuthUtil";
import {setGuestProfile, setLoggedIn, setProfile} from "../redux/auth/actions";
import useUserBalanceUpdater from "../dnweb3/hooks/useUserBalanceUpdater";
import SLoadingModal from "./Dialog/SLoadingModal";
import {IMapStateToProps} from "../types";
import AppApi from "../dnweb3/appapi";
import {EnumUserRole} from "../dnweb3/types";
import useAuth from "../hooks/useAuth";

/**
 * A loading component with the main biz logic to fetch data
 * Polling by timer. Default interval is 60s
 *
 * @returns {*}
 * @constructor
 */
export default function LoadingData() {
  const dispatch = useDispatch();

  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3,
      profileLoaded,
      profile:{role}
    }
  } = useSelector((state: IMapStateToProps) => state);
  const {token, requestAuth} = useAuth();
  const {loading: loadingUserBalance, updater: updateUserBalance} = useUserBalanceUpdater(address);

  useEffect(() => {
    if (isLoggedIn(curNetworkId, address, connectType)) {
      dispatch(setLoggedIn(true));
    }
  }, [curNetworkId, address, connectType]);

  /**
   * Real time login status
   */
  useEffect(() => {
    const tmpFunc = async () => {
      if (web3 && address && loggedIn) {
        AppApi.getUserProfile(address).then(res => {
          dispatch(setProfile({...res, token: getAuthToken(address)}))
        }).catch(reason => {
          dispatch(setGuestProfile(true));
          // console.log("Error", reason.message);
        });
      } else {
        dispatch(setGuestProfile(true));
      }
    }
    tmpFunc();
  }, [web3, address, loggedIn]);

  useEffect(() => {
    if (loggedIn && address && profileLoaded && role == EnumUserRole.ADMIN && !token) {
      requestAuth().then();
    }
  }, [loggedIn, address, profileLoaded, token, requestAuth]);

  return (
    <>{(loading && loadingType > 0) && <SLoadingModal isOpen={loading && loadingType > 0}/>}</>
  );
};
