import React, {useCallback} from 'react';
import {ICollection} from "../../dnweb3/types";
import CollectionSummary from "../CollectionSummary/CollectionSummary";
import './CollectionCard.scss';
import {nf, nf_i} from "../../dnweb3/helpers/utilities";
import ButtonArrow from "../ButtonArrow";
import {Link} from "react-router-dom";
import LazyLoad from 'react-lazyload';
// import ImgCuratedBadge from "../../assets/images/CuratedSticker_32x.png";

export enum EnumCollectionLayoutTypes {
  FEATURED = 0,   // In featured section on Home page
  NORMAL = 1,     // In card.
  SOLD_OUT = 2,     // In card.
}

interface ICollectionCardProps {
  item: ICollection,
  layoutStyle?: EnumCollectionLayoutTypes,
  className?: string
}

const CollectionCard = (props: ICollectionCardProps) => {

  const {item, layoutStyle, className} = props;

  const renderContent = useCallback(() => {
    let html = null;
    switch (layoutStyle) {
      case EnumCollectionLayoutTypes.NORMAL:
      case EnumCollectionLayoutTypes.SOLD_OUT:
        html = (
          <Link to={`/collection/${item.name_uid}`}>
            <div className="collection-img max-h-125 overflow-hidden">
              <LazyLoad>
                <img className={""} src={item.card_url} width="auto" height="auto"/>
              </LazyLoad>
            </div>
            {/*{item.is_curated && <img className="absolute top-0 right-0 max-w-none -mr-10 -mt-10 w-24 h-24 z-10 transform rotate-12" src={ImgCuratedBadge}/>}*/}
            <CollectionSummary item={item} layoutStyle={layoutStyle}/>
          </Link>
        );
        break;
      case EnumCollectionLayoutTypes.FEATURED:
      default:
        html = (
          <>`
            <div className="collection-img max-h-125 overflow-hidden">
              <LazyLoad>
                <img className={""} src={item.card_url || item.banner_url} width="100%" height="auto"/>
              </LazyLoad>
            </div>
            <CollectionSummary item={item} layoutStyle={layoutStyle}/>
          </>
        );
        break;
    }
    return html;
  }, [item, layoutStyle]);

  return (
    <div className={`collection relative ${className || 'normal'}`}>
      {renderContent()}
    </div>
  );
}

export default CollectionCard;
