import BigNumber from "bignumber.js";
import {bnZero} from "../helpers/utilities";

export interface ITicketData {
  price: BigNumber
  mintLimit: number

  nftSold: number
  nftTotal?: number
  saleActive: boolean
  baseUri?:string
}

export const InitialTicketData: ITicketData = {
  nftSold: 30,
  mintLimit: 5,
  price: bnZero(),
  saleActive: false,
  nftTotal: 80,
};


