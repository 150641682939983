import {useLayoutEffect} from "react";

export default function useBodyScrollbar(hideScrollbar: boolean) {
  const body = (document.querySelector('body') as HTMLElement);

  const bodyScrollbar = () => {
    if (body) {
      const scrollWidth = window.innerWidth - document.body.clientWidth;
      if (hideScrollbar) {
        body.style.marginRight = scrollWidth + "px";
        body.classList.add('modal-active');
      } else {
        body.style.marginRight = 'unset';
        body.classList.remove('modal-active');
      }
    }
  };

  const cleanup = () => {
    if (body) {
      body.style.marginRight = 'unset';
      body.classList.remove('modal-active');
    }
  }

  useLayoutEffect(() => {
    bodyScrollbar();

    return cleanup;
  }, [hideScrollbar]);
}
