import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import ReactLoading from "react-loading";

import {LOADING_TYPE_BUYING, LOADING_TYPE_BUYING_DROPSPACE, LOADING_TYPE_BUYING_TICKET} from "../../dnweb3/constants";

import {IMapStateToProps} from "../../types";
import useBodyScrollbar from "../../hooks/useBodyScrollbar";


interface ISLoadingModalProps {
  isOpen: boolean
  loadingIconColor?: string
  renderBody?: (loadingType: any) => void
}

const SLoadingModal = ({
                         isOpen,
                         loadingIconColor,
                         renderBody
                       }: ISLoadingModalProps) => {
  const modalRef: any = useRef();
  const loading = useSelector((state: IMapStateToProps) => state.authUser.loading);
  const loadingType = useSelector((state: IMapStateToProps) => state.authUser.loadingType);

  useBodyScrollbar(isOpen || (loading && loadingType > 0));

  const renderLoadingBody = useCallback(() => {

    if (renderBody) {
      return renderBody(loadingType);
    }

    switch (loadingType) {
      case LOADING_TYPE_BUYING:
        return (
          <h3 className="text-xl font-bold text-center text-black mt-10">
            Hang tight cabrón, we’re minting your NFT.
          </h3>
        );
      case LOADING_TYPE_BUYING_TICKET:
        return (
          <h3 className="text-xl font-bold text-center text-black my-12 px-10">
            Please wait while we are minting your ticket.
          </h3>
        );
      case LOADING_TYPE_BUYING_DROPSPACE:
        return (
          <h3 className="text-xl font-bold text-center text-black my-12 px-10">
            Please wait while we are minting your NFT.
          </h3>
        );
    }
  }, [loadingType]);

  const opened = isOpen || (loading && loadingType > 0);

  return (
    <div className={`modal ${opened ? 'pointer-events-auto' : 'opacity-0'} pointer-events-none fixed w-screen h-full top-0 left-0 flex items-center  justify-center z-50`} ref={modalRef}>
      <div className="modal-overlay absolute w-screen h-full bg-black opacity-50"></div>
      <div className="modal-container bg-white max-h-screen90 w-11/12 max-w-md mx-auto shadow-lg z-50 overflow-y-auto">
        <div className="modal-content pt-12 text-left px-6 items-center justify-center">
          <ReactLoading className="mx-auto" type={"spinningBubbles"} color={loadingIconColor || "#000"} height={56} width={56}/>
          {renderLoadingBody()}
        </div>
      </div>
    </div>
  );
}

export default SLoadingModal;
