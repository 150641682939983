import axios from 'axios'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {IMapStateToProps} from "../types";
import {ICollection} from "../dnweb3/types";
import _ from 'lodash';
import {useSelector} from "react-redux";

export interface ICountDownValue {
  formatted: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
  }
  completed: any;
}

interface IUseBuyNFT {
  project: any
  countDownValue?: ICountDownValue
}

const useBuyNFT = ({
                     countDownValue
                   }: IUseBuyNFT) => {
  const {
    authUser: {
      networkId: curNetworkId,
      address,
      loggedIn,
      web3,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const renderCountDown = useCallback((pValue: ICountDownValue) => {
    const value = pValue ? (pValue as ICountDownValue) : countDownValue;
    let wrapCls = '';
    if (value && value.formatted.days == '00') {
      wrapCls += ' text-live';
    }
    return value && (
      <div className={`flex max-w-64 w-full justify-between font-bold ${wrapCls}`}>
        {value.formatted.days !== '00'? (
          <>
            <div className="hour flex flex-col items-center">
              <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.days}</p>
              <p className="font-inter text-base leading-7">Days</p>
            </div>
            <span className="counter-time font-suisse font-bold text-3xl">:</span>
          </>
        ) : ''}
        <div className="hour flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.hours}</p>
          <p className="font-inter text-base leading-7">Hours</p>
        </div>
        <span className="counter-time font-suisse font-bold text-3xl">:</span>
        <div className="minute flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.minutes || 0}</p>
          <p className="font-inter text-base leading-7">Mins</p>
        </div>
        <span className="counter-time font-suisse font-bold text-3xl">:</span>
        <div className="second flex flex-col items-center">
          <p className="counter-time font-suisse font-bold text-3xl">{value.formatted.seconds || 0}</p>
          <p className="font-inter text-base leading-7">Secs</p>
        </div>
      </div>
    );
  }, [countDownValue]);

  return {
    renderCountDown
  };
}

export default useBuyNFT;
