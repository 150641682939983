export const SET_TICKET_DATA = "SET_TICKET_DATA";
export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const INCREMENT_SLOW_COUNTER = "INCREMENT_SLOW_COUNTER";
export const INCREMENT_FAST_COUNTER = "INCREMENT_FAST_COUNTER";
export const INCREMENT_REFRESH_TICK = "INCREMENT_REFRESH_TICK";

export const setTicketData = (data) => ({
  type: SET_TICKET_DATA,
  payload: data
});

export const setSearchKeyword = (keyword) => ({
  type: SET_SEARCH_KEYWORD,
  payload: keyword || ''
});

export const incrementSlowCounter = () => ({
  type: INCREMENT_SLOW_COUNTER,
});

export const incrementFastCounter = () => ({
  type: INCREMENT_FAST_COUNTER,
});

export const incrementRefreshTick = () => ({
  type: INCREMENT_REFRESH_TICK,
});



