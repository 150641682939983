import React, {HTMLProps} from 'react'

import Button, {IButton} from "../Button";

const ButtonArrow = (props: IButton) => {
  const {children, size = "normal", className, onClick, disable, arrowType} = props;
  return (
    <Button {...{...props, className: `max-w-max ${props.className}`}} >
      {children}
      {arrowType == '1' ?
          <svg className="ml-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.62109 12.3025L12.6211 2.30249" stroke="#060606" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.62109 2.30249H12.6211V12.3025" stroke="#060606" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        :
        <svg className="hidden md:block " width="37" height="9" viewBox="0 0 37 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.5L36 4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M32 0.5L36 4.5L32 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>}
    </Button>
  )
}

export default ButtonArrow;
