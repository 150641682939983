import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ICollection } from "../../dnweb3/types";
import {
  DT_MIN_START_DATE,
  dtInTz,
  dtNiceStartDropDate,
  dtTodayInServerTz,
  dtTzStr,
  nf_i
} from "../../dnweb3/helpers/utilities";
import ButtonArrow from "../ButtonArrow";
import { EnumCollectionLayoutTypes } from "../Collection/Collection";
import Button from "../Button";
import Countdown from "react-countdown";
import { ICountDownValue } from "../../hooks/useBuyNFT";
import 'moment-timezone';
import useAuth from "../../hooks/useAuth";
import LinkButton from "../LinkButton";
import { EnumSaleStep } from "../../dnweb3/helpers/types";
import { TZ_NEW_YORK } from "../../config";
import LazyLoad from 'react-lazyload';

interface ICollectionProps {
  item: ICollection,
  layoutStyle?: EnumCollectionLayoutTypes
}

export const isProjectLive = (item: ICollection) => {
  // Status checking
  let live = false;
  if (item.is_upcoming) {
    return false;
  }
  return true;
};

export const isProjectInValidDate = (item: ICollection) => {
  // Date checking
  const today = dtTodayInServerTz();
  const startDate = dtTzStr(item.start_drop_date || DT_MIN_START_DATE);
  if (today.isSameOrAfter(startDate)) {
    return true;
  }
  return false;
}

export const isSoldOut = (project?: ICollection) => {
  if (!project) return false;
  return project.minted_count > 0 && project.total_count == project.minted_count;
}

export const isMintable = (project: ICollection) => {
  const mintable = isAnySaleActive(project) && project.is_mintable;
  return mintable;
}


export const isAnySaleActive = (project: ICollection) => {
  return project.sc_presale_active || project.sc_sale_active || project.sc_whitelist_active;
}

export const isTicketSale = (project: any, selectedStep?: EnumSaleStep) => {
  return getSaleStep(project, selectedStep) == EnumSaleStep.TicketSale;
}

export const isWhitelistSale = (project: any, selectedStep?: EnumSaleStep) => {
  return getSaleStep(project, selectedStep) == EnumSaleStep.WhitelistSale;
}

export const getSaleStep = (project: any, selectedStep?: EnumSaleStep): EnumSaleStep => {
  const {sc_sale_active, sc_whitelist_active, sc_presale_active, whitelistBuyOnce} = project;

  if (!selectedStep) {
    if (sc_sale_active) return EnumSaleStep.NormalSale;
    if (sc_whitelist_active) return EnumSaleStep.WhitelistSale;
    if (sc_presale_active) return EnumSaleStep.TicketSale;
  } else {
    if (selectedStep == EnumSaleStep.TicketSale) {
      if (sc_presale_active) return EnumSaleStep.TicketSale;
    } else if (selectedStep == EnumSaleStep.WhitelistSale) {
      if (sc_whitelist_active) return EnumSaleStep.WhitelistSale;
    } else if (selectedStep == EnumSaleStep.NormalSale) {
      if (sc_sale_active) return EnumSaleStep.NormalSale;
    }
  }

  return EnumSaleStep.Disabled;
}

export const statusStr = (project: ICollection, isFront?: boolean) => {
  const {is_upcoming, is_mintable, is_live, is_tbd, sc_sale_active, sc_presale_active, sc_whitelist_active} = project;
  let statusStr = '-';
  if (is_upcoming && !is_mintable) {
    statusStr = 'Upcoming';
  } else {
    if (isSoldOut(project)) return 'Sold Out';
    else {
      if (is_mintable) {
        statusStr = 'Public Sale';
        if (isFront) return statusStr;
        if (isWhitelistSale(project))
          statusStr = 'Whitelist Sale';
        else if (isTicketSale(project))
          statusStr = 'Mint Ticket';
      } else
        statusStr = 'Upcoming';
    }
  }
  return statusStr;
}


export const renderCountDown = (pValue: ICountDownValue) => {
  const value = pValue as ICountDownValue;
  let wrapCls = '';
  if (value.formatted.days == '00') {
    wrapCls += ' text-live';
  }
  return value && (
    <div className={`flex max-w-64 w-full justify-between ${wrapCls}`}>
      {value.formatted.days !== '00' ? <>
        <span className="counter-time">{(value.formatted.days)}</span>
        <span className="">d &nbsp;</span>
      </> : ''}

      <span className="counter-time">{(value.formatted.hours)}</span>
      <span className="">h &nbsp;</span>

      <span className="counter-time">{(value.formatted.minutes || 0)}</span>
      <span className="">m &nbsp;</span>

      {/*value.formatted.days == '0' && */ <>
        <span className="counter-time">{(value.formatted.seconds || 0)}</span>
        <span className="">s</span>
      </>}
    </div>
  );
}

const CollectionSummary = (props: ICollectionProps) => {
  const {item: itemProp, layoutStyle} = props;
  const [item, setItem] = useState(itemProp);

  const history = useHistory();
  const {isAdmin} = useAuth();

  useEffect(() => {
    setItem(itemProp);
  }, [itemProp]);

  const isLive = useMemo(() => isProjectLive(item), [item]);


  const onCompleteCountdown = (arg: any) => {
    // console.log('completed:', arg, item.name);
  }

  const renderContent = useCallback(() => {
    let html = null;
    switch (layoutStyle) {
      case EnumCollectionLayoutTypes.FEATURED:
        html = (
          <>
            <LazyLoad>
              <img className="hidden md:block border-gray-300 border-2 bg-white rounded-full object-cover" style={{width: 60, height: 60}} src={item.logo_url} width={60} height={60}/>
            </LazyLoad>
            <h3 className="style1 mt-6"><Link to={`/collection/${item.name_uid}`}>{item.name}</Link></h3>
            <h4 className="style1 mt-2">{item.tagline}</h4>
            <div className="border-b border-white my-8" style={{width: 20}}></div>

            <div className="flex md:block justify-between mt-10">
              <div>
                {
                  item.mint_price > 0 ?
                    <h5 className="style1">{nf_i(item.total_count)} <span className="px-2">•</span> {item.mint_price} ETH</h5>
                    :
                    <h5 className="style1">{nf_i(item.total_count)} </h5>
                }
                <h6 className="style1 mt-4"></h6>
                {
                  item.is_upcoming && (item.start_drop_date ? <div className="font-space-mono text-live mt-4">{dtNiceStartDropDate(item.start_drop_date)}</div> :
                    <div className="font-space-mono text-live mt-4 uppercase"><span className="style1 font-suisse pr-2">•</span>{statusStr(item)}</div>)
                }
                {item.is_live && <div className="font-space-mono text-live mt-4"><span className="style1 font-suisse pr-2">•</span>{statusStr(item)}</div>}
              </div>
              <ButtonArrow className="btn-default flex items-center self-start mt-0 md:mt-12"
                           btnType={'link'}
                           btnLinkUrl={`/collection/${item.name_uid}`}
                           onClick={() => history.push(`/collection/${item.name_uid}`)}
              >
                <span className="md:mr-4 font-suisse text-4 font-normal leading-4 font-normal">View</span>
              </ButtonArrow>
            </div>
          </>
        );
        break;
      case EnumCollectionLayoutTypes.NORMAL:
      case EnumCollectionLayoutTypes.SOLD_OUT:
        const mObj = dtInTz(item.start_drop_date, TZ_NEW_YORK, TZ_NEW_YORK);

        html = (
          <Link to={`/collection/${item.name_uid}`}>
            {isLive && <div className="status-box live absolute top-0 right-0 mt-8 mr-8 font-space-mono text-live text-3 leading-3 border-0 bg-white"><span className="transform scale-150 pr-1.75">•</span>{statusStr(item)}</div>}
            <LazyLoad>
              <img className="logo border-3 mx-auto border-white rounded-full" src={item.logo_url} width={90} height={90}/>
            </LazyLoad>
            {isAdmin && <LinkButton className="absolute btn-white gray bg-white top-0 right-0  mr-8 mt-8" linkTo={`/edit-collection/${item.project_id}`}>Edit</LinkButton>}
            <h3 className=""><Link to={`/collection/${item.name_uid}`}>{item.name}</Link></h3>
            <h4 className="mt-3.5">{item.tagline}</h4>

            <div className="absolute bottom-0 font-suisse left-0 w-full flex items-center border-t border-black2 py-6.5 px-4 overflow-hidden" style={{maxHeight: 100}}>
              <div className="leading-5 text-left mr-4 lg:mr-2 xl:mr-6 2xl:mr-8">
                <label className="text-3_5 text-gray2">{item.is_live ? 'Minted' : 'Items'}</label>
                <div className="text-3_75 mt-2 text-black2">
                  {item.is_live && <><span className="font-bold"> {nf_i(item.minted_count)}</span></>}
                  {!item.is_live && <span className="text-gray-500">{nf_i(item.total_count)}</span>}</div>
              </div>

              <div className="leading-5 text-left mr-4 lg:mr-2 xl:mr-6 2xl:mr-8 ">
                <label className="text-3_5 text-gray2 mb-2">Price</label>
                {
                  item.mint_price > 0 ?
                    <div className="text-3_75 mt-2"><span className="font-bold">{item.mint_price}</span> Ξ</div>
                    :
                    <div className="text-3_75 mt-2"><span className=""> {item.mint_price == -1 ? 0 : 'TBA'} </span></div>
                }
              </div>

              {!isLive && <div className="leading-5 text-left">
                <label className="text-3_5 text-gray2 mb-2">Launching in</label>
                <div className="text-3_75 mt-2 font-bold">
                  {item.is_tbd ? 'To be announced' : <Countdown
                    date={mObj ? mObj.format() : item.start_drop_date}
                    zeroPadDays={2}
                    zeroPadTime={2}
                    renderer={renderCountDown}
                    onComplete={onCompleteCountdown}
                  />}
                </div>
              </div>}
              {isLive && <div className="hidden md:block lg:hidden xl:block ml-auto">
                <Button className="btn-default btn-white black flex items-center"
                        btnType={'link'}
                        btnLinkUrl={`/collection/${item.name_uid}`}
                >
                  <span className="font-suisse text-4 font-normal leading-4 font-normal hidden 3xl:inline ">{isSoldOut(item) ? 'View' : 'Catch the drop'}</span>
                  <span className="font-suisse text-4 font-normal leading-4 font-normal hidden md:inline 3xl:hidden ">View</span>
                </Button>
              </div>}
            </div>
          </Link>
        );
        break;
      default:
        // Live listing on home page
        html = (
          <>
            <h3 className="style1 mt-8">{item.name}</h3>
            <h4 className="style1 mt-2">{item.tagline}</h4>
            <div className="flex justify-between mt-10">
              <div>
                {
                  item.mint_price > 0 ?
                    <h5 className="style1">
                      {isAnySaleActive(item) && item.minted_count > 0 && <>{nf_i(item.minted_count)}</>} {/*<span className="text-gray-500">{nf_i(item.total_count)}</span>*/}
                      {!isAnySaleActive(item) && item.total_count > 0 && <span className="text-gray-500">{nf_i(item.total_count)}</span>}
                      {((isAnySaleActive(item) && item.minted_count > 0) || (!isAnySaleActive(item) && item.total_count > 0)) && <span className="px-2">•</span>}
                      <> {item.mint_price} ETH</>
                    </h5>
                    :
                    <h5 className="style1">
                      {isAnySaleActive(item) && item.minted_count > 0 && <>{nf_i(item.minted_count)}</>} {/*<span className="text-gray-500">{nf_i(item.total_count)}</span>*/}
                      {!isAnySaleActive(item) && item.total_count > 0 && <span className="text-gray-500">{nf_i(item.total_count)}</span>}
                    </h5>
                }
                {item.is_live && <div className="font-space-mono text-live mt-4 uppercase"><span className="style1 font-suisse pr-2">•</span>{statusStr(item)}</div>}
                {
                  item.is_upcoming && (item.start_drop_date && !item.is_tbd ? <div className="font-space-mono text-live mt-4">{dtNiceStartDropDate(item.start_drop_date)}</div> :
                    <div className="font-space-mono text-live mt-4 uppercase"><span className="style1 font-suisse pr-2">•</span>{statusStr(item)}</div>)
                }
              </div>
              <ButtonArrow className="btn-default flex items-center self-start lg:ml-6 xl:ml-16 max-w-32"
                           btnType={'link'}
                           btnLinkUrl={`/collection/${item.name_uid}`}
              >
                <span className="md:mr-4 font-suisse text-4 font-normal leading-4 font-normal">View</span>
              </ButtonArrow>
            </div>
          </>
        );
        break;
    }
    return html;
  }, [item, layoutStyle]);

  return (
    <div className={`collection-summary ${layoutStyle == EnumCollectionLayoutTypes.FEATURED ? 'xl:mt-72 3xl:mt-60' : ''}`}>
      {renderContent()}
    </div>
  );
}

export default CollectionSummary;
