import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

import "./Project.scss";

import useCollectionDetail from "../../hooks/useCollectionDetail";
import ButtonArrow from "../../components/ButtonArrow";
import SLoadingWrap from "../../components/SLoadingWrap";
import LinkButton from "../../components/LinkButton";
import useAuth from "../../hooks/useAuth";
import useInterval, {TimerDelays} from "../../dnweb3/hooks/useInterval";
import {getSaleStep, isSoldOut} from "../../components/CollectionSummary/CollectionSummary";
import {ICollection, ISCData} from "../../dnweb3/types";
import useGraphCustomData from "../../hooks/useGraphCustomData";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {getMergedCollectionDataBySubGraphData} from "../../hooks/useCollections";
import useSCOwner from "../../hooks/useSCOwner";
import ProjectOwnerPanel from "../../components/ProjectOwnerPanel/ProjectOwnerPanel";
import NotFound from "../../components/NotFound";
import moment from "moment";
import {TZ_NEW_YORK} from "../../config";
import {EnumSaleStep} from "../../dnweb3/helpers/types";
import BuyNFT from "../../components/BuyNFT/BuyNFT";
// import ImgCuratedBadge from '../../assets/images/CuratedSticker_32x.png';
import ImgArrowDown from '../../assets/images/icon/chevron-down.svg';
import {safeNum} from "../../dnweb3/helpers/utilities";

export const SocialLinksAvailable = [
  'os',
  'discord',
  'twitter',
  'instagram',
  'tt',
  'globe',
];

const getAnnounceDateTitle = (project_id:any) => {
  switch (safeNum(project_id)) {
    case 37:
      return `OG List Sale`;
    case 38:
      return `Early Mint`;
    default:return 'Whitelist Sale';

  }
}

const getTabTitle = (project_id:any) => {
  switch (safeNum(project_id)) {
    case 37:
      return `OG List`;   // Mavion
    case 38:
      return `Early Mint`;
    default:return 'Whitelist';

  }
}

const Project = () => {
  const params: any = useParams();
  const {isAdmin} = useAuth();
  const {
    authUser: {
      address,
      loggedIn,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const [firstItemLoaded, setFirstItemLoaded] = useState(false);
  const {item, loading: itemLoading, fetch: fetchProject, error: itemError} = useCollectionDetail({name_uid: params.name_uid || ''});
  const [project, setProject] = useState<ICollection>();
  const [overviewCollapsed, setOverviewCollapsed] = useState(true);
  const [selectedSaleStep, setSelectedSaleStep] = useState(EnumSaleStep.Disabled);
  const [userSelectTab, setUserSelectTab] = useState(false);

  useEffect(() => {
    if (item) {
      setProject(item);
      setFirstItemLoaded(true);
    }
  }, [item]);

  useEffect(() => {
    if (!userSelectTab && project) {
      const projectSaleStep = getSaleStep(project);
      setSelectedSaleStep(projectSaleStep !== EnumSaleStep.Disabled? projectSaleStep : EnumSaleStep.TicketSale);
    }
  }, [userSelectTab, project]);

  const {owner: isOwner} = useSCOwner(project?.sc_address);
  // SubGraph related state hooks
  const {loading: loadingCustomGraph, fetchGraphCustomData} = useGraphCustomData(project?.sc_subgraph_url, project?.sc_version);

  const updateProjectDataBySubGraph = async () => {
    if (project && project?.sc_subgraph_url && !project.is_sold_out) {
      return fetchGraphCustomData().then(res => {
        if (res) {
          setProject(prevState => {
            return getMergedCollectionDataBySubGraphData(prevState, (res as ISCData));
          });
        }
      });
    }
  };

  useEffect(() => {
    if (project?.sc_subgraph_url && !project.is_sold_out) {
      updateProjectDataBySubGraph().then();
    }
  }, [address, project?.sc_subgraph_url]);

  /**
   * Timer: To update real-time data of project and subgraph.
   * */
  useInterval(async () => {
    const p = await fetchProject(true);
    if (p && p.sc_subgraph_url) {
      const scData = await fetchGraphCustomData();
      setProject((prevState: any) => ({...prevState, ...p, ...scData}));
    }
  }, isSoldOut(project) ? null : (project?.is_live ? TimerDelays.FAST_INTERVAL_60 : TimerDelays.FAST_INTERVAL_60), false);

  const HtmlToReactParser = require('html-to-react').Parser;
  const getHtmlParsed = (strHtml: string) => {
    const htmlToReactParser = new HtmlToReactParser();
    return htmlToReactParser.parse(strHtml);
  };

  const onOverviewCollapse = (e: any) => {
    setOverviewCollapsed(!overviewCollapsed);
  };

  const getFormattedDate = (date?: any, timeZone?: string) => {
    let strFormatted = 'To be announced';
    if (!timeZone) {
      timeZone = TZ_NEW_YORK;
    }
    let dateObj = null;
    if (date && (dateObj = moment(date))) {
      const dtFormat = "YYYY-MM-DDTHH:mm:ss.SSS";
      const dateWithoutZone = dateObj.format(dtFormat);
      const nowObj = moment();
      if (moment.tz(dateWithoutZone, timeZone).isBefore(moment.tz(nowObj, timeZone)))
        strFormatted = ' - ';
      else
        strFormatted = moment.tz(dateWithoutZone, timeZone).format("MMMM D, YYYY @ h:mm a") + ' EST';
    } else {
      strFormatted = 'To be announced';
    }
    return strFormatted;
  };

  return (
    <div className="page-body text-black2 mb-20">
      {itemError === 404 ? <NotFound title="Project not found."/> : <>
        <div className="mt-9 relative">
          {project && <div className="banner filter xl:drop-shadow-banner">
            <img src={project?.banner_url} alt={project?.name} height={450}/>
          </div>}
          {project?.logo_url && <div className="flex w-full justify-center absolute bottom-0 -mb-16">
            <img
              className={`${project.name_uid == 'aivatar' ? `border-none` : `border-2`} border-gray-300 rounded-full object-cover max-w-32 max-h-32`}
              src={project?.logo_url}/>
          </div>}
          {isAdmin && <LinkButton className="absolute btn-white gray bg-white top-0 right-0  mr-8 mt-8"
                                  linkTo={`/edit-collection/${project?.project_id}`}>Edit</LinkButton>}
        </div>
        <div className="container pt-12 lg:pt-16">
          {project &&
            <SLoadingWrap className="flex flex-wrap lg:flex-nowrap 3xl:px-30 mt-16 md:mt-25" maskCls="p" loading={!firstItemLoaded && itemLoading}>
              <div className="lg:w-7/12">
                <h1 className={`base text-left`}>
                  <div className="inline-block align-middle border-b-4 border-black pl-12 md:pl-25 mr-8 md:mr-12"></div>
                  {project?.name}
                </h1>
                <p className="base mt-8">{project?.tagline}</p>
                <h3 className="style2 mt-12 cursor-pointer" onClick={onOverviewCollapse}>Overview <div
                  className={`inline-block lg:hidden w-4 align-middle ${overviewCollapsed ? 'arrow-collapsed' : ''}`}><img className={`w-full`}
                                                                                                                           src={ImgArrowDown}/></div>
                </h3>
                <p
                  className={`${overviewCollapsed ? 'hidden' : 'block'} lg:block base mt-4 mb-4 whitespace-pre-wrap`}>{getHtmlParsed(project?.description ?? '')}</p>

                {project?.sc_address && (
                  <ButtonArrow
                    className="flex font-suisee btn-white black my-12"
                    external
                    arrowType='1'
                    btnType={'link'}
                    btnLinkUrl={`https://etherscan.io/address/${project?.sc_address}`}
                  >
                    View smart contract
                  </ButtonArrow>
                )}
              </div>
              <div className="right-pane xs:w-full lg:w-5/12 ml-0 lg:ml-10 xl:ml-20">
                {(isOwner && project) && <ProjectOwnerPanel
                  project={project}
                  setProject={setProject}
                  fetchProject={fetchProject}
                  fetchGraphCustomData={fetchGraphCustomData}
                  updateProjectDataBySubGraph={updateProjectDataBySubGraph}
                />}

                {project
                  ?
                  <>
                    <div className={`date-list relative z-10 shadow-buy w-full border border-black2`}>
                      <div className={`has-border date-name text-3_75 font-inter font-medium`}>Mint Ticket Sale:</div>
                      <div
                        className={`has-border date-value font-inter font-semibold`}>{project.sc_presale_active ? "Minting Now" : getFormattedDate(project.presale_date, TZ_NEW_YORK)}</div>
                      <div className={`has-border date-name text-3_75 font-inter font-medium`}>{getAnnounceDateTitle(project.project_id)}: </div>
                      <div
                        className={`has-border date-value font-inter font-semibold`}>{project.sc_whitelist_active ? "Minting Now" : getFormattedDate(project.whitelistsale_date, TZ_NEW_YORK)}</div>
                      {(project.project_id == 37) && <>
                        <div className={`has-border date-name text-3_75 font-inter font-medium`}> Rainbow List: </div>
                        <div
                          className={`has-border date-value font-inter font-semibold`}> March 2, 2022 @ 5:00 pm EST</div>
                      </>}
                      <div className={`date-name text-3_75 font-inter font-medium`}>Public Sale:</div>
                      <div
                        className={`date-value font-inter font-semibold`}>{project.sc_sale_active ? "Minting Now" : getFormattedDate(project.start_drop_date, TZ_NEW_YORK)}</div>
                    </div>
                  </>
                  : <></>
                }

                <div className={`buy-wrapper shadow-buy border border-black2`}>
                  <div className={`block tab-container`}>
                    <ul className={`font-suisse font-semibold`}>
                      <li className={selectedSaleStep == EnumSaleStep.TicketSale ? `selected` : ''} onClick={() => {
                        setUserSelectTab(true);
                        setSelectedSaleStep(EnumSaleStep.TicketSale);
                      }}>Mint Ticket
                      </li>
                      <li className={selectedSaleStep == EnumSaleStep.WhitelistSale ? `selected` : ''} onClick={() => {
                        setUserSelectTab(true);
                        setSelectedSaleStep(EnumSaleStep.WhitelistSale);
                      }}>{getTabTitle(project.project_id)}
                      </li>
                      <li className={selectedSaleStep == EnumSaleStep.NormalSale ? `selected` : ''} onClick={() => {
                        setUserSelectTab(true);
                        setSelectedSaleStep(EnumSaleStep.NormalSale);
                      }}>Public
                      </li>
                    </ul>
                  </div>
                  {project && <BuyNFT
                    className={`w-full`}
                    project={project}
                    setProject={setProject}
                    selectedSaleStep={selectedSaleStep}
                  />}
                </div>

                <h3 className="style2 mt-14">Links</h3>
                <div className="border-b border-black2 mt-4 mb-8"></div>

                {project?.website && <div className="flex items-center font-inter text-4 leading-8  mb-3">
                  <img src={require(`../../assets/images/social-icons/globe.png`).default} width={18} height={18}/>
                  <a className="ml-3 " href={project?.website} target="_blank">{project?.website}</a>
                </div>}

                {project?.social_links && SocialLinksAvailable.map((key: string) => project.social_links[key] &&
                  <div key={key} className="flex items-center font-inter text-4 leading-8 mb-3">
                    <img src={require(`../../assets/images/social-icons/${key}.png`).default} width={18} height={18}/>
                    <a className="ml-3" href={project.social_links[key]} target="_blank">{project.social_links[key]}</a>
                  </div>
                )}
              </div>
            </SLoadingWrap>}
        </div>
      </>}
    </div>
  );
}

export default Project;
