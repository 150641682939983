import React, {useState} from 'react';

import useCollections from "../../hooks/useCollections";
import {IPaginationParam} from "../../dnweb3/types";
import CollectionCard, {EnumCollectionLayoutTypes} from "../../components/Collection/CollectionCard";
import SLoadingWrap from "../SLoadingWrap";

const SoldOutCollections = () => {

  const [fetchParams, setFetchParams] = useState<IPaginationParam>({
    sortBy: "start_drop_date",
    sortDir: 'desc',
    page: 1,
    perPage: 12,
    filters: {is_sold_out: 1, is_listing: 1},
  });
  const {items, hasMore, loading:collectionLoading, pagination, fetchItems} = useCollections(fetchParams);

  const fetchNext = () => {
    setFetchParams(prevState => ({...prevState, page: pagination.currentPage + 1}));
  };

  return (
      <div className="container" >
        <h2 className="style-icon mt-25 md:mt-25 lg:mt-31">Sold out collections</h2>
        <SLoadingWrap className="items flex flex-wrap -mx-3 xl:-mx-7 min-h-60" loading={collectionLoading} maskCls={'parent'}>
          {items && items.length < 1 ? <p className="px-3 xl:px-7">There are no upcoming collections.</p> : <>
            {items && items.map((item, ind) => <div key={item.project_id} className="md:w-6/12 lg:w-4/12 px-3 xl:px-7 mb-8 md:mb-6 xl:mb-14">
                <CollectionCard key={item.project_id} item={item} className="" layoutStyle={EnumCollectionLayoutTypes.SOLD_OUT}/>
              </div>
            )}
          </>}
        </SLoadingWrap>
        {hasMore && <h2 className={`style-icon mx-auto my-10 md:my-18 text-center ${collectionLoading? 'no-icon' : ''}`}>
          {collectionLoading ? <span>Loading...</span>
            : <span className="cursor-pointer" onClick={fetchNext}>Load <span className="italic">more</span></span>
          }
        </h2>
        }
      </div>
  );
}

export default SoldOutCollections;
