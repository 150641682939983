import {useSelector} from "react-redux";
import {IMapStateToProps} from "../types";
import {useEffect, useState} from "react";
import {getContractDS} from "../dnweb3/controllers/dropspace";
import {isStrEqual} from "../dnweb3/helpers/utilities";

export default function useSCOwner(sc_address?: string) {
  const {
    authUser: {
      web3,
      address,
      loggedIn,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const [owner, setOwner] = useState(false);

  useEffect(() => {
    if (web3 && loggedIn && address && sc_address) {
      getContractDS(web3, sc_address).getOwner().then(res => {
        setOwner(isStrEqual(address, res));
      });
    } else {
      setOwner(false);
    }
  }, [web3, loggedIn, address, sc_address])

  return {
    owner,
    isOwner: owner
  };
}
