import WalletLink from 'walletlink';
import { APP_NETWORK_ID } from '../config';
import Web3Util from '../helpers/Web3Util';

const APP_NAME = 'dropspace';
const APP_LOGO_URL = 'https://dropspace.art/logo192.png';

const ConnectToCoinbase = async (providerName: string) => {
  let provider = null;

  // Initialize WalletLink
  const walletLink = new WalletLink({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: false,
  });

  provider = walletLink.makeWeb3Provider(Web3Util.getInfuraAPIEndpoint(APP_NETWORK_ID), APP_NETWORK_ID);
  await provider.request({ method: 'eth_requestAccounts' });

  return provider;
};

export default ConnectToCoinbase;
