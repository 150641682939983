/**
 * Backend paginated result
 */
export interface IPaginationParam {
  page?: number
  perPage?: number
  sortBy?: string
  sortDir?: string
  filters?: object
}

export interface IPaginationResult {
  totalRows: number
  totalPages: number
  currentPage: number
  perPage: number
  hasMore: boolean
}

export interface IResultWithPagination {
  pagination: IPaginationResult
  data: Array<any>
}

export const InitialPagination: IPaginationResult = {
  currentPage: 1,
  hasMore: false,
  perPage: 10,
  totalPages: 0,
  totalRows: 0
};

/**
 * Backend API response
 */
export interface IResponse {
  code: number
  status: string
  message: any
}


/**
 * ------------------------------------------------------------------------
 * Model types
 * ------------------------------------------------------------------------
 */

export interface ISCData {
  total_count: number
  minted_count: number
  presale_minted_count: number
  sale_minted_count: number
  mint_price: number
  mint_limit: number
  sc_sale_active: boolean
  sc_presale_active: boolean
  sc_whitelist_active: boolean
  whitelistBuyOnce?: boolean
  ticketAddress?: string
  tickets?: Array<any>

  reserved?: number
  withdrawalWallet?: string

  // Calculated values
  is_mintable?: boolean
  is_sold_out?: boolean   // set from backend API and updated from subgraph API
  localTimestamp?: number // Used to check the status to be updated or not. Bi-directional update flag.
}

export const InitialSCData: ISCData = {
  is_mintable: false, localTimestamp: 0, mint_limit: 0, mint_price: 0, minted_count: 0, presale_minted_count: 0, sale_minted_count: 0, sc_presale_active: false, sc_sale_active: false, sc_whitelist_active: false, tickets: undefined, total_count: 0
}

export interface ICollection extends ISCData {
  project_id: number
  name: string
  name_uid: string
  tagline: string
  description?: string

  sc_address?: string
  sc_version?: number
  ticketAddress?: string
  sc_ticket_subgraph_url?: string
  sc_subgraph_url?: string
  presale_date?: string
  whitelistsale_date?: string
  sale_date?: string
  presale_whitelist?: string
  start_drop_date?: string
  website?: string
  social_links?: any
  status?: any
  featured?: boolean
  owner?: any
  logo_url?: string
  banner_url?: string
  card_url?: string
  featured_url?: string

  is_live?: boolean
  is_upcoming?: boolean
  is_homepage?: boolean
  is_tbd?: boolean
  is_curated?: boolean
  sc?: any
}

export interface IFile {
  clean_file_name?: any
  crop_height?: any
  crop_width?: any
  crop_x?: any
  crop_y?: any
  data?: any
  file_id?: any
  file_name?: any
  is_main?: any
  org_path?: any
  path?: any
  size?: any
  type?: any
  updated_on?: any
  x?: any
  y?: any
}

export interface IAddress {
  address?: string;
  name?: string;
  avatar_path?: string;
  avatar_org_path?: string;
  avatar_type?: string;
  social_links?: any;
  last_login?: string;
  updated_on?: string;
  created_on?: string;
}

export enum EnumUserRole {
  NORMAL = 0,       // In db
  ADMIN = 1,        // In db
}

export enum EnumProjectStatus {
  DISABLED = 0,     // In db
  ACTIVE = 1,       // In db
  PENDING_APPROVE = 2,         // 3 or 2
  ARCHIVED = 3,  // 3 or 2
  TEST = 4,     // 5 or 4
  PRE_SALE = 5,     // 5 or 4
}

export enum EnumFileCat {
  LOGO = 1,
  BANNER = 2,
  CARD = 3,
  FEATURED = 4,
}



