import React, {useEffect, useState} from 'react';
import useCollections, {EnumPostProcess} from "../../hooks/useCollections";
import {IPaginationParam} from "../../dnweb3/types";
import Collection from "../Collection/Collection";
import useWindowWidth from "../../hooks/useWindowWidth";
import SLoadingWrap from "../SLoadingWrap";
import {dtInTz} from "../../dnweb3/helpers/utilities";
import {TZ_NEW_YORK} from "../../config";
import moment from "moment";

interface ILiveCollections {
  setNewsItem?: any
}

const LiveCollections = ({
                           setNewsItem
                         }: ILiveCollections) => {

  const {vw} = useWindowWidth();
  const twoColumnView = vw >= 1280 || vw < 768;

  const [fetchParams, setFetchParams] = useState<IPaginationParam>({
    sortBy: "start_drop_date",
    sortDir: 'desc',
    page: 1,
    perPage: 10,
    filters: {is_sold_out: 0, is_homepage: 1, is_listing: 1},
  });
  const {items, loading} = useCollections(fetchParams, EnumPostProcess.OVERRIDE);

  useEffect(() => {
    if (items && items.length > 0) {
      let selected = false;
      for (let ind in items) {
        if (items[ind].project_id && items[ind].start_drop_date) {
          const mObjNy = dtInTz(items[ind].start_drop_date, TZ_NEW_YORK);
          const mTodayNy = moment().tz(TZ_NEW_YORK);
          if (mTodayNy.isBefore(mObjNy)) {
            selected = true;
            setNewsItem(items[ind]);
            break;
          }
        }
      }
      if (!selected) {
        setNewsItem(null);
      }
    } else {
      setNewsItem(null);
    }
  }, [items, setNewsItem]);

  return (
    <SLoadingWrap className="lives-wrap flex relative flex-wrap justify-between md:mt-10 lg:mt-6 xl:mt-0 lg:-mx-5 lg:-mx-7 xl:mx-0" loading={loading} maskCls={'p'}>
      {twoColumnView && items.map((item, ind) => (
        <div key={item.project_id}
             className={`${ind == 1 ? 'mt-25 lg:mt-0 xl:-mt-20' : 'mt-25 lg:mt-25 xl:mt-16'} ${ind % 2 == 0 ? "lg:w-6/12 xl:w-6/12 lg:pr-7 xl:pr-12 3xl:pr-0" : "lg:w-6/12 xl:w-5/12 lg:pl-7 xl:pl-0"} 3xl:max-w-xl`}>
          <Collection item={item} className=""/>
          <div className="border-b border-white border-opacity-25 my-8"></div>
        </div>
      ))}
      {!twoColumnView && <>
        <div className="md:px-5 lg:px-7 md:w-6/12">
          {items.map((item, ind) => ind % 2 == 0 && <div key={item.project_id} className="md:mt-20 lg:mt-25">
            <Collection item={item} className=""/>
            <div className="border-b border-white border-opacity-25 my-8"></div>
          </div>)}
        </div>
        <div className="md:px-5 lg:px-7 md:w-6/12">
          {items.map((item, ind) => ind % 2 == 1 && <div key={item.project_id} className={`${ind == 1 ? 'mt-0' : 'md:mt-20 lg:mt-25'}`}>
            <Collection item={item} className=""/>
            <div className="border-b border-white border-opacity-25 my-8"></div>
          </div>)}
        </div>
      </>}
    </SLoadingWrap>
  );
}

export default LiveCollections;
