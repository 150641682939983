import {useCallback, useEffect, useState} from "react";
import {request} from 'graphql-request'
import AppGql from "../dnweb3/appollo/AppGql";
import BigNumber from "bignumber.js";
import {bnToDec} from "../dnweb3/helpers/utilities";
import {ISCData} from "../dnweb3/types";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../types";


const useGraphCustomData = (endpoint?: string, version=1) => {
  const {
    authUser: {
      address,
      loggedIn,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getData = useCallback(async () => {
    setError(false);
    if (!endpoint) return;

    const ticketsFilter = (loggedIn && address) ? {owner: address} : null;

    try {
      setLoading(true);
      let res;
      if (ticketsFilter) {
        const variables: any = {skip: 0};
        if (ticketsFilter && 'owner' in ticketsFilter) variables.owner = ticketsFilter.owner;
        res = await request<any>(endpoint, AppGql.GetCustomDataListWithTickets(version), variables);
      } else {
        res = await request<any>(endpoint, AppGql.GetCustomDataList(version), {
          skip: 0
        });
      }

      const success = res && res.dropspaceSales && res.dropspaceSales.length > 0;
      if (success) {
        const ret: ISCData = {mint_limit: 0, mint_price: 0, minted_count: 0, presale_minted_count: 0, sale_minted_count: 0, sc_presale_active: false, sc_sale_active: false, sc_whitelist_active: false, total_count: 0};
        const dropspaceSale: any = res.dropspaceSales[0];
        ret.mint_price = bnToDec(new BigNumber(dropspaceSale.mintPrice));
        ret.minted_count = parseInt(dropspaceSale.totalSupply) || 0;
        ret.sale_minted_count = parseInt(dropspaceSale.totalSupply) || 0;
        ret.presale_minted_count = parseInt(dropspaceSale.preSale) || 0;
        ret.total_count = parseInt(dropspaceSale.supplyLimit) || 0;
        ret.mint_limit = parseInt(dropspaceSale.mintLimit) || 0;
        ret.sc_sale_active = dropspaceSale.saleActive;
        ret.sc_presale_active = dropspaceSale.preSaleActive;
        ret.sc_whitelist_active = dropspaceSale.whitelistSaleActive;
        ret.whitelistBuyOnce = dropspaceSale.whitelistBuyOnce;  // @since from 2021-11-04. to support the blacklist sale.
        ret.ticketAddress = dropspaceSale.ticketAddress;
        ret.reserved = dropspaceSale.reserved ?? 0;
        ret.withdrawalWallet = dropspaceSale.withdrawalWallet ?? '';

        /*// to do: Dev/test code
        // -------------------------------------------- //
        ret.sc_presale_active = true;
        ret.sc_whitelist_active = true;
        ret.sc_sale_active = true;
        res.tickets = [{id: 1, used:false}];
        // -------------------------------------------- //*/

        ret.is_mintable = (ret.sc_sale_active || ret.sc_presale_active || ret.sc_whitelist_active) && ret.total_count > 0 && ret.minted_count < ret.total_count;
        ret.is_sold_out = (ret.total_count > 0 && ret.total_count == ret.minted_count);
        ret.localTimestamp = new Date().getTime();
        if ('tickets' in res) {
          ret.tickets = res.tickets;
        } else {
          ret.tickets = [];
        }
        return ret;
      } else {
        console.error("Fetching error to get minting data.");
        setError(true);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [endpoint, loggedIn, address]);

  return {loading, error, fetchGraphCustomData: getData}
}

export default useGraphCustomData;
