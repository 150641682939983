import React from "react";
import Switch from "react-switch";

const CRadioComponent = ({
                               field, // { name, value, onChange, onBlur }
                               form, // : {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                               ...props
                             }: any) => {
  const {touched, errors, setFieldValue, handleChange} = form;

  const onChange = (selected:boolean) => {
    setFieldValue(field.name, selected);
  }

  return (
    <>
      <Switch
        checked={field.value == 1 ? true : false}
        onChange={onChange}
        onColor="#c6ccef"
        onHandleColor="#4D5FD5"
        offColor="#ccc"
        handleDiameter={16}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px #4d5fd5)"
        activeBoxShadow="0px 0px 1px 10px #c3bebe22"
        height={22}
        width={40}
        className="react-switch"
        id={field.name}
      />
      {touched[field.name] && errors[field.name] && <div className="help-block errors">{errors[field.name]}</div>}
    </>
  )
};
export default CRadioComponent;
