import React, {useCallback, useEffect, useMemo, useState} from 'react';

import "./BuyNFT.scss";
import Button from "../../components/Button";
import {setLoading, setLoadingType} from "../../redux/auth/actions";
import {LOADING_TYPE_BUYING_DROPSPACE, MSG_NO_CURRENCY} from "../../dnweb3/constants";
import {useDispatch, useSelector} from "react-redux";
import {IMapStateToProps} from "../../types";
import {ICollection, ISCData} from "../../dnweb3/types";
import BigNumber from "bignumber.js";
import {getAppControllers} from "../../dnweb3/controllers";
import {TwitterShareButton} from "react-share";

import ImgSuccess from "../../assets/images/icon/checkout-success.svg";
import ImgTwitter from "../../assets/images/icon/twitter-dark.svg";
import {dtInTz, nf, nf_i, safeNum} from "../../dnweb3/helpers/utilities";
import ImgClose from "../../assets/images/icon/close-dark.svg";

import Countdown from 'react-countdown';
import LinkButton from "../../components/LinkButton";
import {TZ_NEW_YORK} from "../../config";
import _ from 'lodash';
import useBodyScrollbar from "../../hooks/useBodyScrollbar";
import useBuyNFT from "../../hooks/useBuyNFT";
import {getSaleStep, isAnySaleActive, isMintable, isSoldOut, isTicketSale, isWhitelistSale} from "../CollectionSummary/CollectionSummary";
import {getContractDS} from "../../dnweb3/controllers/dropspace";
import useNavbar from "../../hooks/useNavbar";
import useGraphCustomData from "../../hooks/useGraphCustomData";
import {EnumSaleStep} from "../../dnweb3/helpers/types";
import SLoadingWrap from "../SLoadingWrap";
import {getMergedCollectionDataBySubGraphData} from "../../hooks/useCollections";
import AppMsg from "../../dnweb3/helpers/AppMsg";
// import ImgCuratedBadge from "../../assets/images/CuratedSticker_32x.png";

const STEP_SUCCESS = 2;

interface IBuyNFT {
  className?: string
  project: ICollection
  setProject?: any,
  selectedSaleStep: EnumSaleStep,
}


const BuyNFT = ({
                  className,
                  project,
                  setProject,
                  selectedSaleStep
                }: IBuyNFT) => {
  const dispatch = useDispatch();

  const [qtyNFT, setQtyNFT] = useState([
      1, 1, 1, 1
    ]
  );

  const [selectedTicket, setSelectedTicket] = useState<string>('');
  const [step, setStep] = useState<number>(-1);
  // const [step, setStep] = useState<number>(STEP_SUCCESS);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3,
    }
  } = useSelector((state: IMapStateToProps) => state);

  const {loading: loadingCustomGraph, fetchGraphCustomData} = useGraphCustomData(project?.sc_subgraph_url, project?.sc_version);

  const {
    renderCountDown
  } = useBuyNFT({project});

  const {
    renderConnectButton,
    renderAccountModals,
    setVisibleConnectModal
  } = useNavbar(false);
  useBodyScrollbar(step > 0 || loading && loadingType > 0);

  const saleActive = isMintable(project);
  const mintLimit = project.mint_limit || 1;
  const saleStep: EnumSaleStep = getSaleStep(project, selectedSaleStep);

  const checkErrors = useCallback(async () => {
    const saleStep: EnumSaleStep = getSaleStep(project, selectedSaleStep);
    // console.log("checking errors", saleStep, project.sc_sale_active, project.sc_presale_active, project.sc_whitelist_active, project.sc_address, selectedTicket);
    setErrorMsg('');
    if (saleStep != EnumSaleStep.Disabled && loggedIn && address) {
      switch (saleStep) {
        case EnumSaleStep.TicketSale:
          const isAvailable = _.findIndex(project.tickets, {used: false}) >= 0;
          if (!isAvailable)
            setErrorMsg('No valid tickets!');
          else {
            if (!selectedTicket) {
              setErrorMsg('Select ticket below!');
            } else {
              if (!selectedTicket || !project.tickets || _.findIndex(project.tickets, x => x.id == `${selectedTicket}` && !x.used) < 0) {
                setErrorMsg('No valid ticket selected!');
              }
            }
          }
          break;
        case EnumSaleStep.WhitelistSale:
          let isOk = false;
          if (project.sc_address && web3 && address && project.sc_whitelist_active) {
            try {
              isOk = await getContractDS(web3, project.sc_address, project.sc_version).isWhitelisted(address, project.presale_whitelist, project.whitelistBuyOnce);
            } catch (e) {

            }
            if (!isOk) {
              if (project.project_id == 38) {
                setErrorMsg(project.whitelistBuyOnce ? "You have already minted in this window or your wallet cannot mint in this window." : "Your wallet cannot mint in this window.");
              } else {
                setErrorMsg(project.whitelistBuyOnce ? "You have already minted in this window or your wallet cannot mint in this window." : "Your wallet cannot mint in this window.");
              }
            }
          }
          break;
      }
    }
  }, [project, web3, address, loggedIn, selectedTicket, selectedSaleStep]);

  useEffect(() => {
    checkErrors().then();
  }, [checkErrors]);

  const statusDetailMsg = useMemo(() => {
    switch (saleStep) {
      case EnumSaleStep.TicketSale:
        return 'Mint ticket holder sale is active now.';
      case EnumSaleStep.WhitelistSale:
        return project.project_id == 38 ? 'Early Mint is live now.' : 'Whitelist sale is live now.';
      case EnumSaleStep.NormalSale:
        return 'Public sale is live now!';
      default:
        return '';
    }
  }, [saleStep, project.project_id]);

  const onCloseHandler = () => {
    if (step == STEP_SUCCESS) {
      setStep(-1);
    }
  }

  const onClickMint = async (packageQty?: number) => {
    const buyingQty = packageQty ? packageQty : qtyNFT[saleStep];

    if (!loggedIn || !address) {
      setVisibleConnectModal(true);
      return;
    }

    if (!project) return;

    if (buyingQty < 1) {
      AppMsg.info("Please fill quantity correctly!");
      return;
    }

    if (!project.sc_address) {
      AppMsg.info("Minting is not available! Please try again later!");
      return;
    }

    dispatch(setLoadingType(LOADING_TYPE_BUYING_DROPSPACE));
    const balance: BigNumber = await getAppControllers(web3).contract.getUserBalance(address);
    const priceTotal: BigNumber = await getContractDS(web3, project.sc_address, project.sc_version).getPrice(buyingQty);

    if (priceTotal.lte(balance)) {
      // Real data checking now from Subgraph.
      const scData: ISCData | undefined = await fetchGraphCustomData();
      if (!scData) {
        AppMsg.info("There is an error to verify transaction! Please try again.");
        dispatch(setLoading(false));
      }

      if (!scData?.is_mintable) {
        dispatch(setLoading(false));
        AppMsg.info("Minting is not available! Please try again later!");
        return;
      }

      await checkErrors();

      // if presale by tickets?
      if (isTicketSale(scData, selectedSaleStep)) {
        if (!selectedTicket || !project.tickets || _.findIndex(project.tickets, x => x.id == `${selectedTicket}` && !x.used) < 0) {
          dispatch(setLoading(false));
          return;
        } else {
          setErrorMsg('');
        }
      } else if (isWhitelistSale(scData, selectedSaleStep)) {
        let isOk = false;
        try {
          isOk = await getContractDS(web3, project.sc_address, project.sc_version).isWhitelisted(address, project.presale_whitelist, project.whitelistBuyOnce);
          if (!isOk) {
            AppMsg.info("Sorry, you cannot proceed! Whitelist sale live now. You are not whitelisted.");
            dispatch(setLoading(false));
            return;
          }
        } catch (e: any) {
          if (e.code === 4001) {
          } else {
            AppMsg.info("We are in whitelist sale now. Failed to check your address. Please try again!");
          }
          dispatch(setLoading(false));
          return;
        }
      }

      getContractDS(web3, project.sc_address, project.sc_version).buyMultiple(address, buyingQty, priceTotal, safeNum(selectedTicket), saleStep, project.presale_whitelist).then(async res => {
        fetchGraphCustomData().then(res => {
          if (res) {
            setProject((prevState: any) => {
              return getMergedCollectionDataBySubGraphData(prevState, (res as ISCData));
            });
          }
        });

        setStep(STEP_SUCCESS);
        setSelectedTicket('');
      }).catch(reason => {
        console.error(reason);
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      AppMsg.info(MSG_NO_CURRENCY);
      dispatch(setLoading(false));
    }
  };

  const changeQty = (dir: number) => {
    if (!saleActive) return;
    setQtyNFT((prev) => {
      let newQty = prev[saleStep] + dir;
      if (newQty < 1) newQty = 1;
      if (newQty > (mintLimit || 0)) newQty = (mintLimit || 0);

      return [
        selectedSaleStep == EnumSaleStep.Disabled ? newQty : qtyNFT[EnumSaleStep.Disabled],
        selectedSaleStep == EnumSaleStep.NormalSale ? newQty : qtyNFT[EnumSaleStep.NormalSale],
        selectedSaleStep == EnumSaleStep.WhitelistSale ? newQty : qtyNFT[EnumSaleStep.WhitelistSale],
        selectedSaleStep == EnumSaleStep.TicketSale ? newQty : qtyNFT[EnumSaleStep.TicketSale],
      ];
    });
  }

  const onChangeQty = (e: any) => {
    let newQty = parseInt(e.target.value);
    if (isNaN(newQty) || newQty < 1) newQty = -1;
    if (newQty > (mintLimit || 0)) newQty = (mintLimit || 0);

    setQtyNFT([
      selectedSaleStep == EnumSaleStep.Disabled ? newQty : qtyNFT[EnumSaleStep.Disabled],
      selectedSaleStep == EnumSaleStep.NormalSale ? newQty : qtyNFT[EnumSaleStep.NormalSale],
      selectedSaleStep == EnumSaleStep.WhitelistSale ? newQty : qtyNFT[EnumSaleStep.WhitelistSale],
      selectedSaleStep == EnumSaleStep.TicketSale ? newQty : qtyNFT[EnumSaleStep.TicketSale],
    ]);
  }

  const renderByStep = () => {
    switch (step) {
      case STEP_SUCCESS:
        let shareTitle = `Just minted ${nf(qtyNFT[saleStep], 0)} NFT${qtyNFT[saleStep] > 1 ? 's' : ''} on dropspace! https://dropspace.art\n`;
        return (
          <>
            <img className="absolute right-0 top-0 mr-6 mt-6 cursor-pointer" src={ImgClose} onClick={onCloseHandler}/>
            <div className="text-center"><img className="mb-4 mx-auto" src={ImgSuccess} width={64} height={64}/></div>
            <h4 className="text-3xl tracking-10 font-bold font-stinger_fit text-center mt-8">
              Mint successful!
            </h4>
            <div className="mt-6 text-center shares space-x-4">
              <TwitterShareButton
                className="space-x-2 btn btn-white black"
                title={shareTitle}
                url={project.website || `https://dropspace.art`}
                hashtags={['dropspace', project.name || '', project.name_uid || '']}
                via={``}
                resetButtonStyle={false}
              >
                <img className="inline-block" src={ImgTwitter} width={21} height={21}/>
                <span className="font-black align-middle">Twitter</span>
              </TwitterShareButton>

              {/*<FacebookShareButton
                url={"https://musee.art"}
                className="space-x-2"
                quote={shareTitle}
                hashtag={'Musée'}
              >
                <img src={ImgFacebook} width={20} height={20}/>
                <span>Facebook</span>
              </FacebookShareButton>*/}
            </div>

            <div className="mt-6 text-center space-x-4 text-md text-suisse">
              <div>Want to launch with dropspace? <a href="https://docs.google.com/forms/d/e/1FAIpQLSendEc_2Lxb2h0nQ-QTJqIQh9trd-KWcNE1bXNV_kqF2mRncA/viewform" className="text-blue underline" target="_blank">click here</a></div>
              <div>email - <a href="mailto:info@dropspace.art" className="underline pt-2">info@dropspace.art</a></div>
            </div>
          </>
        );
        break;
    }
  }

  const onCompleteCountdown = (arg: any) => {
    // console.log('completed:', arg);
  }

  const renderTickets = () => {
    if (!project.tickets || project.tickets.length < 1) return null;
    if (!isTicketSale(project, selectedSaleStep)) return null;

    return (
      <div className="form-row form-row-inline mb-4">
        <div className="flex items-center">
          <label className="form-label mr-4" htmlFor="selectedTicket">Choose Ticket ID: </label>
          <select
            id="selectedTicket"
            name="selectedTicket"
            className="input-qty font-suisse rounded-25 text-4 leading-4 pr-8"
            value={selectedTicket}
            onChange={(e) => setSelectedTicket(e.target.value)}
          >
            <option>Select</option>
            {project.tickets.map((x: any, ind) => <option key={x.id} defaultChecked={ind == 0} className={x.used ? 'opacity-10' : ''} value={x.id}
                                                          disabled={x.used}>{x.id} {x.used ? ' (used)' : ''}</option>)}
          </select>
        </div>
      </div>
    );
  }
  const renderMintBody = () => {
    let html = null;
    const mintDisabled = !saleStep || !isAnySaleActive(project) || (isTicketSale(project, selectedSaleStep) && !selectedTicket) || (loggedIn && !!errorMsg);

    if (
      (selectedSaleStep == null && project.is_mintable) ||
      (selectedSaleStep && (selectedSaleStep == saleStep && project.is_mintable))
    ) {
      html = (
        <>
          {errorMsg && <div className="text-md font-suisse text-live mt-6 mb-4">{errorMsg}</div>}
          {renderTickets()}
          <div className="flex items-center">
            <div className={`flex items-center w-full mr-2 sm:mr-6 lg:mr-8 ${mintDisabled ? 'disabled cursor-not-allowed' : ''}`}>
              <div className="input-wrap flex rounded-default space-x-1">
                <div className={`control ${!mintDisabled ? '' : 'disabled'}`} onClick={() => changeQty(-1)}>-</div>
                <input type="text" disabled={mintDisabled} className="input-qty bg-white font-suisse rounded-25 text-4 leading-4"
                       value={qtyNFT[saleStep] > 0 ? qtyNFT[saleStep] : ''} onChange={onChangeQty}/>
                <div className={`control ${!mintDisabled ? '' : 'disabled'}`} onClick={() => changeQty(1)}>+</div>
              </div>
            </div>
            <Button
              disable={mintDisabled}
              className="btn-white black"
              onClick={() => onClickMint()}
            >Mint<span
              className="hidden xl:inline"> - {`${nf((project.mint_price > 0 ? project.mint_price : 0) * (qtyNFT[saleStep] ?? 1), 4)}`} Ξ</span></Button>
          </div>
          {(project.mint_limit || 1) > 0 && <p className="text-xs italic pt-4">You can only mint {mintLimit} NFTs in one transaction.</p>}
        </>
      );
    } else if (isSoldOut(project)) {
      html = (
        <div className="flex mt-6">
          <LinkButton
            disable={saleActive}
            className="btn-white black cursor-pointer w-full text-center"
            target={'_blank'}
            linkTo={project.social_links?.os}
            external
          >Buy on Opensea</LinkButton>
        </div>
      );
    } else {
      if (project.is_tbd) {
        html = (
          <div className="flex items-center -mx-6 font-suisse">
            <label className="text-gray2 text-3_75 mt-2 leading-5 font-medium px-6">Launching date will be announced soon.</label>
          </div>
        );
      } else if (project.start_drop_date || project.presale_date || project.whitelistsale_date) {

        let selectedDate = null;
        if (selectedSaleStep == EnumSaleStep.TicketSale) {
          selectedDate = project.presale_date;
        } else if (selectedSaleStep == EnumSaleStep.WhitelistSale) {
          selectedDate = project.whitelistsale_date;
        } else if (selectedSaleStep == EnumSaleStep.NormalSale) {
          selectedDate = project.start_drop_date;
        }

        if (!selectedDate) {
          html = (
            <div className="flex items-center -mx-6 font-suisse">
              <label className="text-gray2 text-3_75 mt-2 leading-5 font-medium px-6">Launching date will be announced soon.</label>
            </div>
          );
        } else {
          const mObj = dtInTz(selectedDate, TZ_NEW_YORK, TZ_NEW_YORK);

          // const timeNow = dtTodayInServerTz().format('YYYY-MM-DD HH:mm:ss');
          // const strDate = mObj && mObj.isValid() ? mObj.format('YYYY-MM-DD HH:mm:ss') : '';
          // if (strDate <= timeNow) {
          //   html = (
          //     <div className="flex items-center -mx-6 font-suisse">
          //       <label className="text-gray2 text-3_75 mt-2 leading-5 font-medium px-6">Launching date will be announced soon.</label>
          //     </div>
          //   );
          // } else
          if (!mObj || !mObj.isValid()) {
            html = (
              <div className="flex items-center -mx-6 font-suisse">
                <label className="text-gray2 text-3_75 mt-2 leading-5 font-medium px-6">Launching date will be announced soon.</label>
              </div>
            );
          } else {
            const title = mObj && mObj.isValid() ? `${mObj.format('Do MMM, YYYY h:mma')} (${' EST' || mObj.format('h:mma z')})` :
              selectedDate;
            html = (
              <div className="font-suisse">
                <div className="text-gray2 font-medium"><span>Launching on </span><span className="font-semibold text-black1">{title}</span></div>
                {mObj && <div className="mint-wrap my-6">
                  <div className="flex justify-center">
                    <Countdown
                      date={mObj ? mObj.format() : selectedDate}
                      zeroPadDays={2}
                      zeroPadTime={2}
                      renderer={renderCountDown}
                    />
                  </div>
                </div>}
              </div>
            );
          }
        }
      }
    }
    return html;
  };


  // const isCuratedDummy = project && (project.featured || project.is_homepage);
  return (
    <div className={`mint-box ${className} sm:min-w-md relative`}>
      <SLoadingWrap loading={loadingCustomGraph && !loading} maskCls={'p'}
                    className={`w-full p-4 sm:p-8 md:p-10 ${loadingCustomGraph || loading ? 'pointer-events-none' : ''}`}>
        {/*{isCuratedDummy && <img className="absolute top-0 right-0 max-w-none -mr-10 -mt-10 w-24 h-24 transform rotate-12" src={ImgCuratedBadge}/>}*/}
        <div className="status-grid justify-center sm:flex-nowrap font-inter tracking-tight md:tracking-normal">
          <div className="">
            <label className="text-gray2 text-center text-3_75 leading-5 font-medium mb-3.5">{(project.is_live) ? 'Minted' : 'Items'}</label>
            <div
              className="min-w-max lg:text-4_5 lg:leading-6_5 xl:text-5 xl:leading-6_5 font-semibold text-black1">
              {(project.is_live) && <>{nf_i(project.minted_count)} {(project.project_id == 37) ? '' : ''}</>}
              {(!project.is_live) && <>{nf_i(project.total_count)}</>}
              {/*{(project.project_id != 37) && <span className="text-gray-500">{nf_i(project.total_count)}</span>}
              {(project.project_id == 37 && !isAnySaleActive(project)) && <span className="text-gray-500">{nf_i(project.total_count)}</span>}*/}
            </div>
          </div>
          <div className="">
            <label className="text-gray2 text-center text-3_75 leading-5 font-medium mb-3.5">Price</label>
            <div
              className="min-w-max lg:text-4_5 lg:leading-6_5 xl:text-5 xl:leading-6_5 font-semibold text-black1">{project.mint_price > 0 ? nf(project.mint_price, 4) : 0} Ξ
            </div>
          </div>
        </div>
        {loggedIn
          ? <>
            <div className="border-b border-black2 mt-8 mb-8"></div>
            {(statusDetailMsg && !isSoldOut(project)) && <div className="text-md font-suisse text-live my-4">{statusDetailMsg}</div>}
            {renderMintBody()}
          </>
          : <>
            {renderConnectButton(2)}
          </>
        }
        {selectedSaleStep == EnumSaleStep.TicketSale && !isSoldOut(project) &&
          <p className="text-xs italic pt-4 underline"><a href={`https://opensea.io/collection/dropspace-mint-tickets`} target={"_blank"}>Click
            here to buy a mint ticket</a></p>}
      </SLoadingWrap>
      <div
        className={`modal-checkout modal ${(step > 0) ? '' : 'hidden'} flex flex-col fixed w-screen min-h-full top-0 left-0 flex items-center shadow-lg justify-center z-50`}
      >
        <div className="modal-overlay absolute w-screen h-full bg-black opacity-50 pointer-events-none" onClick={onCloseHandler}></div>
        <div className="modal-container max-h-screen90 bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto relative">
          <div className="modal-content py-12 text-left px-6 items-center justify-center text-black">
            {renderByStep()}
          </div>
        </div>
      </div>

      {!loggedIn && renderAccountModals()}
    </div>
  );
}
export default BuyNFT;
