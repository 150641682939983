import React from 'react';

import "./Projects.scss";
import LiveCollectionsListing from "../../components/LiveCollections/LiveCollectionsListing";
import {useDispatch, useSelector} from "react-redux";
import UpcomingCollections from "../../components/UpcomingCollections/UpcomingCollections";
import useInterval, {TimerDelays} from "../../dnweb3/hooks/useInterval";
import {incrementRefreshTick} from "../../redux/global/actions";
import SoldOutCollections from "../../components/SoldOutCollections/SoldOutCollections";
import {IMapStateToProps} from "../../types";

const Projects = () => {
  const dispatch = useDispatch();
  const {
    authUser: {
      address,
      loggedIn,
    }
  } = useSelector((state: IMapStateToProps) => state);

  /**
   * Refresh Live and Upcoming collections
   * */
  useInterval(() => {
    dispatch(incrementRefreshTick());
  }, TimerDelays.FAST_INTERVAL_60, false);

  return (
    <div className="page-body projects text-black2 my-20">
      <div className="container">
        <h1 className="base text-left mb-10">
          <div className="inline-block align-middle border-b-4 border-black pl-12 md:pl-25 mr-8 md:mr-12"></div>
          Get minting. <br />Explore all collections.
        </h1>
        <p className="max-w-xl">This is a decentralized launchpad where any collection can mint using our industry-leading tech.</p>
      </div>
      <LiveCollectionsListing/>
      <UpcomingCollections />
      <SoldOutCollections />
    </div>
  );
}

export default Projects
