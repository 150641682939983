import {
  SET_LOADING,
  SET_ADDRESS,
  SET_NETWORKID,
  SET_CONNECT_TYPE,
  SET_ERROR,
  SET_POOL_LIST,
  SET_CURRENT_APY,
  SET_TOTAL_USER_INFO,
  SET_USER_POOL_LIST,
  SET_START_BLOCK,
  SET_CURRENT_APR,
  SET_INIT_DATA,
  SET_LOGGED_IN,
  SET_STATE_DATA,
  SET_LOADING_TYPE,
  SET_NFT_LIST,
  SET_SELECTED_NFT_ID,
  SET_USER_NFT_ID_LIST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  SET_PROFILE,
  SET_GUEST_PROFILE, UPDATE_PROFILE
} from '../actions';
import {APP_NETWORK_ID} from "../../dnweb3/config";
import Web3 from "web3";
import {bnZero} from "../../dnweb3/helpers/utilities";
import BigNumber from "bignumber.js";
import {getAuthToken} from "../../dnweb3/helpers/AuthUtil";
import {EnumUserRole} from "../../dnweb3/types";


export interface IProfile {
  user_id: number
  email?: string | null | undefined
  role?: number
  status?: number
  address?: string
  name?: string
  nonce?: number
  token?: null | string | undefined
}

const INIT_PROFILE: IProfile = {
  user_id: 0,
  role: EnumUserRole.NORMAL,
  token: null,
}

export interface IStateType {
  loading: boolean;
  loadingType: number;
  error: any;    // global error message

  // account & network connection info
  address: any
  web3: any
  provider: any
  chainId: number
  networkId: number | null
  connectType: string
  loggedIn: boolean
  connected: boolean
  triedConnect: boolean

  killSession: (web3Prop: Web3) => void
  onConnect: (connectType: string, isCached: boolean, cb?: any) => void

  // wallet balance
  balance: BigNumber

  // prices
  nftPrices: any

  // profile
  isAuthenticated: boolean
  profileLoaded: boolean
  profile: IProfile
};


const INIT_STATE: IStateType = {
  loading: false,
  loadingType: 0,
  error: '',    // global error message

  // account & network connection info
  address: null,
  web3: null,
  provider: null,
  chainId: APP_NETWORK_ID,
  networkId: null,
  connectType: '',
  loggedIn: false,
  connected: false,
  triedConnect: false,

  killSession: web3Prop => {
  },
  onConnect: (connectType: string, isCached: boolean, cb?: any) => {
    console.log("Default on connect");
  },

  // wallet balance
  balance: bnZero(),

  nftPrices: {},

  // profile
  isAuthenticated: false,
  profile: INIT_PROFILE,
  profileLoaded: false,
};

const authReducer = (state: IStateType = INIT_STATE, action: any) => {
  switch (action.type) {
    case SET_LOADING_TYPE:
      return {...state, loadingType: action.payload.loadingType, loading: action.payload.loadingType > 0};
    case SET_LOADING:
      const loading = action.payload.loading;
      let extra = {loadingType: state.loadingType};
      if (!loading) extra.loadingType = 0;
      return {...state, loading: loading, ...extra};
    case SET_ADDRESS:
      return {...state, address: action.payload.address};
    case SET_NETWORKID:
      return {...state, networkId: action.payload.networkId};
    case SET_CONNECT_TYPE:
      return {...state, connectType: action.payload.connectType};
    case SET_ERROR:
      return {...state, error: action.payload.error};
    case SET_POOL_LIST:
      return {...state, poolList: action.payload.data};
    case SET_CURRENT_APY:
      return {...state, currentAPY: action.payload.data};
    case SET_CURRENT_APR:
      return {...state, currentAPR: action.payload.data};
    case SET_USER_POOL_LIST:
      return {...state, userPoolList: action.payload.data};
    case SET_TOTAL_USER_INFO:
      return {...state, userTotalInfo: action.payload.data};
    case SET_START_BLOCK:
      return {...state, startBlock: action.payload.data};
    case SET_LOGGED_IN:
      return {...state, loggedIn: action.payload};
    case SET_STATE_DATA:
      return {...state, ...action.payload};
    case SET_INIT_DATA:
      return {
        ...state,
        poolList: [],
        userTotalInfo: {},
        userPoolList: null,
        currentAPY: null,
        currentAPR: null,
        nftList: [],
        userNftMap: {},
        isAuthenticated: false,
        profileLoaded: false,
        profile: INIT_PROFILE,
      };

    // NFT Related
    case SET_NFT_LIST:
      return {...state, nftList: action.payload};
    case SET_USER_NFT_ID_LIST:
      return {...state, userNftIdList: action.payload};
    case SET_SELECTED_NFT_ID:
      return {...state, selectedNftId: action.payload};

    // Auth & Profile
    case AUTH_LOGIN_SUCCESS:
      return {...state};
    case AUTH_LOGIN_ERROR:
      return {...state};

    case SET_PROFILE:
      return {
        ...state,
        profileLoaded: true,
        profile: action.payload || INIT_PROFILE,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profileLoaded: true,
        profile: {...state.profile, ...action.payload},
      };

    case SET_GUEST_PROFILE:
      return {...state, profile: INIT_PROFILE};

    default:
      return {...state};
  }
}

export default authReducer;
