import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


const useAppTheme = () => {
  const location = useLocation();
  const [theme, setTheme] = useState<any>(null);

  useEffect(() => {
    setTheme(location.pathname == '/' ? 'dark' : 'white');
  }, [location, setTheme]);

  return [theme, setTheme];
}
export default useAppTheme;
