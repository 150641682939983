import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../types";
import SAccountModal from "../components/Dialog/SAccountModal";
import {SConnectModal} from "../components/Dialog/SConnectModal";
import ImgMetamask from "../assets/images/icon/metamask-i.png";
import {ellipseAddress} from "../dnweb3/helpers/utilities";
import BtnConnectWalletBlack from "../../assets/images/button/btn-connect-anim-black.png";

const useNavbar = (isOpen: boolean) => {
  const [visibleConnectModal, setVisibleConnectModal] = useState<boolean | undefined>();
  const location = useLocation();
  const loggedIn = useSelector((state: IMapStateToProps) => state.authUser.loggedIn);
  const address = useSelector((state: IMapStateToProps) => state.authUser.address);

  const renderAccountModals = useCallback(() => {
    return (loggedIn && address) ?
      <SAccountModal isOpen={visibleConnectModal} onClose={() => setVisibleConnectModal(false)}/> :
      <SConnectModal isOpen={visibleConnectModal} onClose={() => setVisibleConnectModal(false)}/>
  }, [isOpen, loggedIn, address, visibleConnectModal, setVisibleConnectModal]);

  const renderConnectButton = useCallback((style?: number) => {
    let html = null;
    switch (style) {
      case 1:
        html = !(loggedIn && address) && (<>
          <div className="btn-anim mt-9 md:mt-0 mx-auto md:mx-0 md:ml-auto cursor-pointer uppercase inline-block"
               onClick={() => setVisibleConnectModal(true)}
          >
            Connect Wallet
          </div>
          </>
        );
        break;
      case 2: // Button mode
        html = !(loggedIn && address) && (<>
            <button className="btn btn-connect mt-9 md:mt-0 mx-auto md:mx-0 md:ml-auto cursor-pointer uppercase inline-block"
                 onClick={() => setVisibleConnectModal(true)}
            >
              Connect Wallet
            </button>
          </>
        );
        break;
      default:
        html = <>
          {/*<img className="mr-2" src={ImgMetamask} width={21} height={18.34}/>*/}
          <a
            className="nav px-4 hover:opacity-95 md:ml-0 md:ml-0"
            href='#'
            onClick={e => {
              e.preventDefault();
              setVisibleConnectModal(true);
            }}
          >{loggedIn && address ? ellipseAddress(address, 6) : "Connect"}</a>
        </>;
        break;
    }
    return html;
  }, [isOpen, loggedIn, address, visibleConnectModal, setVisibleConnectModal]);

  return {
    loggedIn,
    address,
    renderConnectButton,
    renderAccountModals,
    setVisibleConnectModal
  };
}

export default useNavbar;
