import {useCallback, useEffect, useState} from 'react'
import {ICollection} from "../dnweb3/types";
import {bindCollectionData} from '../hooks/useCollections';
import AppApi from "../dnweb3/appapi";
import {AxiosError} from "axios";

const useCollectionDetail = (params: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [item, setItem] = useState<ICollection>();

  const fetch = useCallback(async (skipUpdateItem?: boolean) => {
    try {
      setError(false);
      setLoading(true);
      const response = await AppApi.getProjectByUid(params.name_uid);
      const newItem = bindCollectionData(response);
      if (!skipUpdateItem)
        setItem(newItem);
      return newItem;
    } catch (error:any) {
      console.error('Unable to fetch project.', error, error.response?.status);
      setError(error.response?.status);
    } finally {
      setLoading(false);
    }
  }, [params.name_uid]);

  useEffect(() => {
    if (params && params.name_uid) {
      fetch();
    }
  }, [params.name_uid]);

  return {item, loading, fetch, error};
}

export default useCollectionDetail;
