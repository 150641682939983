import axios from 'axios'
import {useSelector} from "react-redux";
import {IMapStateToProps} from "../types";
import {APP_API} from "../config";
import {setAuthToken} from "../dnweb3/helpers/AuthUtil";

axios.defaults.baseURL = APP_API;

export function useAxios() {
  const {
    authUser: {
      address,
      profile: {
        token,
      }
    }
  } = useSelector((state: IMapStateToProps) => state);

  // Axios Initialization
  axios.defaults.headers.common['Accept'] = '*/*';
  axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';

  axios.interceptors.request.use(config => {
    // if(config.method === 'post') {
    //     config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    //     config.data = qs.stringify(config.data)
    // }

    if (token /*&& !config.url.includes('https://api.thegraph.com') && !config.url.includes('https://ipfs.io/')*/) {
      if (config.headers) {
        config.headers.authorization = `${token}`;
        // config.headers.common['Authorization'] = `${token}`
      }
    }
    config.baseURL = `${APP_API}`;
    config.timeout = 300000;

    return config;
  });

  axios.interceptors.response.use(response => {
    return Promise.resolve(response);
  }, error => {
    if (error.response?.status == 403) {
      if (address) {
        setAuthToken(address, null);
      }
    }
    return Promise.reject(error)
  });
}
