import {useEffect, useRef} from 'react'

export const TimerDelays = {
  FAST_INTERVAL_10: 10000,
  FAST_INTERVAL_20: 20000,
  FAST_INTERVAL_30: 30000,
  FAST_INTERVAL_40: 40000,
  FAST_INTERVAL_60: 60000,
  SLOW_INTERVAL_90: 90000,
  SLOW_INTERVAL_120: 120000,
  SLOW_INTERVAL_180: 180000,
  SLOW_INTERVAL_300: 300000,
};

export default function useInterval(callback: () => void, delay: null | number, leading = true) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const {current} = savedCallback;
      if (current) {
        current();
      }
    }

    if (leading) tick();

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay, leading]);
}
